import { Fragment, useEffect, useState } from "react";

import "./radioButton.scss";

interface RadioButtonProps {
  labels: string[];
  checkedIndex?: number;
  onClick?: (index: number) => void;
}

function RadioButton({ labels, checkedIndex = 0, onClick }: RadioButtonProps) {
  const [indexSelected, setCheckedIndex] = useState<number>(checkedIndex);

  const handleRadioClick = (index: number) => {
    setCheckedIndex(index);
    if (onClick) {
      onClick(index);
    }
  };

  useEffect(() => {
    setCheckedIndex(checkedIndex);
  }, [checkedIndex]);

  return (
    <div className="switch-field">
      {labels.map((label, index) => (
        <Fragment key={index}>
          <input
            type="radio"
            id={`radio-${index}`}
            name="switch-two"
            value={index}
            checked={index === indexSelected}
            onChange={() => handleRadioClick(index)}
          />
          <label
            htmlFor={`radio-${index}`}
            onClick={() => handleRadioClick(index)}
          >
            {label}
          </label>
        </Fragment>
      ))}
    </div>
  );
}

export default RadioButton;
