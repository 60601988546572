import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import LabelsApi, { CreateLabelInputs } from "@pelote/api/LabelsApi";
import { APIError } from "@pelote/types/ApiError";

function usePageAdminLabelCreate(): {
  sportPath: string | undefined;
  handleCreateLabel: (createLabelInputs: CreateLabelInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Handle the creation of a label
  const queryClient = useQueryClient();
  const {
    mutate: createLabel,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newLabel: CreateLabelInputs) => LabelsApi.create(newLabel),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["indexLabels"] });
      toast.success(t("admin.label.created"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });
  function handleCreateLabel(createLabelInputs: CreateLabelInputs) {
    if (currentSport === undefined) return;

    createLabelInputs.sport_id = currentSport.id;
    createLabel(createLabelInputs);
  }

  return {
    sportPath,
    handleCreateLabel,
    isLoading: isUserLoading,
    isCreationPending,
    isCreationSuccess,
  };
}

export default usePageAdminLabelCreate;
