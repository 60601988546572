import userContext from "@/contexts/userContext";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SelectOption } from "@/ui-lib/select/Select";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import usePaginatedPools from "@pelote/hooks/usePaginatedPools";
import usePaginatedRounds from "@pelote/hooks/usePaginatedRounds";
import usePaginatedGames from "@pelote/hooks/usePaginatedGames";
import { Game } from "@pelote/types/Game";
import usePaginatedStages from "@pelote/hooks/usePaginatedStages";
import { Permission } from "@/domains/user/types/Permission";

function usePageCompetitionDetails(): {
  userPermissions: Permission[];
  games: Game[] | undefined;
  seasonSelectOptions: SelectOption[];
  stageSelectOptions: SelectOption[];
  poolSelectOptions: SelectOption[];
  roundSelectOptions: SelectOption[];
  isLoading: boolean;
  handleChangeSeasonOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangeStageOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangePoolOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeRoundOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
} {
  const { t } = useTranslation();
  const [selectedSeasonOption, setSelectedSeasonOption] =
    useState<SelectOption | null>(null);
  const [selectedStageOption, setSelectedStageOption] =
    useState<SelectOption | null>(null);
  const [selectedPoolOption, setSelectedPoolOption] =
    useState<SelectOption | null>(null);
  const [selectedRoundOption, setSelectedRoundOption] =
    useState<SelectOption | null>(null);

  // Get the current sport from the user context
  const {
    currentSport,
    isLoading: isUserLoading,
    permissions: userPermissions,
  } = useContext(userContext);

  // Get the competition id
  const { competitionId } = useParams();

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons(
    {
      page: 1,
      pageSize: 10,
      sortBy: "name:desc",
      sportId: currentSport?.id,
    },
  );

  // Convert the seasons to select options
  const seasonSelectOptions = convertItemsToSelectOptions(
    paginatedSeasons?.items || [],
    "id",
    "name",
  );

  // Fetch a list of stages
  const { paginatedStages, isLoading: isStagesLoading } = usePaginatedStages({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: competitionId,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || competitionId === null,
  });

  // Convert the stages to select options
  const stageSelectOptions = convertItemsToSelectOptions(
    paginatedStages?.items || [],
    "id",
    "name",
  );
  stageSelectOptions.unshift({ label: `${t("all_stages")}`, value: "" });

  // Fetch a list of pools
  const { paginatedPools, isLoading: isPoolsLoading } = usePaginatedPools({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: competitionId,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || competitionId === null,
  });

  // Convert the pools to select options
  const poolSelectOptions = convertItemsToSelectOptions(
    paginatedPools?.items || [],
    "id",
    "name",
  );
  poolSelectOptions.unshift({ label: `${t("all_pools")}`, value: "" });

  // Fetch a list of rounds
  const { paginatedRounds, isLoading: isRoundsLoading } = usePaginatedRounds({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: competitionId,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption == null || competitionId == null,
  });

  // Convert the rounds to select options
  const roundSelectOptions = convertItemsToSelectOptions(
    paginatedRounds?.items || [],
    "id",
    "name",
  );

  roundSelectOptions.unshift({ label: `${t("all_rounds")}`, value: "" });

  // Get the paginated games
  const { paginatedGames, isLoading: isGamesLoading } = usePaginatedGames({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: competitionId,
    stageId: selectedStageOption?.value as string,
    poolId: selectedPoolOption?.value as string,
    roundId: selectedRoundOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || competitionId === null,
    sortBy: "start_time:desc",
  });
  const games = paginatedGames?.items;

  const handleChangeSeasonOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedSeasonOption(
      seasonSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };

  const handleChangeStageOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedStageOption(
      stageSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };

  const handleChangePoolOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedPoolOption(
      poolSelectOptions.find((option) => option.value === event.target.value) ||
        null,
    );
  };

  const handleChangeRoundOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedRoundOption(
      roundSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };

  useEffect(() => {
    if (!paginatedSeasons || seasonSelectOptions.length === 0) return;
    const initialSeasonOption = seasonSelectOptions[0];
    setSelectedSeasonOption(initialSeasonOption);
  }, [paginatedSeasons]);

  useEffect(() => {
    if (!paginatedStages || stageSelectOptions.length === 0) return;
    const initalStageOption = stageSelectOptions[0];
    setSelectedStageOption(initalStageOption);
  }, [paginatedStages]);

  useEffect(() => {
    if (!paginatedPools || poolSelectOptions.length === 0) return;
    const initalPoolOption = poolSelectOptions[0];
    setSelectedPoolOption(initalPoolOption);
  }, [paginatedPools]);

  useEffect(() => {
    if (!paginatedRounds || roundSelectOptions.length === 0) return;
    const initialRoundOption = roundSelectOptions[0];
    setSelectedRoundOption(initialRoundOption);
  }, [paginatedRounds]);

  const isLoading =
    isSeasonsLoading ||
    isStagesLoading ||
    isPoolsLoading ||
    isRoundsLoading ||
    isGamesLoading ||
    isUserLoading;

  return {
    userPermissions,
    games,
    seasonSelectOptions,
    stageSelectOptions,
    poolSelectOptions,
    roundSelectOptions,
    isLoading,
    handleChangeSeasonOption,
    handleChangeStageOption,
    handleChangePoolOption,
    handleChangeRoundOption,
  };
}

export default usePageCompetitionDetails;
