import { cacheKey } from "@/utils/constants";
import { useQuery } from "@tanstack/react-query";

import UsersApi from "@user/api/UsersApi";
import { UserFull } from "@user/types/User";

interface useUserMeOptions {
  skip?: boolean;
}

function useUserMe(options: Partial<useUserMeOptions> = {}): {
  user: UserFull | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { skip = false } = options;

  const { data, isLoading, isError } = useQuery({
    queryKey: [cacheKey.showUserMe],
    queryFn: () => UsersApi.showMe(),
    enabled: !skip,
  });

  return { user: data, isLoading, isError };
}

export default useUserMe;
