import { useTranslation } from "react-i18next";

import "./TranslationFlags.scss";

export function TranslationFlags({ className }: { className?: string }) {
  const { i18n } = useTranslation();

  const handleLanguageSwitch = (language: string) => {
    i18n.changeLanguage(language);
  };
  return (
    <div className={`${className} flags-section`}>
      <button
        type="button"
        className="button-theme"
        onClick={() => handleLanguageSwitch("fr")}
      >
        {" "}
        <img
          src="https://img.icons8.com/color/48/000000/ france-circular.png"
          className="flags"
        />
      </button>

      <button
        type="button"
        className="button-theme"
        onClick={() => handleLanguageSwitch("sp")}
      >
        {" "}
        <img
          className="flags "
          src="https://img.icons8.com/color/48/spain2-circular.png"
        />
      </button>
      <button
        type="button"
        className="button-theme"
        onClick={() => handleLanguageSwitch("eu")}
      >
        {" "}
        <img
          className="flags "
          style={{ height: "35px" }}
          src="https://stickair.com/27427-medium_default/drapeau-basque-rond.jpg"
        />
      </button>
      <button
        type="button"
        className="button-theme"
        onClick={() => handleLanguageSwitch("en")}
      >
        {" "}
        <img
          className="flags "
          src="https://img.icons8.com/color/48/000000/ great-britain-circular.png"
        />
      </button>
    </div>
  );
}
