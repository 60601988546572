import { useAppSelector } from "../hooks";
import { RootState } from "../store";

const UseValidationReport = () => {
  const validationErrors = useAppSelector(
    (state: RootState) => state.validationReducer,
  );
  const errors = validationErrors.validator;
  const actionIdsToCorrect: string[] = [];
  errors.forEach((element) => {
    if (element.action_id) {
      actionIdsToCorrect.push(element.action_id);
    }
  });

  return {
    actionIdsToCorrect,
  };
};

export default UseValidationReport;
