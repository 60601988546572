import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import userContext from "@/contexts/userContext";
import { CardsCompetitions } from "@pelote/components/cards-competitions/CardsCompetitions";
import { Competition } from "@pelote/types/Competition";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";
import Loader from "@pelote/components/loader/Loader";

import "./PageCompetitions.scss";

const PageCompetitions = () => {
  const navigate = useNavigate();
  const { isLoading: isUserLoading, currentSport } = useContext(userContext);
  const { paginatedCompetitions } = usePaginatedCompetitions({
    sportId: currentSport?.id,
    pageSize: 20,
  });

  if (isUserLoading) {
    return <Loader />;
  }

  return (
    <div className="competitions-list-parent">
      <div className="competitions-list-child">
        {paginatedCompetitions?.items.map((competition: Competition) => (
          <CardsCompetitions
            key={competition.id}
            name={competition.name}
            urlLogo={competition.logo_url}
            onClick={() =>
              navigate(`/cesta-punta/competitions/${competition.id}`)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default PageCompetitions;
