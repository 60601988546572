import { useContext, useState } from "react";

import userContext from "@/contexts/userContext";
import { Player } from "@pelote/types/Player";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import usePaginatedPlayers from "@pelote/hooks/usePaginatedPlayers";
import { useDebounce } from "@/hooks/useDebounce";

function usePageUserPlayers({ pageSize }: { pageSize: number }): {
  sportPath: string | undefined;
  paginatedPlayers: PaginatedModel<Player> | undefined;
  isLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
  searchPlayerInput: string;
  selectPositionInput: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePositionChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Set the search input state - only lastname for now
  const [searchPlayerInput, setSearchPlayerInput] = useState("");
  const debouncedSearch = useDebounce(searchPlayerInput, 600);

  // Set the Position input state
  const [selectPositionInput, setSelectPositionInput] = useState("any");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentPage !== 1 && setCurrentPage(1);
    setSearchPlayerInput(event.target.value);
  };

  const handlePositionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectPositionInput(event.target.value);
  };

  // Get the paginated players
  const { paginatedPlayers, isLoading: isPlayersLoading } = usePaginatedPlayers(
    {
      page: currentPage,
      pageSize,
      sportId: currentSport?.id,
      skip: currentSport === undefined,
      lastname: debouncedSearch,
      position: selectPositionInput,
    },
  );

  // Build the global isLoading flag
  const isLoading = isUserLoading || isPlayersLoading;

  return {
    sportPath,
    paginatedPlayers,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
    searchPlayerInput,
    selectPositionInput,
    handleSearchChange,
    handlePositionChange,
  };
}

export default usePageUserPlayers;
