import { Player } from "@pelote/types/Player";
import { useTranslation } from "react-i18next";

import { getFlag } from "@pelote/utils/getFlag";
import { calculateAge } from "@pelote/utils/calculateAge";
import { getPlayerPosition } from "@pelote/utils/getPlayerPosition";

import "./PlayerHeader.scss";

interface PlayerHeaderProps {
  player: Player;
}

export const PlayerHeader = ({ player }: PlayerHeaderProps) => {
  const { t } = useTranslation();

  const birthDate = player.birthdate
    ? ` (${new Date(player.birthdate).toLocaleDateString(navigator.language)})`
    : "";

  return (
    <div className="player-header">
      <div className="player-photo-holder">
        <img
          src={
            player.photo_url || "/src/assets/images/default_player_photo.png"
          }
        />
      </div>
      <div className="player-nationality-holder">
        <img src={getFlag(player.nationality)} />
      </div>
      <div className="player-name-container">
        {player.lastname} {player.firstname}
      </div>
      <div className="player-info">
        <p>{t("age") + " : " + calculateAge(player.birthdate) + birthDate}</p>
        <p>
          {t("size")} : {player.size}
        </p>
        <p>
          {t("position")} : {t(getPlayerPosition(player.favorite_position))}
        </p>
        <p>
          {t("weight")} : {player.weight}kg
        </p>
        <p>
          {t("laterality")} : {t(player.laterality || "unknownLaterality")}
        </p>
      </div>
    </div>
  );
};
