import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import VideosApi, { UpdateVideoInputs } from "@pelote/api/VideosApi";
import { APIError } from "@pelote/types/ApiError";
import { Video } from "@pelote/types/Video";
import useVideo from "@pelote/hooks/useVideo";

function usePageAdminGameEditVideo(): {
  sportPath: string | undefined;
  video: Video | undefined;
  handleEditVideo: (updatedVideo: UpdateVideoInputs) => void;
  isLoading: boolean;
} {
  // Get the video id from the route params
  const { videoId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Fetch the video
  const { video, isLoading: isVideoLoading } = useVideo(videoId || "");

  // Handle the edition of a video
  const queryClient = useQueryClient();
  const { mutate: updateVideo } = useMutation({
    mutationFn: videoId
      ? (updatedVideo: UpdateVideoInputs) =>
          VideosApi.update(videoId, updatedVideo)
      : undefined,

    onSuccess: (data: Video) => {
      queryClient.invalidateQueries({
        queryKey: ["showVideo", data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexVideos"],
      });
      toast.success(t("admin.video.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/games/${data.game_id}/videos`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edition"), {
        autoClose: 3000,
      });
    },
  });

  function handleEditVideo(updatedVideo: UpdateVideoInputs) {
    if (videoId === undefined) return;
    updateVideo(updatedVideo);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isVideoLoading;

  return {
    sportPath,
    video,
    handleEditVideo,
    isLoading,
  };
}

export default usePageAdminGameEditVideo;
