import axios from "axios";

import msalManager from "@/auth/msalManager";
import { Organization } from "@user/types/Organization";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { User } from "@user/types/User";
import { Permission } from "@user/types/Permission";
import { Role } from "@user/types/Role";
import { OrganizationApiRoute } from "@user/types/OrganizationApiRoute";
import { OrganizationCompetitionRugby } from "@user/types/OrganizationCompetitionRugby";
import { OrganizationGameBought } from "../types/OrganizationGameBought";

export interface CreateOrganizationInputs {
  name: string;
}

export interface UpdateOrganizationInputs {
  name?: string;
  is_active?: boolean;
}

export interface IndexOrganizationUsersOptions {
  page?: number;
  pageSize?: number;
}

export interface IndexOrganizationPermissionsOptions {
  page?: number;
  pageSize?: number;
}

export interface IndexOrganizationRolesOptions {
  page?: number;
  pageSize?: number;
}

export interface IndexOrganizationApiRoutesOptions {
  page?: number;
  pageSize?: number;
}

export default class OrganizationsApi {
  static domain: string = "user";
  static resource: string = "organizations";

  static async create(
    createOrganizationInputs: CreateOrganizationInputs,
  ): Promise<Organization> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}`;

    const response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: createOrganizationInputs,
    });

    return response.data;
  }

  static async show(id: string): Promise<Organization> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async index(
    name: string | undefined = undefined,
    isActive: boolean | undefined = undefined,
    page: number = 1,
    pageSize: number = 10,
    sortBy: string = "name:asc",
  ): Promise<PaginatedModel<Organization>> {
    const accessToken = await msalManager.getAccessToken();

    // Build base URL
    const url = new URL(
      `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}`,
    );

    // Add query params
    const queryParams = new URLSearchParams();
    if (name) queryParams.append("name", name);
    if (isActive) queryParams.append("is_active", isActive.toString());
    if (page) queryParams.append("page", page.toString());
    if (pageSize) queryParams.append("page_size", pageSize.toString());
    if (sortBy) queryParams.append("sort_by", sortBy);

    if (queryParams.toString()) {
      url.search = queryParams.toString();
    }

    const response = await axios(url.toString(), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async update(
    id: string,
    updateOrganizationInputs: UpdateOrganizationInputs,
  ): Promise<Organization> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: updateOrganizationInputs,
    });

    return response.data;
  }

  static async renewApiKey(id: string): Promise<Organization> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${id}/renew-api-key`;

    const response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${id}`;

    await axios(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  static async indexOrganizationUsers(
    organizationId: string,
    options: IndexOrganizationUsersOptions,
  ): Promise<PaginatedModel<User>> {
    const { page = 1, pageSize = 10 } = options;

    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${organizationId}/users?page=${page}&page_size=${pageSize}`;

    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async indexOrganizationPermissions(
    organizationId: string,
    options: IndexOrganizationPermissionsOptions,
  ): Promise<PaginatedModel<Permission>> {
    const { page = 1, pageSize = 10 } = options;

    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${organizationId}/permissions?page=${page}&page_size=${pageSize}`;

    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async addPermission(
    organizationId: string,
    permissionId: string,
  ): Promise<void> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${organizationId}/permissions/${permissionId}`;

    await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  static async removePermission(
    organizationId: string,
    permissionId: string,
  ): Promise<void> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${organizationId}/permissions/${permissionId}`;

    await axios(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  static async indexOrganizationRoles(
    organizationId: string,
    options: IndexOrganizationRolesOptions,
  ): Promise<PaginatedModel<Role>> {
    const { page = 1, pageSize = 10 } = options;

    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${organizationId}/roles?page=${page}&page_size=${pageSize}`;

    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async getAllOrganizationRoutesByOrganizationId(
    organizationId: string,
  ): Promise<OrganizationApiRoute[]> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${organizationId}/organization-routes/all`;

    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async getAllOrganizationCompetitionsRugbyByOrganizationId(
    organizationId: string,
  ): Promise<OrganizationCompetitionRugby[]> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${organizationId}/organization-competitions-rugby/all`;

    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async refillTokensByOrganizationId(
    organizationId: string,
    number_of_tokens: number,
  ): Promise<Organization> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${organizationId}/refill-tokens`;

    const response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: { number_of_tokens },
    });

    return response.data;
  }

  static async getAllOrganizationGamesByOrganizationId(
    organizationId: string,
  ): Promise<OrganizationGameBought[]> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${organizationId}/organization-games-rugby/all`;

    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }
}
