import { useNavigate } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { FaFileDownload } from "react-icons/fa";

import Loader from "@pelote/components/loader/Loader";
import FormButton from "@/ui-lib/form-button/FormButton";
import Table from "@/ui-lib/table/Table";
import Pagination from "@/ui-lib/pagination/Pagination";
import usePageAdminGameListVideos from "./usePageAdminGameListVideos";
import { Video, VideoProcessingStatus } from "@pelote/types/Video";

import "./pageAdminGameListVideos.scss";

const PageAdminGameListVideos = () => {
  const {
    sportPath,
    setCurrentPage,
    currentPage,
    pageSize,
    paginatedVideos,
    isLoading,
    handleDeleteVideo,
  } = usePageAdminGameListVideos({
    pageSize: 10,
  });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Video>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),
    columlHelper.accessor("processing_status", {
      cell: (info) => t(info.getValue()),
      header: () => t("admin.video.status"),
    }),
    columlHelper.display({
      id: "download",
      cell: (info) =>
        info.row.original.processing_status ===
          VideoProcessingStatus.finished && (
          <FaFileDownload
            onClick={() => {
              window.open(info.row.original.uri, "_blank");
            }}
            size={24}
            cursor="pointer"
            fill="green"
          />
        ),
      header: () => t("admin.game.video"),
    }),

    columlHelper.display({
      id: "action",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigate(
                `/admin/pelote/${sportPath}/games/${info.row.original.game_id}/videos/${info.row.original.id}`,
              )
            }
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => handleDeleteVideo(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="game-videos-list">
      <div className="create-video">
        <FormButton
          labelName={t("admin.video.create")}
          onClick={() => navigate(`create`)}
        />
      </div>
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table
              columns={columns}
              data={paginatedVideos?.items ?? []}
              hasGlobalFilter={true}
            />
            <div>
              <Pagination
                totalItems={paginatedVideos?.pagination.total_items ?? 0}
                itemsPerPage={pageSize}
                currentPage={currentPage}
                onClick={setCurrentPage}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PageAdminGameListVideos;
