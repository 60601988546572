import * as Sentry from "@sentry/react";

import { AppEnv } from "./constants";

class SentryManager {
  private dsn: string;

  constructor() {
    this.dsn = "";
    if (
      import.meta.env.VITE_APP_ENV == AppEnv.development ||
      import.meta.env.VITE_APP_ENV == AppEnv.production
    ) {
      this.dsn =
        "https://7fb95a4ab450555a704c6008723d1fac@o4507821381648384.ingest.de.sentry.io/4507855422947408";
    }
  }

  init() {
    Sentry.init({
      dsn: this.dsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
}

export default new SentryManager();
