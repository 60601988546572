import { useQuery } from "@tanstack/react-query";

import { User } from "@user/types/User";
import UsersApi from "@user/api/UsersApi";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { cacheKey } from "@/utils/constants";

interface useUsersOptions {
  page?: number;
  pageSize?: number;
  lastName?: string;
  organizationName?: string;
  skip?: boolean;
}

function usePaginatedUsers(options: Partial<useUsersOptions> = {}): {
  paginatedUsers: PaginatedModel<User> | undefined;
  isLoadingUsers: boolean;
  isErrorUsers: boolean;
} {
  const { page = 1, pageSize = 10, lastName, organizationName, skip } = options;

  // Fetch a list of Users based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: [cacheKey.indexUsers, page, pageSize, lastName, organizationName],
    queryFn: () =>
      UsersApi.index({ page, pageSize, lastName, organizationName }),
    enabled: !skip,
  });

  return {
    paginatedUsers: data,
    isLoadingUsers: isLoading,
    isErrorUsers: isError,
  };
}

export default usePaginatedUsers;
