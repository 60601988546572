import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import RolesApi, { UpdateRoleInputs } from "@user/api/RolesApi";
import useRole from "@user/hooks/useRole";
import { APIError } from "@user/types/ApiError";
import { Role } from "@user/types/Role";
import { Permission } from "@/domains/user/types/Permission";
import { cacheKey } from "@/utils/constants";

function usePageAdminRoleEdit(): {
  role: Role | undefined;
  updateRole: (updatedRole: UpdateRoleInputs) => void;
  isRoleUpdateSuccess: boolean;
  rolePermissions: Permission[] | undefined;
  isLoading: boolean;
} {
  const { id: roleId } = useParams();
  const { t } = useTranslation();

  // Fetch the role and the role permissions
  const {
    role,
    permissions: paginatedRolePermissions,
    isLoading: isRoleLoading,
  } = useRole(roleId, { withPermissions: true, skip: !roleId });
  const rolePermissions = paginatedRolePermissions?.items || [];

  // Handle the edition of a role
  const queryClient = useQueryClient();
  const { mutate: updateRoleInApi, isSuccess: isRoleUpdateSuccess } =
    useMutation({
      mutationFn: roleId
        ? (updatedRole: UpdateRoleInputs) =>
            RolesApi.update(roleId, updatedRole)
        : undefined,
      onSuccess: (data: Role) => {
        queryClient.invalidateQueries({
          queryKey: [cacheKey.showRole, data.id],
        });
        queryClient.invalidateQueries({
          queryKey: [cacheKey.indexRoles],
        });
        queryClient.invalidateQueries({
          queryKey: [cacheKey.indexOrganizationRoles, data.organization.id],
        });
        toast.success(t("admin.role.role-edition-success"), {
          autoClose: 3000,
        });
      },
      onError: (error: APIError) => {
        console.error(error);
        toast.error(t("admin.role.role-edition-failed"), {
          autoClose: 3000,
        });
      },
    });
  function updateRole(updatedRole: UpdateRoleInputs) {
    updateRoleInApi(updatedRole);
  }

  // Build global isLoading flag
  const isLoading = isRoleLoading;

  return {
    role,
    rolePermissions,
    updateRole,
    isRoleUpdateSuccess,
    isLoading,
  };
}

export default usePageAdminRoleEdit;
