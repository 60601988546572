import { useQuery } from "@tanstack/react-query";

import { Domain } from "@user/types/Domain";
import DomainsApi from "@user/api/DomainsApi";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { cacheKey } from "@/utils/constants";

interface usePaginatedDomainsOptions {
  page?: number;
  pageSize?: number;
  skip?: boolean;
}

function usePaginatedDomains(
  options: Partial<usePaginatedDomainsOptions> = {},
): {
  paginatedDomains: PaginatedModel<Domain> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, skip } = options;

  // Fetch a list of Domains based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: [cacheKey.indexDomains, page, pageSize],
    queryFn: () => DomainsApi.index(page, pageSize),
    enabled: !skip,
  });

  return {
    paginatedDomains: data,
    isLoading,
    isError,
  };
}

export default usePaginatedDomains;
