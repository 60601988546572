import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UpdateGameInputs } from "@pelote/api/GamesApi";
import Loader from "@pelote/components/loader/Loader";
import usePageAdminGameEdit from "./usePageAdminGameEdit";
import { CodingStatus, GameTeamFormat } from "@pelote/types/Game";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./pageAdminGameEdit.scss";

const PageAdminGameEdit = () => {
  const {
    game,
    isLoading,
    handleEditGame,
    playgroundSelectOptions,
    colorSelectOptions,
  } = usePageAdminGameEdit();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UpdateGameInputs>();

  const codingStarted = game?.coding_status !== CodingStatus.todo;

  useEffect(() => {
    setValue("name", game?.name || "");
    setValue("description", game?.description || "");
    setValue("start_time", game?.start_time || "");
    setValue("coding_status", game?.coding_status || CodingStatus.todo);
    setValue("team_format", game?.team_format || GameTeamFormat.double);
    setValue("score_team_1", game?.score_team_1);
    setValue("score_team_2", game?.score_team_2);
    setValue("color_team_1", game?.color_team_1);
    setValue("color_team_2", game?.color_team_2);
    setValue(
      "playground_id",
      game?.playground.id || (playgroundSelectOptions?.[0]?.value as string),
    );
  }, [game]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="game-edition">
      <form onSubmit={handleSubmit(handleEditGame)}>
        <div className="edit-game-form-container">
          <div className="edit-game-name">
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div className="edit-game-start_time">
            <FormInput
              id="start_time"
              labelName={t("date")}
              type={InputType.dateTimeLocal}
              textToDisplayIfError={t("admin.necessary.date")}
              errorMessage={errors.start_time?.message}
              register={register}
            />
          </div>
          <div className="edit-game-team_format">
            <FormSelect
              id="team_format"
              labelName={t("format")}
              options={Object.values(GameTeamFormat).map((option) => ({
                value: option,
                label: option,
              }))}
              textToDisplayIfError={t("admin.necessary.format")}
              errorMessage={errors.team_format?.message}
              register={register}
              disabled={codingStarted}
            />
          </div>
          <div className="edit-game-coding_status">
            <FormSelect
              id="coding_status"
              labelName={t("admin.codingStatus")}
              options={Object.values(CodingStatus).map((option) => ({
                value: option,
                label: option,
              }))}
              textToDisplayIfError={t("admin.necessary.codingStatus")}
              errorMessage={errors.coding_status?.message}
              register={register}
            />
          </div>
          <div className="edit-game-playground_id">
            <FormSelect
              id="playground_id"
              labelName={t("playground")}
              options={playgroundSelectOptions}
              textToDisplayIfError={t("admin.necessary.playground")}
              errorMessage={errors.playground_id?.message}
              register={register}
              disabled={codingStarted}
            />
          </div>
          <div className="edit-game-description">
            <FormInput
              id="description"
              labelName={t("description")}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="edit-game-score-1">
            <FormInput
              id="score_team_1"
              labelName={"Score " + t("team") + " 1"}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="edit-game-score-2">
            <FormInput
              id="score_team_2"
              labelName={"Score " + t("team") + " 2"}
              type={InputType.text}
              register={register}
            />
          </div>
          <div className="edit-game-color-1">
            <FormSelect
              id="color_team_1"
              labelName={t("admin.color.color") + " " + t("team") + " 1"}
              options={colorSelectOptions}
              register={register}
            />
          </div>
          <div className="edit-game-color-2">
            <FormSelect
              id="color_team_2"
              labelName={t("admin.color.color") + " " + t("team") + " 2"}
              options={colorSelectOptions}
              register={register}
            />
          </div>
        </div>
        <div className="edit-game-buttons">
          <FormGoBackButton
            labelName={t("admin.return")}
            onClick={() => navigate(`/admin/pelote/cesta-punta/games`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </div>
  );
};

export default PageAdminGameEdit;
