import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import ButtonCodage from "@/ui-lib/button-codage/ButtonCodage";
import usePaginatedActionTypes from "@pelote/hooks/usePaginatedActionTypes";
import { ActionType } from "@pelote/types/ActionType";
import { Label } from "@pelote/types/Label";
import { Option } from "@pelote/types/Option";
import {
  setActionTypeIdAndLabelsOptions,
  setCoordinates,
  setResult,
  setSide,
  setType,
  setWallZone,
} from "@pelote/pages/cesta-punta/user/codage/redux/features/currentActionReducer";
import UseCurrentActionCestaPunta from "@pelote/pages/cesta-punta/user/codage/redux/hooks/currentActionCestaPuntaHook";
import UsePreviousAction from "@pelote/pages/cesta-punta/user/codage/redux/hooks/previousActionHook";
import UseActions from "@pelote/pages/cesta-punta/user/codage/redux/hooks/usePaginatedActions";
import { getFilteredLabels } from "@pelote/utils/getFilteredLabels";
import UseGameDetails from "@pelote/pages/cesta-punta/user/codage/redux/hooks/gameDetailsHook";

import "./ActionsPanel.scss";

export function ActionsPanels() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { playgroundNumberAreas } = UseGameDetails();
  const {
    currentAction,
    currentActionReceptionPositiveShot,
    isSelected,
    actionTypeId,
    isEditMode,
  } = UseCurrentActionCestaPunta();
  const { isFirstActionIsNotBut } = UseActions();
  const { previousAction } = UsePreviousAction();
  const {
    paginatedActionTypes,
    reception,
    service,
    shot,
    typeLabel,
    serviceLabels: allServiceLabels,
    receptionLabels: allReceptionLabels,
    shotLabels: allShotLabels,
    automatizationLabels,
    automatizationOptions,
  } = usePaginatedActionTypes();

  const [serviceLabels, setServiceLabels] = useState<Label[]>([]);
  const [receptionNegativeLabels, setReceptionNegativeLabels] = useState<
    Label[]
  >([]);
  const [receptionPositiveShotLabels, setReceptionPositiveShotLabels] =
    useState<Label[]>([]);

  function handleActionClick(buttonType: string, action: ActionType) {
    // If buttpnType is action_type and action is shot, we have to dispatch the labels of reception positive too
    if (reception && buttonType === "action_type" && action.name === "shot") {
      dispatch(
        setActionTypeIdAndLabelsOptions({
          actionType: action,
          actionIdReception: reception.id,
        }),
      );
      selectMostCommonLabelsOptions();
    } else {
      dispatch(setActionTypeIdAndLabelsOptions({ actionType: action }));
      // If reception negative clicked we automatized the negative label
      if (action.name === "reception") {
        if (
          automatizationLabels.labelTypeReceptionResult &&
          automatizationOptions.optionNegativeTypeReceptionResult
        ) {
          handleLabelOption(
            automatizationLabels.labelTypeReceptionResult,
            automatizationOptions.optionNegativeTypeReceptionResult,
            receptionNegativeLabels,
          );
        }
      }
      if (action.name === "service") {
        if (
          automatizationLabels.labelWallZoneShot &&
          automatizationOptions.optionWallZoneLeftShot
        ) {
          handleLabelOption(
            automatizationLabels.labelWallZoneShot,
            automatizationOptions.optionWallZoneLeftShot,
            serviceLabels,
          );
        }
      }
    }
    // Automatization of coordinates for service action in function of the current cancha
    const coordinatesMap = {
      13: { x: 3, y: 1 },
      9: { x: 0, y: 1 },
      14: { x: 4, y: 1 },
      15: { x: 5, y: 1 },
    };
    if (action.name === "service") {
      const coordinates = coordinatesMap[playgroundNumberAreas];
      if (coordinates) {
        dispatch(setCoordinates({ coordinates }));
      } else {
        // Handle default values similar as 13 zones
        const defaultValueX = 3;
        const defaultValueY = 1;
        dispatch(
          setCoordinates({
            coordinates: { x: defaultValueX, y: defaultValueY },
          }),
        );
      }
    }
  }

  function handleLabelOption(label: Label, option: Option, labels: Label[]) {
    // If labels lenght is 5, it means that we are in the shot action and we have to dispatch labelOptions for the action needed
    if (labels.length === 5) {
      if (label.name.includes("side")) {
        dispatch(
          setSide({
            idSide: label.id,
            option: option,
            label: label,
            isShot: true,
          }),
        );
      } else if (label.name.includes("type_reception")) {
        dispatch(
          setType({
            idType: label.id,
            option: option,
            label: label,
            isShot: true,
          }),
        );
        if (
          automatizationOptions.optionPositiveTypeReceptionResult &&
          automatizationLabels.labelTypeReceptionResult
        ) {
          // If we have a reception positive, by default we set de result to positive
          dispatch(
            setResult({
              idResult: automatizationLabels.labelTypeReceptionResult.id,
              option: automatizationOptions.optionPositiveTypeReceptionResult,
              label: automatizationLabels.labelTypeReceptionResult,
              isShot: true,
            }),
          );
        }
      } else if (label.name.includes("type_shot")) {
        dispatch(
          setType({
            idType: label.id,
            option: option,
            label: label,
            isShot: true,
          }),
        );
      } else if (label.name.includes("wall_zone")) {
        dispatch(
          setWallZone({ idWallZone: label.id, option: option, label: label }),
        );
      } else if (label.name.includes("result")) {
        dispatch(
          setResult({ idResult: label.id, option: option, label: label }),
        );
      }
    }
    // If not we only dispatch the labelOptions for the action needed
    else {
      if (label.name.includes("type")) {
        dispatch(setType({ idType: label.id, option: option, label: label }));
      } else if (label.name.includes("wall_zone")) {
        dispatch(
          setWallZone({ idWallZone: label.id, option: option, label: label }),
        );
      } else if (label.name.includes("result")) {
        dispatch(
          setResult({ idResult: label.id, option: option, label: label }),
        );
      } else if (label.name.includes("side")) {
        dispatch(setSide({ idSide: label.id, option: option, label: label }));
      }
    }
  }

  function selectMostCommonLabelsOptions() {
    // Handle Type of Shot classic
    if (
      automatizationLabels.labelTypeShot &&
      automatizationOptions.optionTypeShot
    ) {
      handleLabelOption(
        automatizationLabels.labelTypeShot,
        automatizationOptions.optionTypeShot,
        receptionPositiveShotLabels,
      );
    }
    // Handle wall_zon onto Left
    if (
      automatizationLabels.labelWallZoneShot &&
      automatizationOptions.optionWallZoneLeftShot
    ) {
      handleLabelOption(
        automatizationLabels.labelWallZoneShot,
        automatizationOptions.optionWallZoneLeftShot,
        receptionPositiveShotLabels,
      );
    }

    // Handle result shot Neutral
    if (
      automatizationLabels.labelResultShot &&
      automatizationOptions.optionResultNeutralShot
    ) {
      handleLabelOption(
        automatizationLabels.labelResultShot,
        automatizationOptions.optionResultNeutralShot,
        receptionPositiveShotLabels,
      );
    }
  }

  // This useEffect is used to automatize the action when the previous action is a service
  useEffect(() => {
    if (!paginatedActionTypes) return;
    if (previousAction.action.action_type_id === service?.id) {
      const previousType = previousAction.action.label_option_ids.find(
        (item) => item.label_id === typeLabel?.id,
      );
      const previousOption = paginatedActionTypes?.items[0].labels
        .find((label) => label.name === "type_service")
        ?.options.find((option) => option.id === previousType?.option_id);
      if (
        previousOption?.name !== "ace" &&
        previousOption?.name !== "pasa" &&
        previousOption?.name !== "falta"
      ) {
        // If service + "arriere" or "avant" we automatize on action shot (that means reception positive + shot)
        handleActionClick("action_type", paginatedActionTypes?.items[2]);
        if (
          automatizationLabels.labelTypeReception &&
          receptionPositiveShotLabels
        ) {
          if (previousOption?.name === "back") {
            // insert type Bote as Reception Positive for Arriere
            if (automatizationOptions.optionTypeBote) {
              handleLabelOption(
                automatizationLabels.labelTypeReception,
                automatizationOptions.optionTypeBote,
                receptionPositiveShotLabels,
              );
            }
          } else if (previousOption?.name === "front") {
            // insert type Aire as Reception Positive for Avant
            if (automatizationOptions.optionTypeAire) {
              handleLabelOption(
                automatizationLabels.labelTypeReception,
                automatizationOptions.optionTypeAire,
                receptionPositiveShotLabels,
              );
            }
          }
        }
        selectMostCommonLabelsOptions();
      } else {
        // if service + "ace" or "falta" we automatize on action BUT again the only difference will be on player selected
        handleActionClick("action_type", paginatedActionTypes?.items[0]);
      }
    }
  }, [previousAction]);

  // Initialization of the page with paginatedActionTypes and set correct labels, options values ordered
  useEffect(() => {
    if (allServiceLabels && allReceptionLabels && allShotLabels) {
      const {
        serviceLabelsOrdered,
        receptionNegativeLabels,
        receptionPositiveShotLabels,
      } = getFilteredLabels(
        allServiceLabels,
        allReceptionLabels,
        allShotLabels,
      );
      setServiceLabels(serviceLabelsOrdered);
      setReceptionNegativeLabels(receptionNegativeLabels);
      setReceptionPositiveShotLabels(receptionPositiveShotLabels);
    }
  }, [paginatedActionTypes, isFirstActionIsNotBut]);

  // This function allows us to show the labels and options of each action type in the interface
  function getLabelsOptions(labels?: Label[]) {
    return labels?.map((label) => (
      <Fragment key={label.id}>
        <p>
          {t(
            `codagePelote.actionsPanel.actionTypeDetails.titleLabels.${label.name}`,
          )}
        </p>
        {label?.options.map((option) => {
          const isSelectedLabelOption =
            currentAction.label_option_ids.find(
              (labelOption) =>
                labelOption.label_id === label.id &&
                labelOption.option_id === option.id,
            ) !== undefined;
          const isSelectedReceptionLabelOption =
            currentActionReceptionPositiveShot.label_option_ids.find(
              (labelOption) =>
                labelOption.label_id === label.id &&
                labelOption.option_id === option.id,
            ) !== undefined;
          return (
            <ButtonCodage
              key={option.id}
              text={t(
                `codagePelote.actionsPanel.actionTypeDetails.option.${option.name}`,
              )}
              className="button-codage-action"
              isSelected={
                isSelected.actionType &&
                (isSelected.typeReception ||
                  isSelected.typeSevice ||
                  isSelected.typeShot ||
                  isSelected.side ||
                  isSelected.result ||
                  isSelected.wallZone) &&
                (isSelectedLabelOption || isSelectedReceptionLabelOption)
              }
              onClick={() => handleLabelOption(label, option, labels)}
              customStyles={{
                backgroundColor: "gold",
                color: "white",
                outline: "none",
              }}
            />
          );
        })}
      </Fragment>
    ));
  }

  return (
    <Fragment>
      <div className="selected-actions">
        <div className="selection-actions-buttons">
          {service && (
            <ButtonCodage
              key={service.id}
              text={t(`codagePelote.actionsPanel.actionType.${service.name}`)}
              className="handleActionType"
              isSelected={isSelected.actionType && actionTypeId === service.id}
              disabled={isEditMode && currentAction.id !== service.id}
              onClick={() => handleActionClick("action_type", service)}
              customStyles={{
                backgroundColor: "gold",
                color: "white",
                outline: "none",
              }}
              customStylesDisabled={{
                backgroundColor: "grey",
                color: "white",
                outline: "none",
                cursor: "not-allowed",
              }}
            />
          )}
          {/* Throw here the action Reception Positive + Shot (all labels) */}
          {shot && (
            <div
              data-tooltip-id="idNotFirsActionTooltip"
              data-tooltip-content={
                isFirstActionIsNotBut
                  ? t("codagePelote.actionsPanel.mustCodeBut.sentence")
                  : ""
              }
            >
              <ButtonCodage
                key={shot.id}
                text={t(
                  `codagePelote.actionsPanel.actionTypeModifiedForFront.${shot.name}`,
                )}
                className="handleActionType"
                isSelected={isSelected.actionType && actionTypeId === shot.id}
                disabled={
                  isFirstActionIsNotBut ||
                  (isEditMode && currentAction.id !== shot.id)
                }
                // Must dispatch the labels of reception positive too
                onClick={() => handleActionClick("action_type", shot)}
                customStyles={{
                  backgroundColor: "gold",
                  color: "white",
                  outline: "none",
                }}
                customStylesDisabled={{
                  backgroundColor: "grey",
                  color: "white",
                  outline: "none",
                  cursor: "not-allowed",
                }}
              />
              <Tooltip
                id="idNotFirsActionTooltip"
                className="idNotFirsActionTooltipClass"
                classNameArrow="idNotFirsActionTooltipArrow"
              />
            </div>
          )}
          {/* Throw here the action Reception Negative, Only here the labels negatice */}
          {reception && (
            <div
              data-tooltip-id="idNotFirsActionTooltip"
              data-tooltip-content={
                isFirstActionIsNotBut
                  ? t("codagePelote.actionsPanel.mustCodeBut.sentence")
                  : ""
              }
            >
              <ButtonCodage
                key={reception.id}
                text={t(
                  `codagePelote.actionsPanel.actionTypeModifiedForFront.${reception.name}`,
                )}
                className="handleActionType"
                isSelected={
                  isSelected.actionType && actionTypeId === reception.id
                }
                disabled={
                  isFirstActionIsNotBut ||
                  (isEditMode && currentAction.id !== reception.id)
                }
                // Must dispatch the labels of reception negative only
                onClick={() => handleActionClick("action_type", reception)}
                customStyles={{
                  backgroundColor: "gold",
                  color: "white",
                  outline: "none",
                }}
                customStylesDisabled={{
                  backgroundColor: "grey",
                  color: "white",
                  outline: "none",
                  cursor: "not-allowed",
                }}
              />
              <Tooltip
                id="idNotFirsActionTooltip"
                className="idNotFirsActionTooltipClass"
                classNameArrow="idNotFirsActionTooltipArrow"
              />
            </div>
          )}
        </div>
        {isSelected.actionType && (
          // Here you have to get the correct labels in function of actions
          <div>
            {actionTypeId === service?.id && getLabelsOptions(serviceLabels)}
            {actionTypeId === reception?.id &&
              getLabelsOptions(receptionNegativeLabels)}
            {actionTypeId === shot?.id &&
              getLabelsOptions(receptionPositiveShotLabels)}
          </div>
        )}
      </div>
    </Fragment>
  );
}
