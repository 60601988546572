import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import OptionsApi, { UpdateOptionInputs } from "@pelote/api/OptionsApi";
import useOption from "@pelote/hooks/useOption";
import { APIError } from "@pelote/types/ApiError";
import { Option } from "@pelote/types/Option";

function usePageAdminOptionEdit(): {
  sportPath: string | undefined;
  option: Option | undefined;
  handleEditOption: (updatedOption: UpdateOptionInputs) => void;
  isLoading: boolean;
} {
  // Get the option id from the route params
  const { id: optionId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Fetch the option
  const { option, isLoading: isOptionLoading } = useOption(optionId || "");

  // Handle the edition of a option
  const queryClient = useQueryClient();
  const { mutate: updateOption } = useMutation({
    mutationFn: optionId
      ? (updatedOption: UpdateOptionInputs) =>
          OptionsApi.update(optionId, updatedOption)
      : undefined,

    onSuccess: (data: Option) => {
      queryClient.invalidateQueries({
        queryKey: ["showOption", data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexOptions"],
      });
      toast.success(t("admin.option.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/options`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edit"), {
        autoClose: 3000,
      });
    },
  });
  function handleEditOption(updatedOption: UpdateOptionInputs) {
    if (currentSport === undefined) return;
    updateOption(updatedOption);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isOptionLoading;

  return {
    sportPath,
    option,
    handleEditOption,
    isLoading,
  };
}

export default usePageAdminOptionEdit;
