import { useTranslation } from "react-i18next";

// Graphs
import ScatterChartAreas from "@/ui-lib/core/scatterChart/ScatterChart";
import PieChartGraph from "@/ui-lib/core/pieChart/PieChart";
import LinearGauge from "@/ui-lib/core/linearGauge/LinearGauge";

// CSS
import "@pelote/pages/cesta-punta/gamePdf/PageGamePdf.scss";

// Models
import { MetricsGame } from "@pelote/types/MetricsGame";
import { MetricsGameTeam } from "@pelote/types/MetricsGameTeam";
import { MetricsGameActionsList } from "@pelote/types/MetricsGameActionsList";
import { Game } from "@pelote/types/Game";
import { ReceptionForActionsList } from "@pelote/types/MetricsGameActionsList";
import { Playground } from "@pelote/types/Playground";

// Header & Footer
import GamePdfHeader from "@pelote/components/GamePdf/GamePdfHeader";
import GamePdfFooter from "@pelote/components/GamePdf/GamePdfFooter";

const GamePdfReceptions = ({
  game,
  playground,
  metricsGameData,
  metricsGameTeam1Data,
  metricsGameTeam2Data,
  metricsGameActionsListData,
}: {
  game: Game | undefined;
  playground: Playground | undefined;
  metricsGameData: MetricsGame | undefined;
  metricsGameTeam1Data: MetricsGameTeam | undefined;
  metricsGameTeam2Data: MetricsGameTeam | undefined;
  metricsGameActionsListData: MetricsGameActionsList | undefined;
}) => {
  const { t } = useTranslation();

  if (
    !game ||
    !metricsGameData ||
    !metricsGameTeam1Data ||
    !metricsGameTeam2Data ||
    !metricsGameActionsListData
  ) {
    return null;
  }

  const pageTitle: string = t("metricsPelote.receptions");
  const pageNumber: number = 3;

  // Get players infos
  const playerFrontTeam1 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  const playerFrontTeam2 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  const playerBackTeam1 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  const playerBackTeam2 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  game.players.forEach((player) => {
    if (player.favorite_position === "front" && player.team_number === 1) {
      playerFrontTeam1.firstname = player.firstname;
      playerFrontTeam1.lastname = player.lastname;
      playerFrontTeam1.photo_url = player.photo_url;
    }
    if (player.favorite_position === "front" && player.team_number === 2) {
      playerFrontTeam2.firstname = player.firstname;
      playerFrontTeam2.lastname = player.lastname;
      playerFrontTeam2.photo_url = player.photo_url;
    }
    if (player.favorite_position === "back" && player.team_number === 1) {
      playerBackTeam1.firstname = player.firstname;
      playerBackTeam1.lastname = player.lastname;
      playerBackTeam1.photo_url = player.photo_url;
    }
    if (player.favorite_position === "back" && player.team_number === 2) {
      playerBackTeam2.firstname = player.firstname;
      playerBackTeam2.lastname = player.lastname;
      playerBackTeam2.photo_url = player.photo_url;
    }
  });

  // Get Teams names
  const team1Name =
    playerFrontTeam1.firstname.charAt(0) +
    "." +
    playerFrontTeam1.lastname.toUpperCase() +
    " / " +
    playerBackTeam1.firstname.charAt(0) +
    "." +
    playerBackTeam1.lastname.toUpperCase();
  const team2Name =
    playerFrontTeam2.firstname.charAt(0) +
    "." +
    playerFrontTeam2.lastname.toUpperCase() +
    " / " +
    playerBackTeam2.firstname.charAt(0) +
    "." +
    playerBackTeam2.lastname.toUpperCase();

  // Get report title
  const reportTitle: string = `Rapport Partie ${team1Name} vs ${team2Name}`;

  const testReceptionZoneDataTeam1: ReceptionForActionsList[] =
    metricsGameActionsListData.actions.filter(
      (action): action is ReceptionForActionsList =>
        action.team_number === 1 && action.type === "reception",
    );

  const testReceptionZoneDataTeam2: ReceptionForActionsList[] =
    metricsGameActionsListData.actions.filter(
      (action): action is ReceptionForActionsList =>
        action.team_number === 2 && action.type === "reception",
    );

  const colorsServiceTypes: Array<string> = [
    "green",
    "blue",
    "purple",
    "orange",
    "red",
  ];

  const receptionTypeMetrics = [
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.aire"),
      valueTeam1: metricsGameTeam1Data.receptions.type_reception.aire.total,
      valueTeam2: metricsGameTeam2Data.receptions.type_reception.aire.total,
      valuePositiveTeam1:
        metricsGameTeam1Data.receptions.result_reception.positive.type_reception
          .aire.total,
      valuePositiveTeam2:
        metricsGameTeam2Data.receptions.result_reception.positive.type_reception
          .aire.total,
      valueNegativeTeam1:
        metricsGameTeam1Data.receptions.result_reception.negative.type_reception
          .aire.total,
      valueNegativeTeam2:
        metricsGameTeam2Data.receptions.result_reception.negative.type_reception
          .aire.total,
      percentageTeam1:
        (100 * metricsGameTeam1Data.receptions.type_reception.aire.total) /
        metricsGameTeam1Data.receptions.total,
      percentageTeam2:
        (100 * metricsGameTeam2Data.receptions.type_reception.aire.total) /
        metricsGameTeam2Data.receptions.total,
      color: "green",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.bote"),
      valueTeam1: metricsGameTeam1Data.receptions.type_reception.bote.total,
      valueTeam2: metricsGameTeam2Data.receptions.type_reception.bote.total,
      valuePositiveTeam1:
        metricsGameTeam1Data.receptions.result_reception.positive.type_reception
          .bote.total,
      valuePositiveTeam2:
        metricsGameTeam2Data.receptions.result_reception.positive.type_reception
          .bote.total,
      valueNegativeTeam1:
        metricsGameTeam1Data.receptions.result_reception.negative.type_reception
          .bote.total,
      valueNegativeTeam2:
        metricsGameTeam2Data.receptions.result_reception.negative.type_reception
          .bote.total,
      percentageTeam1:
        (100 * metricsGameTeam1Data.receptions.type_reception.bote.total) /
        metricsGameTeam1Data.receptions.total,
      percentageTeam2:
        (100 * metricsGameTeam2Data.receptions.type_reception.bote.total) /
        metricsGameTeam2Data.receptions.total,
      color: "blue",
    },
    {
      name: t(
        "codagePelote.actionsPanel.actionTypeDetails.option.bote_corrido",
      ),
      valueTeam1:
        metricsGameTeam1Data.receptions.type_reception.bote_corrido.total,
      valueTeam2:
        metricsGameTeam2Data.receptions.type_reception.bote_corrido.total,
      valuePositiveTeam1:
        metricsGameTeam1Data.receptions.result_reception.positive.type_reception
          .bote_corrido.total,
      valuePositiveTeam2:
        metricsGameTeam2Data.receptions.result_reception.positive.type_reception
          .bote_corrido.total,
      valueNegativeTeam1:
        metricsGameTeam1Data.receptions.result_reception.negative.type_reception
          .bote_corrido.total,
      valueNegativeTeam2:
        metricsGameTeam2Data.receptions.result_reception.negative.type_reception
          .bote_corrido.total,
      percentageTeam1:
        (100 *
          metricsGameTeam1Data.receptions.type_reception.bote_corrido.total) /
        metricsGameTeam1Data.receptions.total,
      percentageTeam2:
        (100 *
          metricsGameTeam2Data.receptions.type_reception.bote_corrido.total) /
        metricsGameTeam2Data.receptions.total,
      color: "purple",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.bote_pronto"),
      valueTeam1:
        metricsGameTeam1Data.receptions.type_reception.bote_pronto.total,
      valueTeam2:
        metricsGameTeam2Data.receptions.type_reception.bote_pronto.total,
      valuePositiveTeam1:
        metricsGameTeam1Data.receptions.result_reception.positive.type_reception
          .bote_pronto.total,
      valuePositiveTeam2:
        metricsGameTeam2Data.receptions.result_reception.positive.type_reception
          .bote_pronto.total,
      valueNegativeTeam1:
        metricsGameTeam1Data.receptions.result_reception.negative.type_reception
          .bote_pronto.total,
      valueNegativeTeam2:
        metricsGameTeam2Data.receptions.result_reception.negative.type_reception
          .bote_pronto.total,
      percentageTeam1:
        (100 *
          metricsGameTeam1Data.receptions.type_reception.bote_pronto.total) /
        metricsGameTeam1Data.receptions.total,
      percentageTeam2:
        (100 *
          metricsGameTeam2Data.receptions.type_reception.bote_pronto.total) /
        metricsGameTeam2Data.receptions.total,
      color: "orange",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.rebote"),
      valueTeam1: metricsGameTeam1Data.receptions.type_reception.rebote.total,
      valueTeam2: metricsGameTeam2Data.receptions.type_reception.rebote.total,
      valuePositiveTeam1:
        metricsGameTeam1Data.receptions.result_reception.positive.type_reception
          .rebote.total,
      valuePositiveTeam2:
        metricsGameTeam2Data.receptions.result_reception.positive.type_reception
          .rebote.total,
      valueNegativeTeam1:
        metricsGameTeam1Data.receptions.result_reception.negative.type_reception
          .rebote.total,
      valueNegativeTeam2:
        metricsGameTeam2Data.receptions.result_reception.negative.type_reception
          .rebote.total,
      percentageTeam1:
        (100 * metricsGameTeam1Data.receptions.type_reception.rebote.total) /
        metricsGameTeam1Data.receptions.total,
      percentageTeam2:
        (100 * metricsGameTeam2Data.receptions.type_reception.rebote.total) /
        metricsGameTeam2Data.receptions.total,
      color: "red",
    },
  ];

  return (
    <div className="page">
      <GamePdfHeader
        pageTitle={pageTitle}
        team1_color={game.color_team_1}
        team1_name={team1Name}
        team2_color={game.color_team_2}
        team2_name={team2Name}
      />
      <div
        className="body"
        style={{
          display: "grid",
          gridTemplateRows: "18% 2% 24% 2% 26% 2% 26%",
        }}
      >
        <div
          style={{
            display: "grid",
            gridRow: "1 / 1",
            gridTemplateColumns: "2% 20% 1% 54% 1% 20% 2%",
          }}
        >
          <div className="box" style={{ gridColumn: "2 / 2" }}>
            <h5 className="subtitle">
              {t(
                "codagePelote.actionsPanel.actionTypeDetails.titleLabels.side",
              )}{" "}
            </h5>
            {PieChartGraph({
              categories: [
                t(
                  "codagePelote.actionsPanel.actionTypeDetails.option.forehand",
                ),
                t(
                  "codagePelote.actionsPanel.actionTypeDetails.option.backhand",
                ),
              ],
              values: [
                metricsGameTeam1Data.receptions.side.forehand,
                metricsGameTeam1Data.receptions.side.backhand,
              ],
              colors: ["blue", "orange"],
              height: 150,
            })}
          </div>
          <div className="box" style={{ gridColumn: "4 / 4" }}>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th scope="col" style={{ width: "40%" }}></th>
                  <th scope="col" style={{ width: "5%" }}></th>
                  <th scope="col" style={{ width: "10%" }}></th>
                  <th scope="col" style={{ width: "5%" }}></th>
                  <th scope="col" style={{ width: "40%" }}></th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ lineHeight: "9mm" }}>
                  <td colSpan={5}>
                    <h5 className="subtitle">
                      {t(
                        "codagePelote.actionsPanel.actionTypeDetails.titleLabels.result_shot",
                      )}{" "}
                    </h5>
                  </td>
                </tr>
                <tr style={{ lineHeight: "3mm" }}>
                  <td>
                    <div>
                      {LinearGauge({
                        leftToRight: true,
                        value:
                          (100 *
                            metricsGameTeam1Data.receptions.result_reception
                              .positive.total) /
                          metricsGameTeam1Data.receptions.total,
                        color: game.color_team_1 || "",
                        width: 200,
                      })}
                    </div>
                  </td>
                  <td className="small-text">
                    {metricsGameTeam1Data.receptions.result_reception.positive
                      .total +
                      "/" +
                      metricsGameTeam1Data.receptions.total}
                  </td>
                  <td className="small-text">{t("total")}</td>
                  <td className="small-text">
                    {metricsGameTeam2Data.receptions.result_reception.positive
                      .total +
                      "/" +
                      metricsGameTeam2Data.receptions.total}
                  </td>
                  <td>
                    <div>
                      {LinearGauge({
                        leftToRight: false,
                        value:
                          (100 *
                            metricsGameTeam2Data.receptions.result_reception
                              .positive.total) /
                          metricsGameTeam2Data.receptions.total,
                        color: game.color_team_2 || "",
                        width: 200,
                      })}
                    </div>
                  </td>
                </tr>
                <tr style={{ lineHeight: "9mm" }}>
                  <td colSpan={5}>
                    <h5 className="subtitle">
                      {t("metricsPelote.graphSuccessSide")}{" "}
                    </h5>
                  </td>
                </tr>
                <tr style={{ lineHeight: "3mm" }}>
                  <td>
                    <div>
                      {LinearGauge({
                        leftToRight: true,
                        value:
                          (100 *
                            metricsGameTeam1Data.receptions.result_reception
                              .positive.side.forehand) /
                          metricsGameTeam1Data.receptions.side.forehand,
                        color: game.color_team_1 || "",
                        width: 200,
                      })}
                    </div>
                  </td>
                  <td className="small-text">
                    {metricsGameTeam1Data.receptions.result_reception.positive
                      .side.forehand +
                      "/" +
                      metricsGameTeam1Data.receptions.side.forehand}
                  </td>
                  <td className="small-text">
                    {t(
                      "codagePelote.actionsPanel.actionTypeDetails.option.forehand",
                    )}
                  </td>
                  <td className="small-text">
                    {metricsGameTeam2Data.receptions.result_reception.positive
                      .side.forehand +
                      "/" +
                      metricsGameTeam2Data.receptions.side.forehand}
                  </td>
                  <td>
                    <div>
                      {LinearGauge({
                        leftToRight: false,
                        value:
                          (100 *
                            metricsGameTeam2Data.receptions.result_reception
                              .positive.side.forehand) /
                          metricsGameTeam2Data.receptions.side.forehand,
                        color: game.color_team_2 || "",
                        width: 200,
                      })}
                    </div>
                  </td>
                </tr>
                <tr style={{ lineHeight: "3mm" }}>
                  <td>
                    <div>
                      {LinearGauge({
                        leftToRight: true,
                        value:
                          (100 *
                            metricsGameTeam1Data.receptions.result_reception
                              .positive.side.backhand) /
                          metricsGameTeam1Data.receptions.side.backhand,
                        color: game.color_team_1 || "",
                        width: 200,
                      })}
                    </div>
                  </td>
                  <td className="small-text">
                    {metricsGameTeam1Data.receptions.result_reception.positive
                      .side.backhand +
                      "/" +
                      metricsGameTeam1Data.receptions.side.backhand}
                  </td>
                  <td className="small-text">
                    {t(
                      "codagePelote.actionsPanel.actionTypeDetails.option.backhand",
                    )}
                  </td>
                  <td className="small-text">
                    {metricsGameTeam2Data.receptions.result_reception.positive
                      .side.backhand +
                      "/" +
                      metricsGameTeam2Data.receptions.side.backhand}
                  </td>
                  <td>
                    <div>
                      {LinearGauge({
                        leftToRight: false,
                        value:
                          (100 *
                            metricsGameTeam2Data.receptions.result_reception
                              .positive.side.backhand) /
                          metricsGameTeam2Data.receptions.side.backhand,
                        color: game.color_team_2 || "",
                        width: 200,
                      })}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="box" style={{ gridColumn: "6 / 6" }}>
            <h5 className="subtitle">
              {t(
                "codagePelote.actionsPanel.actionTypeDetails.titleLabels.side",
              )}{" "}
            </h5>
            {PieChartGraph({
              categories: [
                t(
                  "codagePelote.actionsPanel.actionTypeDetails.option.forehand",
                ),
                t(
                  "codagePelote.actionsPanel.actionTypeDetails.option.backhand",
                ),
              ],
              values: [
                metricsGameTeam2Data.receptions.side.forehand,
                metricsGameTeam2Data.receptions.side.backhand,
              ],
              colors: ["blue", "orange"],
              height: 150,
            })}
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridRow: "3 / 3",
            gridTemplateColumns: "2% 96% 2%",
          }}
        >
          <div
            className="box"
            style={{
              display: "grid",
              gridColumn: "2 / 2",
              gridTemplateColumns: "33% 2% 30% 2% 33%",
            }}
          >
            <div style={{ gridColumn: "1 / 1" }}>
              {PieChartGraph({
                categories: [
                  t("codagePelote.actionsPanel.actionTypeDetails.option.aire"),
                  t("codagePelote.actionsPanel.actionTypeDetails.option.bote"),
                  t(
                    "codagePelote.actionsPanel.actionTypeDetails.option.bote_corrido",
                  ),
                  t(
                    "codagePelote.actionsPanel.actionTypeDetails.option.bote_pronto",
                  ),
                  t(
                    "codagePelote.actionsPanel.actionTypeDetails.option.rebote",
                  ),
                ],
                values: [
                  metricsGameTeam1Data.receptions.type_reception.aire.total,
                  metricsGameTeam1Data.receptions.type_reception.bote.total,
                  metricsGameTeam1Data.receptions.type_reception.bote_corrido
                    .total,
                  metricsGameTeam1Data.receptions.type_reception.bote_pronto
                    .total,
                  metricsGameTeam1Data.receptions.type_reception.rebote.total,
                ],
                colors: colorsServiceTypes,
                height: 200,
              })}
            </div>
            <div style={{ gridColumn: "3 / 3", height: "100%", width: "100%" }}>
              <table style={{ height: "90%", width: "100%" }}>
                <thead>
                  <tr>
                    <th scope="col" style={{ width: "15%" }}></th>
                    <th scope="col" style={{ width: "15%" }}></th>
                    <th scope="col" style={{ width: "15%" }}></th>
                    <th scope="col" style={{ width: "10%" }}></th>
                    <th scope="col" style={{ width: "15%" }}></th>
                    <th scope="col" style={{ width: "15%" }}></th>
                    <th scope="col" style={{ width: "15%" }}></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <h5 className="subtitle">{t("-")} </h5>
                    </td>
                    <td>
                      <h5 className="subtitle">{t("+")} </h5>
                    </td>
                    <td>
                      <h5 className="small-text">{t("total")} </h5>
                    </td>
                    <td></td>
                    <td>
                      <h5 className="small-text">{t("total")} </h5>
                    </td>
                    <td>
                      <h5 className="subtitle">{t("+")} </h5>
                    </td>
                    <td>
                      <h5 className="subtitle">{t("-")} </h5>
                    </td>
                  </tr>
                  {receptionTypeMetrics.map((metric) => (
                    <tr key={metric.name}>
                      <td>
                        {" "}
                        <p className="small-text">
                          {metric.valueNegativeTeam1}
                        </p>{" "}
                      </td>
                      <td>
                        {" "}
                        <p className="small-text">
                          {metric.valuePositiveTeam1}
                        </p>{" "}
                      </td>
                      <td>
                        {" "}
                        <p className="small-text">{metric.valueTeam1}</p>{" "}
                      </td>
                      <td>
                        <p
                          className="small-text"
                          style={{
                            borderRadius: "10px",
                            background: metric.color,
                          }}
                        >
                          {metric.name}
                        </p>
                      </td>
                      <td>
                        {" "}
                        <p className="small-text">{metric.valueTeam2}</p>{" "}
                      </td>
                      <td>
                        {" "}
                        <p className="small-text">
                          {metric.valuePositiveTeam2}
                        </p>{" "}
                      </td>
                      <td>
                        {" "}
                        <p className="small-text">
                          {metric.valueNegativeTeam2}
                        </p>{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ gridColumn: "5 / 5" }}>
              {PieChartGraph({
                categories: [
                  t("codagePelote.actionsPanel.actionTypeDetails.option.aire"),
                  t("codagePelote.actionsPanel.actionTypeDetails.option.bote"),
                  t(
                    "codagePelote.actionsPanel.actionTypeDetails.option.bote_corrido",
                  ),
                  t(
                    "codagePelote.actionsPanel.actionTypeDetails.option.bote_pronto",
                  ),
                  t(
                    "codagePelote.actionsPanel.actionTypeDetails.option.rebote",
                  ),
                ],
                values: [
                  metricsGameTeam2Data.receptions.type_reception.aire.total,
                  metricsGameTeam2Data.receptions.type_reception.bote.total,
                  metricsGameTeam2Data.receptions.type_reception.bote_corrido
                    .total,
                  metricsGameTeam2Data.receptions.type_reception.bote_pronto
                    .total,
                  metricsGameTeam2Data.receptions.type_reception.rebote.total,
                ],
                colors: colorsServiceTypes,
                height: 200,
              })}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridRow: "5 / 5",
            gridTemplateColumns: "2% 96% 2%",
          }}
        >
          <div className="box" style={{ display: "grid", gridColumn: "2 / 2" }}>
            <div
              style={{ gridColumn: "1 / 3", gridRow: "1 / 1", height: "10mm" }}
            >
              <h5 className="subtitle">
                {t("metricsPelote.graphPositiveReceptionZone")}
              </h5>
            </div>
            <div
              style={{
                gridColumn: "1 / 1",
                gridRow: "2 / 2",
                height: "55mm",
                width: "100mm",
              }}
            >
              {ScatterChartAreas({
                data: testReceptionZoneDataTeam1.filter(
                  (action) => action.result_reception === "positive",
                ),
                type: "reception",
                numberOfAreas: playground?.number_areas || 0,
                height: 200,
              })}
            </div>
            <div
              style={{
                gridColumn: "2 / 2",
                gridRow: "2 / 2",
                height: "55mm",
                width: "100mm",
              }}
            >
              {ScatterChartAreas({
                data: testReceptionZoneDataTeam2.filter(
                  (action) => action.result_reception === "positive",
                ),
                type: "reception",
                numberOfAreas: playground?.number_areas || 0,
                height: 200,
              })}
            </div>
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridRow: "7 / 7",
            gridTemplateColumns: "2% 96% 2%",
          }}
        >
          <div className="box" style={{ display: "grid", gridColumn: "2 / 2" }}>
            <div
              style={{ gridColumn: "1 / 3", gridRow: "1 / 1", height: "10mm" }}
            >
              <h5 className="subtitle">
                {t("metricsPelote.graphNegativeReceptionZone")}
              </h5>
            </div>
            <div
              style={{
                gridColumn: "1 / 1",
                gridRow: "2 / 2",
                height: "55mm",
                width: "100mm",
              }}
            >
              {ScatterChartAreas({
                data: testReceptionZoneDataTeam1.filter(
                  (action) => action.result_reception === "negative",
                ),
                type: "reception",
                numberOfAreas: playground?.number_areas || 0,
                height: 200,
              })}
            </div>
            <div
              style={{
                gridColumn: "2 / 2",
                gridRow: "2 / 2",
                height: "55mm",
                width: "100mm",
              }}
            >
              {ScatterChartAreas({
                data: testReceptionZoneDataTeam2.filter(
                  (action) => action.result_reception === "negative",
                ),
                type: "reception",
                numberOfAreas: playground?.number_areas || 0,
                height: 200,
              })}
            </div>
          </div>
        </div>
      </div>
      <GamePdfFooter pageNumber={pageNumber} reportTitle={reportTitle} />
    </div>
  );
};

export default GamePdfReceptions;
