import { useState } from "react";

import { PaginatedModel } from "@user/types/PaginatedModel";
import usePaginatedSports from "@user/hooks/usePaginatedSports";
import { Sport } from "@user/types/Sport";

function usePageAdminSportList({ pageSize }: { pageSize: number }): {
  paginatedSports: PaginatedModel<Sport> | undefined;
  isLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
} {
  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Get the paginated sports
  const { paginatedSports, isLoading: isSportsLoading } = usePaginatedSports({
    page: currentPage,
    pageSize,
  });

  // Build the global isLoading flag
  const isLoading = isSportsLoading;

  return {
    paginatedSports,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
  };
}

export default usePageAdminSportList;
