import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import { Game } from "@pelote/types/Game";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { SelectOption } from "@/ui-lib/select/Select";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";
import usePaginatedPlayerGames from "@pelote/hooks/usePaginatedPlayerGames";

function usePagePlayerDetails({
  pageSize,
  playerId,
}: {
  pageSize: number;
  playerId: string;
}): {
  isLoading: boolean;
  seasonSelectOptions: SelectOption[];
  selectedSeasonOption: SelectOption | null;
  competitionSelectOptions: SelectOption[];
  selectedCompetitionOption: SelectOption | null;
  paginatedPlayerGames: PaginatedModel<Game> | undefined;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
  handleChangeSeasonOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangeCompetitionOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);
  const { t } = useTranslation();

  // Get the sport path from the current sport
  //const sportPath = currentSport?.url_segment;

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSeasonOption, setSelectedSeasonOption] =
    useState<SelectOption | null>(null);
  const [selectedCompetitionOption, setSelectedCompetitionOption] =
    useState<SelectOption | null>(null);

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons(
    {
      page: 1,
      pageSize: 10,
      sortBy: "name:desc",
      sportId: currentSport?.id,
    },
  );
  // Convert the seasons to select options
  const seasonSelectOptions = convertItemsToSelectOptions(
    paginatedSeasons?.items || [],
    "id",
    "name",
  );
  seasonSelectOptions.unshift({ label: t("allSeasons"), value: "" });

  // Get the paginated competitions
  const { paginatedCompetitions, isLoading: isCompetitionsLoading } =
    usePaginatedCompetitions({
      page: 1,
      pageSize: 10,
      sportId: currentSport?.id,
    });

  // Convert the competitions to select options
  const competitionSelectOptions = convertItemsToSelectOptions(
    paginatedCompetitions?.items || [],
    "id",
    "name",
  );
  competitionSelectOptions.unshift({
    label: t("allCompetitions"),
    value: "",
  });

  // Get the paginated games
  const { paginatedPlayerGames, isLoading: isGamesLoading } =
    usePaginatedPlayerGames({
      seasonId: selectedSeasonOption?.value as string,
      competitionId: selectedCompetitionOption?.value as string,
      page: currentPage,
      pageSize: 100,
      playerId,
    });

  const handleChangeSeasonOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedSeasonOption(
      seasonSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };
  const handleChangeCompetitionOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedCompetitionOption(
      competitionSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };
  const isLoading =
    isUserLoading ||
    isGamesLoading ||
    isSeasonsLoading ||
    isCompetitionsLoading;

  return {
    isLoading,
    seasonSelectOptions,
    selectedSeasonOption,
    competitionSelectOptions,
    selectedCompetitionOption,
    paginatedPlayerGames,
    setCurrentPage,
    currentPage,
    pageSize,
    handleChangeSeasonOption,
    handleChangeCompetitionOption,
  };
}

export default usePagePlayerDetails;
