import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { Configuration, LogLevel } from "@azure/msal-browser";

const b2cPolicies = {
  names: {
    signIn: import.meta.env.VITE_ADB2C_SIGNIN,
  },
  authorities: {
    signIn: {
      authority: import.meta.env.VITE_ADB2C_SIGNIN_AUTHORITY,
    },
  },
  authorityDomain: import.meta.env.VITE_ADB2C_AUTHORITY_DOMAIN,
};

const msalConfig: Configuration = {
  auth: {
    clientId: import.meta.env.VITE_ADB2C_MULTISPORTS_FRONT_CLIENT_ID,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: window.location.origin, // The redirect URI has to be registered in the Azure portal
    postLogoutRedirectUri: window.location.origin, // The redirect URI has to be registered in the Azure portal
    navigateToLoginRequestUrl: false, // Workaround to prevent a redirect loop
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    // This is the logger configuration
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          // case LogLevel.Info:
          //   console.info(message);
          //   return;
          // case LogLevel.Verbose:
          //   console.debug(message);
          //   return;
          default:
            return;
        }
      },
    },
  },
};

const protectedResources = {
  multisportsAPI: {
    endpoint: import.meta.env.VITE_API_URL,
    scopes: {
      crud: [import.meta.env.VITE_API_CRUD_SCOPE_URL],
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
const apisScopes = [...protectedResources.multisportsAPI.scopes.crud];

class MSALManager {
  private instance: PublicClientApplication;

  public constructor() {
    this.instance = new PublicClientApplication(msalConfig);
    // Private constructor to prevent instantiation outside of the class
    /**
     * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
     * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
     */
    // Default to using the first account if no account is active on page load
    if (
      !this.instance.getActiveAccount() &&
      this.instance.getAllAccounts().length > 0
    ) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      this.instance.setActiveAccount(this.instance.getAllAccounts()[0]);
    }

    this.instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
          event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload &&
        "account" in event.payload &&
        event.payload.account
      ) {
        this.instance.setActiveAccount(event.payload.account);
      }
    });
  }

  public async getInstance() {
    await this.instance.initialize();
    return this.instance;
  }

  public async getAccessToken() {
    const activeAccount = this.instance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = this.instance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
      /*
       * User is not signed in. Throw error or wait for user to login.
       * Do not attempt to log a user in outside of the context of MsalProvider
       */
      // console.error("User is not signed in");
      throw new Error("User is not signed in");
    }

    const request = {
      scopes: protectedResources.multisportsAPI.scopes.crud,
      account: activeAccount || accounts[0],
    };

    const authResult = await this.instance
      .acquireTokenSilent(request)
      .catch((error) => {
        console.error("Error in acquireTokenSilent:", error);
        return { accessToken: "" };
      });

    return authResult.accessToken;
  }
}

const msalManager = new MSALManager();

export { apisScopes, msalManager as default };
