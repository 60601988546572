import { toast } from "react-toastify";
import { t } from "i18next";
import { AxiosError } from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PaginatedModel } from "@user/types/PaginatedModel";
import useRole from "@user/hooks/useRole";
import { Permission } from "@user/types/Permission";
import RolesApi from "@user/api/RolesApi";
import { APIError, APIErrorType } from "@user/types/ApiError";
import useOrganization from "@user/hooks/useOrganization";
import { cacheKey } from "@/utils/constants";

function usePageAdminRolePermissions(): {
  paginatedRolePermissions: PaginatedModel<Permission> | undefined;
  rolePermissionsPage: number;
  rolePermissionsPageSize: number;
  setRolePermissionsPage: (page: number) => void;
  setRolePermissionsPageSize: (pageSize: number) => void;
  addPermissionToRole: (permissionId: string) => void;
  removePermissionFromRole: (permissionId: string) => void;
  paginatedPermissions: PaginatedModel<Permission> | undefined;
  permissionsPage: number;
  permissionsPageSize: number;
  setPermissionsPage: (page: number) => void;
  setPermissionsPageSize: (pageSize: number) => void;
  isLoading: boolean;
} {
  const { id: roleId } = useParams();

  // Handle pagination for role permissions
  const [rolePermissionsPage, setRolePermissionsPage] = useState(1);
  const [rolePermissionsPageSize, setRolePermissionsPageSize] = useState(5);
  const [permissionsPage, setPermissionsPage] = useState(1);
  const [permissionsPageSize, setPermissionsPageSize] = useState(5);

  // Get the paginated role permissions
  const {
    role,
    permissions: paginatedRolePermissions,
    isLoading: isRolePermissionsLoading,
  } = useRole(roleId, {
    withPermissions: true,
    permissionsPage: rolePermissionsPage,
    permissionsPageSize: rolePermissionsPageSize,
  });

  // Get the paginated permissions for the organization
  const { permissions: paginatedPermissions, isLoading: isPermissionsLoading } =
    useOrganization(role?.organization.id, {
      withPermissions: true,
    });

  // Add a permission to the role
  const queryClientAdd = useQueryClient();
  const { mutate: addPermissionToRoleInApi } = useMutation({
    mutationFn: (permissionId: string) => {
      if (!roleId) return new Promise(() => {});
      return RolesApi.addPermission(roleId, permissionId);
    },
    onSuccess: () => {
      queryClientAdd.invalidateQueries({
        queryKey: [cacheKey.indexRolePermissions, roleId],
      });

      toast.success(t("admin.role.permissions-assignation-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ALREADY_EXISTS) {
        toast.error(t("admin.role.permissions-already-assigned"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("admin.role.permissions-assignation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function addPermissionToRole(permissionId: string) {
    addPermissionToRoleInApi(permissionId);
  }

  // Remove a permission from the role
  const queryClientRemove = useQueryClient();
  const { mutate: removePermissionFromRoleInApi } = useMutation({
    mutationFn: (permissionId: string) => {
      if (!roleId) return new Promise(() => {});
      return RolesApi.removePermission(roleId, permissionId);
    },
    onSuccess: () => {
      queryClientRemove.invalidateQueries({
        queryKey: [cacheKey.indexRolePermissions, roleId],
      });

      toast.success(t("admin.role.permissions-removal-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: AxiosError) => {
      console.error(error);
      toast.error(t("admin.role.permissions-removal-failed"), {
        autoClose: 3000,
      });
    },
  });
  function removePermissionFromRole(permissionId: string) {
    removePermissionFromRoleInApi(permissionId);
  }

  // Build the global isLoading flag
  const isLoading = isRolePermissionsLoading || isPermissionsLoading;

  return {
    paginatedRolePermissions,
    rolePermissionsPage,
    rolePermissionsPageSize,
    setRolePermissionsPage,
    setRolePermissionsPageSize,
    addPermissionToRole,
    removePermissionFromRole,
    paginatedPermissions,
    permissionsPage,
    permissionsPageSize,
    setPermissionsPage,
    setPermissionsPageSize,
    isLoading,
  };
}

export default usePageAdminRolePermissions;
