import { Upload } from "tus-js-client";
import * as CryptoJS from "crypto-js";

export interface UploadStatus {
  progressInt: number;
  status: "in_progress" | "completed" | "failed";
  progress: string;
}

// Function to generate the presigned request signature
function generatePreSignedRequestSignature(
  expirationTime: string,
  processingId: string,
): string {
  const dataToHash =
    (import.meta.env.VITE_BUNNY_LIBRARY_ID ?? "") +
    (import.meta.env.VITE_BUNNY_API_KEY ?? "") +
    expirationTime +
    processingId;

  const hash = CryptoJS.SHA256(dataToHash).toString(CryptoJS.enc.Hex);
  return hash;
}

// Handles the upload of a video on Bunny
async function uploadVideo(
  processingId: string,
  videoName: string,
  video: File,
  onProgress?: (uploadStatus: UploadStatus) => void,
  onSuccess?: (uploadStatus: UploadStatus) => void,
): Promise<Upload> {
  // Expiration set to 2 hours
  const expirationTime = Math.floor(Date.now() / 1000) + 60 * 120;
  const presignature = generatePreSignedRequestSignature(
    expirationTime.toString(),
    processingId,
  );

  const initVideoUpload = new Upload(video, {
    endpoint: "https://video.bunnycdn.com/tusupload",
    retryDelays: [0, 3000, 5000, 10000, 20000],
    metadata: {
      title: `${processingId}-${videoName}`,
    },
    headers: {
      AuthorizationSignature: presignature,
      AuthorizationExpire: expirationTime.toString(), // Expiration time as in the signature,
      VideoId: processingId, // The processingId of a previously created video object through the Create Video API call
      LibraryId: import.meta.env.VITE_BUNNY_LIBRARY_ID ?? "",
    },
    onProgress: (bytesUploaded, bytesTotal) => {
      if (!onProgress) return;

      const progress = (bytesUploaded / bytesTotal) * 100;
      const percentage = progress.toFixed(2);
      onProgress({
        status: "in_progress",
        progress: percentage,
        progressInt: parseInt(progress.toFixed(0)),
      });
    },
    onError: (error) => {
      if (!onProgress) return;

      onProgress({ status: "failed", progress: "0.00", progressInt: 0 });
      console.error("Upload failed because: " + error.message);
    },
    onSuccess: () => {
      if (!onSuccess) return;
      onSuccess({
        progressInt: 100,
        progress: "100%",
        status: "completed",
      });
    },
  });

  return initVideoUpload;
}

export { uploadVideo };
