import { useQuery } from "@tanstack/react-query";

import { ApiRoute } from "@user/types/ApiRoute";
import ApiRoutesApi from "@user/api/ApiRoutesApi";
import { cacheKey } from "@/utils/constants";

interface UseApiRoutesOptions {
  skip?: boolean; // Deactivate the hook
}

// This hooks fetch all the API routes
function useApiRoutes(useApiRoutesOptions: Partial<UseApiRoutesOptions> = {}): {
  apiRoutes: ApiRoute[] | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { skip = false } = useApiRoutesOptions;

  // Fetch all the ApiRoutes from the API
  // This fetch is automatically triggered at startup and when any parameter changes
  const {
    data: apiRoutes,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [cacheKey.getAllApiRoutes],
    queryFn: () => ApiRoutesApi.getAll(),
    enabled: !skip,
  });

  return {
    apiRoutes,
    isLoading,
    isError,
  };
}

export default useApiRoutes;
