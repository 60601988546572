import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Role } from "@user/types/Role";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminRoleList from "./usePageAdminRoleList";
import Pagination from "@/ui-lib/pagination/Pagination";

import "./pageAdminRoleList.scss";
import FormButton from "@/ui-lib/form-button/FormButton";
import useNavigateInApp from "@/hooks/useNavigateInApp";

const PageAdminRoleList = () => {
  const {
    paginatedRoles,
    setCurrentPage,
    currentPage,
    pageSize,
    deleteRole,
    isLoading,
  } = usePageAdminRoleList({
    pageSize: 25,
  });
  const { t } = useTranslation();
  const { navigateTo } = useNavigateInApp();

  const columlHelper = createColumnHelper<Role>();
  const columns = [
    columlHelper.accessor("organization.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.role.organization"),
    }),
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.role.name"),
    }),
    columlHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.role.description"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigateTo(`/admin/user/roles/${info.row.original.id}`)
            }
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => deleteRole(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  return (
    <div className="page-admin-role-list">
      <AdminHeader text={t("admin.role.roles")} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="roles-list-content">
          <Table
            columns={columns}
            data={paginatedRoles?.items ?? []}
            hasGlobalFilter={true}
          />
          <div className="pagination">
            <Pagination
              totalItems={paginatedRoles?.pagination.total_items ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdminRoleList;
