import { toast } from "react-toastify";
import { t } from "i18next";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { PaginatedModel } from "@user/types/PaginatedModel";
import useOrganization from "@user/hooks/useOrganization";
import { Role } from "@user/types/Role";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import RolesApi from "@user/api/RolesApi";
import { AxiosError } from "axios";
import { APIError, APIErrorType } from "@/domains/user/types/ApiError";
import { cacheKey } from "@/utils/constants";

function usePageAdminOrganizationRoles(): {
  organizationId: string | undefined;
  paginatedRoles: PaginatedModel<Role> | undefined;
  rolesPage: number;
  rolesPageSize: number;
  setRolesPage: (page: number) => void;
  setRolesPageSize: (pageSize: number) => void;
  deleteRole: (roleId: string) => void;
  isLoading: boolean;
} {
  const { id: organizationId } = useParams();

  // Handle pagination for organization roles
  const [rolesPage, setRolesPage] = useState(1);
  const [rolesPageSize, setRolesPageSize] = useState(10);

  // Get the paginated organization roles
  const { roles: paginatedRoles, isLoading: isRolesLoading } = useOrganization(
    organizationId,
    {
      withRoles: true,
      rolesPage: rolesPage,
      rolesPageSize: rolesPageSize,
    },
  );

  // Delete a role
  const queryClient = useQueryClient();
  const { mutate: deleteRoleInApi } = useMutation({
    mutationFn: (roleId: string) => {
      return RolesApi.delete(roleId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexOrganizationRoles, organizationId],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexRoles],
      });

      toast.success(t("admin.role.role-deletion-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ATTACHED_ITEMS) {
        toast.error(t("admin.role.users-still-attached-remove-them-first"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("admin.role.role-deletion-failed"), {
        autoClose: 3000,
      });
    },
  });
  function deleteRole(roleId: string) {
    deleteRoleInApi(roleId);
  }

  // Build the global isLoading flag
  const isLoading = isRolesLoading;

  return {
    organizationId,
    paginatedRoles,
    rolesPage,
    rolesPageSize,
    setRolesPage,
    setRolesPageSize,
    deleteRole,
    isLoading,
  };
}

export default usePageAdminOrganizationRoles;
