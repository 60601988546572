import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";

import { UpdateVideoInputs } from "@pelote/api/VideosApi";
import usePageAdminGameEditVideo from "./usePageAdminGameEditVideo";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./pageAdminGameEditVideo.scss";

const PageAdminGameEditVideo = () => {
  const { video, handleEditVideo } = usePageAdminGameEditVideo();
  const { id } = useParams();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdateVideoInputs>({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    setValue("name", video?.name || "");
  }, [video]);

  return (
    <div id="page-admin-game-video-edit">
      <form onSubmit={handleSubmit(handleEditVideo)}>
        <div className="form-inputs">
          <div className="input-name">
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
            <FormInput
              id="uri"
              labelName={t("url")}
              type={InputType.text}
              disabled
              register={register}
            />
          </div>
        </div>
        <div className="form-buttons">
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() =>
              navigate(`/admin/pelote/cesta-punta/games/${id}/videos`)
            }
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </div>
  );
};

export default PageAdminGameEditVideo;
