import { useQuery } from "@tanstack/react-query";

import UsersApi from "@user/api/UsersApi";
import { User } from "@user/types/User";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { Role } from "@user/types/Role";
import { Sport } from "@user/types/Sport";
import { cacheKey } from "@/utils/constants";

interface useUserOptions {
  skip?: boolean; // Deactivate the hook
  withRoles?: boolean; // Enable fetching the roles
  rolesPage?: number;
  rolesPageSize?: number;
  withSports?: boolean; // Enable fetching the sports
  sportsPage?: number;
  sportsPageSize?: number;
}

function useUser(
  userId?: string,
  options: Partial<useUserOptions> = {},
): {
  user: User | undefined;
  roles: PaginatedModel<Role> | undefined;
  sports: PaginatedModel<Sport> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const {
    skip = false,
    withRoles = false,
    rolesPage = 1,
    rolesPageSize = 10,
    withSports = false,
    sportsPage = 1,
    sportsPageSize = 10,
  } = options;

  // Fetch the user
  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useQuery({
    queryKey: [cacheKey.showUser, userId],
    queryFn: () => {
      if (!userId) return;
      return UsersApi.show(userId);
    },
    enabled: !!userId && !skip,
  });

  // Fetch the roles if required
  const {
    data: paginatedRoles,
    isLoading: isRolesLoading,
    isError: isRolesError,
  } = useQuery({
    queryKey: [cacheKey.indexUserRoles, userId, rolesPage, rolesPageSize],
    queryFn: () => {
      if (!userId) return;
      return UsersApi.indexUserRoles(userId, {
        page: rolesPage,
        pageSize: rolesPageSize,
      });
    },
    enabled: !!userId && !skip && withRoles,
  });

  // Fetch the sports if required
  const {
    data: paginatedSports,
    isLoading: isSportsLoading,
    isError: isSportsError,
  } = useQuery({
    queryKey: [cacheKey.indexUserSports, userId, sportsPage, sportsPageSize],
    queryFn: () => {
      if (!userId) return;
      return UsersApi.indexUserSports(userId, {
        page: sportsPage,
        pageSize: sportsPageSize,
      });
    },
    enabled: !!userId && !skip && withSports,
  });

  const isLoading = isUserLoading || isRolesLoading || isSportsLoading;
  const isError = isUserError || isRolesError || isSportsError;

  return {
    user,
    roles: paginatedRoles,
    sports: paginatedSports,
    isLoading,
    isError,
  };
}

export default useUser;
