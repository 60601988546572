import {} from "@/auth/msalManager";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

const PageLogout = () => {
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect();
  }, []);

  return null;
};

export default PageLogout;
