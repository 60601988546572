import { useNavigate } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Season } from "@pelote/types/Season";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminSeasonList from "./usePageAdminSeasonList";
import FormButton from "@/ui-lib/form-button/FormButton";

const PageAdminSeasonList = () => {
  const { sportPath, paginatedSeasons, isLoading } = usePageAdminSeasonList({
    page: 1,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Season>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),

    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigate(
                `/admin/pelote/${sportPath}/seasons/${info.row.original.id}`,
              )
            }
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={t("admin.season.seasons")} />
      <FormButton
        labelName={t("admin.season.create")}
        onClick={() => navigate(`/admin/pelote/${sportPath}/seasons/create`)}
      />
      <Table
        columns={columns}
        data={paginatedSeasons?.items ?? []}
        hasGlobalFilter={true}
      />
    </>
  );
};

export default PageAdminSeasonList;
