import { GamePlayer } from "@pelote/types/GamePlayer";

export function findPlayersDouble(players: GamePlayer[]) {
  const player_front_team_1 = players.find(
    (p) => p.position === "front" && p.team_number === 1,
  );
  const player_front_team_2 = players.find(
    (p) => p.position === "front" && p.team_number === 2,
  );
  const player_back_team_1 = players.find(
    (p) => p.position === "back" && p.team_number === 1,
  );
  const player_back_team_2 = players.find(
    (p) => p.position === "back" && p.team_number === 2,
  );

  return {
    player_front_team_1,
    player_front_team_2,
    player_back_team_1,
    player_back_team_2,
  };
}
