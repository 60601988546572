import { useQuery } from "@tanstack/react-query";

import { Organization } from "@user/types/Organization";
import OrganizationsApi from "@user/api/OrganizationsApi";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { cacheKey } from "@/utils/constants";

interface useOrganizationsOptions {
  name?: string;
  isActive?: boolean;
  page?: number;
  pageSize?: number;
  skip?: boolean;
  sortBy?: string;
}

function usePaginatedOrganizations(
  options: Partial<useOrganizationsOptions> = {},
): {
  paginatedOrganizations: PaginatedModel<Organization> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { name, isActive, page = 1, pageSize = 10, skip, sortBy } = options;

  // Fetch a list of Organizations based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      cacheKey.indexOrganizations,
      name,
      isActive,
      page,
      pageSize,
      sortBy,
    ],
    queryFn: () =>
      OrganizationsApi.index(name, isActive, page, pageSize, sortBy),
    enabled: !skip,
  });

  return {
    paginatedOrganizations: data,
    isLoading,
    isError,
  };
}

export default usePaginatedOrganizations;
