import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import { Game } from "@pelote/types/Game";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import usePaginatedGames from "@pelote/hooks/usePaginatedGames";
import GamesApi from "@pelote/api/GamesApi";
import { APIError } from "@pelote/types/ApiError";
import { SelectOption } from "@/ui-lib/select/Select";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";
import usePaginatedPools from "@pelote/hooks/usePaginatedPools";
import usePaginatedRounds from "@pelote/hooks/usePaginatedRounds";
import usePaginatedStages from "@pelote/hooks/usePaginatedStages";
import { useDebounce } from "@/hooks/useDebounce";

function usePageAdminGameList({ pageSize }: { pageSize: number }): {
  sportPath: string | undefined;
  paginatedGames: PaginatedModel<Game> | undefined;
  seasonSelectOptions: SelectOption[];
  selectedSeasonOption: SelectOption | null;
  competitionSelectOptions: SelectOption[];
  selectedCompetitionOption: SelectOption | null;
  stageSelectOptions: SelectOption[];
  selectedStageOption: SelectOption | null;
  poolSelectOptions: SelectOption[];
  selectedPoolOption: SelectOption | null;
  roundSelectOptions: SelectOption[];
  selectedRoundOption: SelectOption | null;
  isLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
  searchGameInput: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDeleteGame: (id: string) => void;
  handleChangeSeasonOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangeCompetitionOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangeStageOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangePoolOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeRoundOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleResetFilters: () => void;
  handleResetSearchInput: () => void;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);
  const { t } = useTranslation();

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Set the search input state - only lastname for now
  const [searchGameInput, setSearchGameInput] = useState("");
  const debouncedSearch = useDebounce(searchGameInput, 300);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentPage !== 1 && setCurrentPage(1);
    setSearchGameInput(event.target.value);
  };

  const handleResetSearchInput = () => {
    searchGameInput && setSearchGameInput("");
    currentPage !== 1 && setCurrentPage(1);
  };

  // Reset the input and go back to the first page of pagination
  const handleResetFilters = () => {
    setSelectedSeasonOption(seasonSelectOptions[0]);
    setSelectedCompetitionOption(competitionSelectOptions[0]);
    setSelectedStageOption(stageSelectOptions[0]);
    setSelectedPoolOption(poolSelectOptions[0]);
    setSelectedRoundOption(roundSelectOptions[0]);
  };

  const [selectedSeasonOption, setSelectedSeasonOption] =
    useState<SelectOption | null>(null);
  const [selectedCompetitionOption, setSelectedCompetitionOption] =
    useState<SelectOption | null>(null);
  const [selectedStageOption, setSelectedStageOption] =
    useState<SelectOption | null>(null);
  const [selectedPoolOption, setSelectedPoolOption] =
    useState<SelectOption | null>(null);
  const [selectedRoundOption, setSelectedRoundOption] =
    useState<SelectOption | null>(null);

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons(
    {
      page: 1,
      pageSize: 10,
      sortBy: "name:desc",
      sportId: currentSport?.id,
    },
  );

  // Convert the seasons to select options
  const seasonSelectOptions = convertItemsToSelectOptions(
    paginatedSeasons?.items || [],
    "id",
    "name",
  );
  seasonSelectOptions.unshift({ label: t("admin.allSeasons"), value: "" });

  // Get the paginated competitions
  const { paginatedCompetitions, isLoading: isCompetitionsLoading } =
    usePaginatedCompetitions({
      page: 1,
      pageSize: 10,
      sportId: currentSport?.id,
    });

  // Convert the competitions to select options
  const competitionSelectOptions = convertItemsToSelectOptions(
    paginatedCompetitions?.items || [],
    "id",
    "name",
  );
  competitionSelectOptions.unshift({
    label: t("admin.allCompetitions"),
    value: "",
  });

  // Fetch a list of stages

  const { paginatedStages, isLoading: isStagesLoading } = usePaginatedStages({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: !selectedSeasonOption?.value || !selectedCompetitionOption?.value,
  });

  // Convert the stages to select options
  const stageSelectOptions = convertItemsToSelectOptions(
    paginatedStages?.items || [],
    "id",
    "name",
  );
  if (stageSelectOptions.length > 0) {
    stageSelectOptions.unshift({ label: t("admin.allStages"), value: "" });
  } else {
    stageSelectOptions.unshift({ label: t("admin.noStage"), value: "" });
  }

  // Fetch a list of pools
  const { paginatedPools, isLoading: isPoolsLoading } = usePaginatedPools({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: !selectedSeasonOption?.value || !selectedCompetitionOption?.value,
  });

  // Convert the pools to select options
  const poolSelectOptions = convertItemsToSelectOptions(
    paginatedPools?.items || [],
    "id",
    "name",
  );
  if (poolSelectOptions.length > 0) {
    poolSelectOptions.unshift({ label: t("admin.allPools"), value: "" });
  } else {
    poolSelectOptions.unshift({ label: t("admin.noPool"), value: "" });
  }

  // Fetch a list of rounds
  const { paginatedRounds, isLoading: isRoundsLoading } = usePaginatedRounds({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: !selectedSeasonOption?.value || !selectedCompetitionOption?.value,
  });

  // Convert the rounds to select options
  const roundSelectOptions = convertItemsToSelectOptions(
    paginatedRounds?.items || [],
    "id",
    "name",
  );

  roundSelectOptions.unshift({ label: t("admin.allRounds"), value: "" });

  // Get the paginated games
  const { paginatedGames, isLoading: isGamesLoading } = usePaginatedGames({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    stageId: selectedStageOption?.value as string,
    poolId: selectedPoolOption?.value as string,
    roundId: selectedRoundOption?.value as string,
    page: currentPage,
    pageSize,
    skip: selectedSeasonOption === null || selectedCompetitionOption === null,
    name: debouncedSearch,
    sortBy: "updated_at:desc",
  });

  // Handle the deletion of a game
  const queryClient = useQueryClient();
  const { mutate: deleteGame } = useMutation({
    mutationFn: (id: string) => GamesApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: ["showGame", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexGames"],
      });

      toast.success(t("admin.game.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeleteGame(id: string) {
    if (confirm(t("admin.game.confirmDeletion"))) {
      deleteGame(id);
    }
  }

  const handleChangeSeasonOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedSeasonOption(
      seasonSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };
  const handleChangeCompetitionOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedCompetitionOption(
      competitionSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };
  const handleChangeStageOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedStageOption(
      stageSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };
  const handleChangePoolOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedPoolOption(
      poolSelectOptions.find((option) => option.value === event.target.value) ||
        null,
    );
  };

  const handleChangeRoundOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedRoundOption(
      roundSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };

  // Build the global isLoading flag
  const isLoading =
    isUserLoading ||
    isGamesLoading ||
    isSeasonsLoading ||
    isCompetitionsLoading ||
    isPoolsLoading ||
    isRoundsLoading ||
    isStagesLoading;

  useEffect(() => {
    if (!paginatedSeasons || seasonSelectOptions.length === 0) return;
    const initialSeasonOption = seasonSelectOptions[0];
    setSelectedSeasonOption(initialSeasonOption);
  }, [paginatedSeasons]);

  useEffect(() => {
    if (!paginatedCompetitions || competitionSelectOptions.length === 0) return;
    const initialCompetitionOption = competitionSelectOptions[0];
    setSelectedCompetitionOption(initialCompetitionOption);
  }, [paginatedCompetitions]);

  useEffect(() => {
    if (!paginatedStages || stageSelectOptions.length === 0) return;
    const initalStageOption = stageSelectOptions[0];
    setSelectedStageOption(initalStageOption);
  }, [paginatedStages]);

  useEffect(() => {
    if (!paginatedPools || poolSelectOptions.length === 0) return;
    const initalPoolOption = poolSelectOptions[0];
    setSelectedPoolOption(initalPoolOption);
  }, [paginatedPools]);

  useEffect(() => {
    if (!paginatedRounds || roundSelectOptions.length === 0) return;
    const initialRoundOption = roundSelectOptions[0];
    setSelectedRoundOption(initialRoundOption);
  }, [paginatedRounds]);

  return {
    sportPath,
    isLoading,
    seasonSelectOptions,
    selectedSeasonOption,
    competitionSelectOptions,
    selectedCompetitionOption,
    stageSelectOptions,
    selectedStageOption,
    poolSelectOptions,
    selectedPoolOption,
    roundSelectOptions,
    selectedRoundOption,
    paginatedGames,
    setCurrentPage,
    currentPage,
    pageSize,
    searchGameInput,
    handleSearchChange,
    handleDeleteGame,
    handleChangeSeasonOption,
    handleChangeCompetitionOption,
    handleChangeStageOption,
    handleChangePoolOption,
    handleChangeRoundOption,
    handleResetFilters,
    handleResetSearchInput,
  };
}

export default usePageAdminGameList;
