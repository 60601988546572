import { useNavigate } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { PiEraserFill } from "react-icons/pi";

import usePageAdminOrganizationList from "./usePageAdminOrganizationList";
import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import { Organization } from "@user/types/Organization";
import Pagination from "@/ui-lib/pagination/Pagination";
import FormButton from "@/ui-lib/form-button/FormButton";
import Checkbox from "@/ui-lib/checkbox/Checkbox";
import StringInput from "@/ui-lib/input/StringInput";

import "./pageAdminOrganizationList.scss";

const PageAdminOrganizationList = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    paginatedOrganizations,
    filterByName,
    changeFilterByName,
    resetFilterByName,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
  } = usePageAdminOrganizationList();

  const columlHelper = createColumnHelper<Organization>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.organization.name"),
    }),
    columlHelper.accessor("is_active", {
      cell: (info) => <Checkbox defaultChecked={info.getValue()} disabled />,
      header: () => t("admin.organization.enabled"),
    }),
    columlHelper.accessor("number_of_tokens", {
      cell: (info) => info.getValue(),
      header: () => t("admin.organization.number_of_tokens"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigate(`/admin/user/organizations/${info.row.original.id}`)
            }
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  return (
    <div className="page-admin-organization-list">
      <AdminHeader text={t("admin.organization.organizations")} />
      <FormButton
        labelName={t("admin.organization.create-organization")}
        onClick={() => navigate(`/admin/user/organizations/create`)}
      />
      <div className="search-bar">
        <div className="search-bar-input search-by-name">
          <StringInput
            placeholder={t("admin.organization.search-by-name")}
            value={filterByName}
            onChange={changeFilterByName}
          />
          <button type="button" onClick={resetFilterByName}>
            <PiEraserFill size={25} />
          </button>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="organizations-list-content">
          <Table
            columns={columns}
            data={paginatedOrganizations?.items ?? []}
            hasGlobalFilter={true}
          />
          <div className="pagination">
            <Pagination
              totalItems={paginatedOrganizations?.pagination.total_items ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdminOrganizationList;
