import { useQuery } from "@tanstack/react-query";

import { Sport } from "@user/types/Sport";
import SportsApi from "@user/api/SportsApi";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { cacheKey } from "@/utils/constants";

interface useSportsOptions {
  page?: number;
  pageSize?: number;
  skip?: boolean;
}

function usePaginatedSports(options: Partial<useSportsOptions> = {}): {
  paginatedSports: PaginatedModel<Sport> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { page = 1, pageSize = 10, skip } = options;

  // Fetch a list of Sports based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const {
    data: paginatedSports,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [cacheKey.indexSports, page, pageSize],
    queryFn: () => SportsApi.index(page, pageSize),
    enabled: !skip,
  });

  return {
    paginatedSports,
    isLoading,
    isError,
  };
}

export default usePaginatedSports;
