import { SelectOption } from "@/ui-lib/select/Select";
import { useTranslation } from "react-i18next";

import "./gameFilter.scss";

interface GameFilterProps {
  seasonSelectOptions: SelectOption[];
  stageSelectOptions: SelectOption[];
  poolSelectOptions: SelectOption[];
  roundSelectOptions: SelectOption[];
  handleChangeSeasonOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangeStageOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangePoolOption: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleChangeRoundOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
}

/** GameFilter component, used to filter games by season, pool (if it exists) and round
 * @param seasonSelectOptions: SelectOption[]
 * @param poolSelectOptions: SelectOption[]
 * @param roundSelectOptions: SelectOption[]
 * @param handleChangeSeasonOption: (event: React.ChangeEvent<HTMLSelectElement>) => void
 * @param handleChangePoolOption: (event: React.ChangeEvent<HTMLSelectElement>) => void
 * @param handleChangeRoundOption: (event: React.ChangeEvent<HTMLSelectElement>) => void
 * @returns React.FC
 */

function GameFilter({
  seasonSelectOptions,
  stageSelectOptions,
  poolSelectOptions,
  roundSelectOptions,
  handleChangeSeasonOption,
  handleChangeStageOption,
  handleChangePoolOption,
  handleChangeRoundOption,
}: GameFilterProps) {
  const { t } = useTranslation();
  return (
    <div className="competition-filters">
      <h3 className="title">{t("filters")}</h3>
      <div className="container-filters">
        {seasonSelectOptions && (
          <div className="filter">
            <label htmlFor="season">{t("season")}</label>
            <select id="season" onChange={handleChangeSeasonOption}>
              {seasonSelectOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* // Do not display the pool filter if there is only one pool (all pools option) */}
        {stageSelectOptions && stageSelectOptions.length > 1 && (
          <div className="filter">
            <label htmlFor="stage">{t("stage")}</label>
            <select id="stage" onChange={handleChangeStageOption}>
              {stageSelectOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* // Do not display the pool filter if there is only one pool (all pools option) */}
        {poolSelectOptions && poolSelectOptions.length > 1 && (
          <div className="filter">
            <label htmlFor="pool">{t("pool")}</label>
            <select id="pool" onChange={handleChangePoolOption}>
              {poolSelectOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {roundSelectOptions && roundSelectOptions.length > 1 && (
          <div className="filter">
            <label htmlFor="round">{t("round")}</label>
            <select id="round" onChange={handleChangeRoundOption}>
              {roundSelectOptions?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>
    </div>
  );
}

export default GameFilter;
