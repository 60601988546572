import { useEffect, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import UsersApi, { UpdateUserInputs } from "@user/api/UsersApi";
import usePaginatedOrganizations from "@user/hooks/usePaginatedOrganizations";
import useUser from "@user/hooks/useUser";
import { APIError } from "@user/types/ApiError";
import { User } from "@user/types/User";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import { SelectOption } from "@/ui-lib/select/Select";
import usePaginatedRoles from "@user/hooks/usePaginatedRoles";
import useNavigateInApp from "@/hooks/useNavigateInApp";
import { cacheKey } from "@/utils/constants";

function usePageAdminUserEdit(): {
  user: User | undefined;
  organizationsOptions: SelectOption[];
  rolesOptions: SelectOption[];
  selectedRole: SelectOption | undefined;
  changeOrganization: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  changeRole: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  updateUser: (updatedUser: UpdateUserInputs) => void;
  sendWelcomeEmail: (userId: string) => void;
  isLoading: boolean;
} {
  const { id: userId } = useParams();
  const { navigateBack } = useNavigateInApp();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [selectedOrganization, setSelectedOrganization] = useState<
    SelectOption | undefined
  >();
  const [selectedRole, setSelectedRole] = useState<SelectOption | undefined>();

  // Fetch the user
  const { user, isLoading: isUserLoading } = useUser(userId, { skip: !userId });
  useEffect(() => {
    if (user) {
      setSelectedOrganization({
        value: user.organization.id,
        label: user.organization.name,
      });
      setSelectedRole({
        value: user.role.id,
        label: user.role.name,
      });
    }
  }, [user]);

  // Build the organizations options
  const { paginatedOrganizations, isLoading: isOrganizationsLoading } =
    usePaginatedOrganizations({
      pageSize: 1000,
    });
  const organizations = paginatedOrganizations?.items || [];
  const organizationsOptions = convertItemsToSelectOptions(
    organizations,
    "id",
    "name",
  );

  // Build the roles options
  const { paginatedRoles } = usePaginatedRoles({
    pageSize: 1000,
    organizationId: selectedOrganization?.value as string,
    skip: !selectedOrganization,
  });
  const roles = paginatedRoles?.items || [];
  const rolesOptions = convertItemsToSelectOptions(roles, "id", "name");

  // Handle the change of the organization
  function changeOrganization(event: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedOrganization(
      organizationsOptions.find(
        (organization) => organization.value === event.target.value,
      ),
    );
  }
  // Handle the change of the role
  function changeRole(event: React.ChangeEvent<HTMLSelectElement>) {
    const newRole = rolesOptions.find(
      (role) => role.value === event.target.value,
    );
    setSelectedRole(newRole);
  }

  // Handle the edition of a user
  const { mutate: updateUserInApi } = useMutation({
    mutationFn: userId
      ? (updatedUser: UpdateUserInputs) => UsersApi.update(userId, updatedUser)
      : undefined,
    onSuccess: (data: User) => {
      queryClient.invalidateQueries({
        queryKey: [cacheKey.showUser, data.id],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexUsers],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexOrganizationUsers, data.organization.id],
      });
      toast.success(t("admin.user.user-edition-success"), {
        autoClose: 3000,
      });
      navigateBack({ fallbackRoute: "/admin/user/users" });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.user-edition-failed"), {
        autoClose: 3000,
      });
    },
  });
  function updateUser(updatedUser: UpdateUserInputs) {
    updateUserInApi(updatedUser);
  }

  // Send welcome email to the user
  const { mutate: sendWelcomeEmailInApi } = useMutation({
    mutationFn: (userId: string) => UsersApi.sendWelcomeEmail(userId),
    onSuccess: (data: User) => {
      queryClient.invalidateQueries({
        queryKey: [cacheKey.showUser, userId],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexUsers],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexOrganizationUsers, data.organization.id],
      });
      toast.success(t("admin.user.welcome-email-sent-successfully"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.welcome-email-sent-failed"), {
        autoClose: 3000,
      });
    },
  });
  function sendWelcomeEmail(userId: string) {
    sendWelcomeEmailInApi(userId);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isOrganizationsLoading;

  return {
    user,
    organizationsOptions,
    rolesOptions,
    selectedRole,
    changeOrganization,
    changeRole,
    updateUser,
    sendWelcomeEmail,
    isLoading,
  };
}

export default usePageAdminUserEdit;
