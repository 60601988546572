import { createBrowserRouter } from "react-router-dom";

import EnsureAuth from "@/auth/EnsureAuth";
import PageHome from "@/pages/PageHome";
import PageLogin from "@/pages/pageLogin/PageLogin";
import PageLogout from "@/pages/pageLogin/PageLogout";
import EnsureCestaPuntaSport from "@/auth/EnsureCestaPuntaSport";
import LayoutPeloteUser from "@pelote/layout/LayoutPeloteUser/LayoutPeloteUser";
import PageNotFound from "@/pages/PageNotFound";

import LayoutAdmin from "@/layouts/LayoutAdmin/LayoutAdmin";
import routesAdminUser from "./RoutesAdminUser";
import routesAdminPeloteCestaPunta from "./RoutesAdminPeloteCestaPunta";
import routesCestaPunta from "./RoutesCestaPunta";
import PageUnauthorized from "@/pages/PageUnauthorized";

const router = createBrowserRouter([
  {
    path: "login",
    element: <PageLogin />,
  },
  {
    path: "logout",
    element: <PageLogout />,
  },
  {
    path: "/unauthorized",
    element: (
      <EnsureAuth>
        <PageUnauthorized />
      </EnsureAuth>
    ),
  },
  {
    path: "/",
    element: (
      <EnsureAuth>
        <PageHome />
      </EnsureAuth>
    ),
  },
  {
    path: "cesta-punta",
    element: (
      <EnsureAuth>
        <EnsureCestaPuntaSport>
          <LayoutPeloteUser />
        </EnsureCestaPuntaSport>
      </EnsureAuth>
    ),
    children: [...routesCestaPunta],
  },
  {
    path: "admin",
    element: (
      <EnsureAuth>
        <LayoutAdmin />
      </EnsureAuth>
    ), // Waiting to build a proper admin home page, we just display the menu
  },
  {
    path: "admin/user",
    element: (
      <EnsureAuth>
        <LayoutAdmin />
      </EnsureAuth>
    ),
    children: [...routesAdminUser],
  },
  {
    path: "admin/pelote/cesta-punta",
    element: (
      <EnsureAuth>
        <LayoutAdmin />
      </EnsureAuth>
    ),
    children: [...routesAdminPeloteCestaPunta],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

export default router;
