import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { PiEraserFill } from "react-icons/pi";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminApiRouteList from "./usePageAdminApiRouteList";
import { ApiRoute } from "@user/types/ApiRoute";
import Pagination from "@/ui-lib/pagination/Pagination";
import FormButton from "@/ui-lib/form-button/FormButton";
import StringInput from "@/ui-lib/input/StringInput";
import useNavigateInApp from "@/hooks/useNavigateInApp";

import "./pageAdminApiRouteList.scss";

const PageAdminApiRouteList = () => {
  const { navigateTo } = useNavigateInApp();
  const { t } = useTranslation();

  const {
    paginatedApiRoutes,
    filterByPath,
    setFilterByPath,
    resetFilterByPath,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
    deleteApiRoute,
  } = usePageAdminApiRouteList();

  const columlHelper = createColumnHelper<ApiRoute>();
  const columns = [
    columlHelper.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.domain.domain"),
    }),
    columlHelper.accessor("method", {
      cell: (info) => info.getValue(),
      header: () => t("admin.api-route.method"),
    }),
    columlHelper.accessor("path", {
      cell: (info) => info.getValue(),
      header: () => t("admin.api-route.path"),
    }),
    columlHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.api-route.description"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigateTo(`/admin/user/api-routes/${info.row.original.id}`)
            }
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => deleteApiRoute(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  return (
    <div className="page-admin-api-route-list">
      <AdminHeader text={t("admin.api-route.api-routes")} />
      <FormButton
        labelName={t("admin.api-route.create-api-route")}
        onClick={() => navigateTo(`/admin/user/api-routes/create`)}
      />
      <div className="search-bar">
        <div className="search-bar-input">
          <StringInput
            placeholder={t("admin.api-route.search-by-access-path")}
            value={filterByPath}
            onChange={(e) => setFilterByPath(e.target.value)}
            className="search-by-path"
          />
          <button type="button" onClick={resetFilterByPath}>
            <PiEraserFill size={25} />
          </button>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="api-routes-list-content">
          <Table
            className="table"
            columns={columns}
            data={paginatedApiRoutes?.items ?? []}
            hasGlobalFilter={true}
          />
          <div className="pagination">
            <Pagination
              totalItems={paginatedApiRoutes?.pagination.total_items ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdminApiRouteList;
