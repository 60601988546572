import { useQuery } from "@tanstack/react-query";

import RolesApi from "@user/api/RolesApi";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { User } from "@user/types/User";
import { Permission } from "@user/types/Permission";
import { Role } from "@user/types/Role";
import { cacheKey } from "@/utils/constants";

interface useRoleOptions {
  skip?: boolean; // Deactivate the hook
  withUsers?: boolean; // Enable fetching the users
  usersPage?: number;
  usersPageSize?: number;
  withPermissions?: boolean; // Enable fetching the permissions
  permissionsPage?: number;
  permissionsPageSize?: number;
}

function useRole(
  roleId?: string,
  options: Partial<useRoleOptions> = {},
): {
  role: Role | undefined;
  users: PaginatedModel<User> | undefined;
  permissions: PaginatedModel<Permission> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const {
    skip = false,
    withUsers = false,
    usersPage = 1,
    usersPageSize = 10,
    withPermissions = false,
    permissionsPage = 1,
    permissionsPageSize = 10,
  } = options;

  // Fetch the role
  const {
    data: role,
    isLoading: isRoleLoading,
    isError: isRoleError,
  } = useQuery({
    queryKey: [cacheKey.showRole, roleId],
    queryFn: () => {
      if (!roleId) return;
      return RolesApi.show(roleId);
    },
    enabled: !!roleId && !skip,
  });

  // Fetch the users if required
  const {
    data: paginatedUsers,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useQuery({
    queryKey: [cacheKey.indexRoleUsers, roleId, usersPage, usersPageSize],
    queryFn: () => {
      if (!roleId) return;
      return RolesApi.indexRoleUsers(roleId, {
        page: usersPage,
        pageSize: usersPageSize,
      });
    },
    enabled: !!roleId && withUsers,
  });

  // Fetch the permissions if required
  const {
    data: paginatedPermissions,
    isLoading: isPermissionsLoading,
    isError: isPermissionsError,
  } = useQuery({
    queryKey: [
      cacheKey.indexRolePermissions,
      roleId,
      permissionsPage,
      permissionsPageSize,
    ],
    queryFn: () => {
      if (!roleId) return;
      return RolesApi.indexRolePermissions(roleId, {
        page: permissionsPage,
        pageSize: permissionsPageSize,
      });
    },
    enabled: !!roleId && withPermissions,
  });

  const isLoading = isRoleLoading || isUsersLoading || isPermissionsLoading;
  const isError = isRoleError || isUsersError || isPermissionsError;

  return {
    role: role,
    users: paginatedUsers,
    permissions: paginatedPermissions,
    isLoading,
    isError,
  };
}

export default useRole;
