import "./CardsCompetitions.scss";

interface CardsCompetitionsProps {
  name: string;
  urlLogo?: string;
  onClick?: () => void;
}

export const CardsCompetitions = ({
  name,
  urlLogo,
  onClick,
}: CardsCompetitionsProps) => {
  return (
    <div className="cards-container" onClick={onClick}>
      <div className="card-logo-holder">
        <img src={urlLogo} alt={name + "_logo"} />
      </div>
      <div className="card-name">
        <p>{name.toUpperCase()}</p>
      </div>
    </div>
  );
};
