import { useNavigate } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import usePageAdminGameListPlayers from "./usePageAdminGameListPlayers";
import FormButton from "@/ui-lib/form-button/FormButton";
import Table from "@/ui-lib/table/Table";
import { GamePlayer } from "@pelote/types/GamePlayer";
import { CodingStatus, GameTeamFormat } from "@pelote/types/Game";
import playerDefaultImage from "/src/assets/images/default_player_photo.png";
import { getTeamSizesAndSubstitutes } from "@/domains/pelote/utils/getTeamSizesAndSubstitutes";

import "./pageAdminGameListPlayers.scss";

const PageAdminGameListPlayers = () => {
  const { isLoading, game, attachedPlayers, handleRemovePlayerToGame } =
    usePageAdminGameListPlayers();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const isCodingStarted = game?.coding_status !== CodingStatus.todo;
  const columlHelper = createColumnHelper<GamePlayer>();
  let columns = [
    columlHelper.accessor("photo_url", {
      cell: (info) => (
        <img src={info.getValue() || playerDefaultImage} alt="player-picture" />
      ),
      header: () => "",
    }),
    columlHelper.accessor("lastname", {
      cell: (info) => info.getValue(),
      header: () => t("last_name"),
    }),
    columlHelper.accessor("firstname", {
      cell: (info) => info.getValue(),
      header: () => t("firstname"),
    }),
    columlHelper.accessor("position", {
      cell: (info) => t(info.getValue()),
      header: () => t("position"),
    }),

    columlHelper.display({
      id: "action",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => handleRemovePlayerToGame(info.row.original.id)}
            disabled={isCodingStarted}
          />
        </div>
      ),
      header: () => t("action"),
    }),
  ];
  // no need for the position if the game is Single
  if (game?.team_format == GameTeamFormat.single) {
    columns.splice(3, 1);
  }

  if (isLoading) {
    return <Loader />;
  }
  // Display varies depending on the format of the game
  if (
    game?.team_format == GameTeamFormat.double ||
    game?.team_format == GameTeamFormat.triple
  ) {
    return (
      <div className="game-players">
        <div className="add-players">
          <FormButton
            labelName={t("admin.game.addPlayer")}
            onClick={() => navigate(`add`)}
            disabled={isCodingStarted}
          />
        </div>
        <div className="players">
          <Table
            columns={columns}
            data={
              attachedPlayers.filter((player) => player.team_number === 1) || []
            }
            hasGlobalFilter={false}
            title={t("team") + " 1"}
          />
          <Table
            columns={columns}
            data={
              attachedPlayers.filter((player) => player.team_number === 2) || []
            }
            hasGlobalFilter={false}
            title={t("team") + " 2"}
          />
        </div>
      </div>
    );
  } else if (game?.team_format == GameTeamFormat.single) {
    // prevent adding players if there is already one per team
    let isDisabled = false;
    const { team_1_size, team_2_size } = getTeamSizesAndSubstitutes(
      game.players,
    );
    if (team_1_size > 0 && team_2_size > 0) {
      isDisabled = true;
    }
    return (
      <div className="game-players">
        <div className="add-players">
          <FormButton
            labelName={t("admin.game.addPlayer")}
            onClick={() => navigate(`add`)}
            disabled={isDisabled}
          />
        </div>
        <div className="players">
          <Table
            columns={columns}
            data={
              attachedPlayers.filter((player) => player.team_number === 1) || []
            }
            hasGlobalFilter={false}
            title={t("player") + " 1"}
          />
          <Table
            columns={columns}
            data={
              attachedPlayers.filter((player) => player.team_number === 2) || []
            }
            hasGlobalFilter={false}
            title={t("player") + " 2"}
          />
        </div>
      </div>
    );
  }
};

export default PageAdminGameListPlayers;
