export function calculateAge(birthdate: Date | undefined) {
  if (!(birthdate === undefined)) {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age.toString();
  } else {
    return "incorrect_date";
  }
}
