import ReactPlayer from "react-player";
import { FaVolumeMute, FaVolumeUp, FaPlay, FaPause } from "react-icons/fa";

import { useAppDispatch } from "@pelote/pages/cesta-punta/user/codage/redux/hooks";
import {
  decreaseSpeed,
  increaseSpeed,
  resetSpeed,
  toggleIsMuted,
  togglePlayPause,
} from "@pelote/pages/cesta-punta/user/codage/redux/features/videoPlayerReducer";
import UseVideoPlayerData from "@pelote/pages/cesta-punta/user/codage/redux/hooks/videoPlayerHook";

import "./VideoPlayerButtons.scss";

export function VideoPlayerButtons({
  videoPlayerRef,
}: {
  videoPlayerRef: React.RefObject<ReactPlayer>;
}) {
  const dispatch = useAppDispatch();
  const { isPlaying, isMuted } = UseVideoPlayerData();

  const handleDecreaseSpeed = () => {
    dispatch(decreaseSpeed());
  };
  const handleIncreaseSpeed = () => {
    dispatch(increaseSpeed());
  };
  const handlePlayPause = () => {
    dispatch(togglePlayPause());
  };
  const handleResetSpeed = () => {
    dispatch(resetSpeed());
  };
  const seekTime = (event: React.MouseEvent, addTime: number) => {
    if (videoPlayerRef.current === null) return;
    // Use information from the event to determine a kind of multiplier (number of clicks)
    const currentTime = videoPlayerRef.current.getCurrentTime();
    videoPlayerRef.current.seekTo(currentTime + addTime * event.detail);
  };
  const toggleMute = () => {
    dispatch(toggleIsMuted());
  };

  return (
    <div className="videoplayer-buttons">
      <button
        id={isPlaying ? "pause" : "play"}
        onClick={() => handlePlayPause()}
      >
        {isPlaying ? <FaPause className="icon" /> : <FaPlay className="icon" />}
      </button>
      <button id="speed_1_2" onClick={() => handleDecreaseSpeed()}>
        1/2
      </button>
      <button id="speed_1" onClick={() => handleResetSpeed()}>
        x1
      </button>
      <button id="speed_2" onClick={() => handleIncreaseSpeed()}>
        x2
      </button>
      <button
        id="speed_-_5_sec"
        onClick={(event: React.MouseEvent) => seekTime(event, -5)}
      >
        -5
      </button>
      <button
        id="speed_+_5_sec"
        onClick={(event: React.MouseEvent) => seekTime(event, +5)}
      >
        +5
      </button>
      <button id={isMuted ? "muted" : "mute"} onClick={() => toggleMute()}>
        {isMuted ? (
          <FaVolumeMute className="icon" />
        ) : (
          <FaVolumeUp className="icon" />
        )}
      </button>
    </div>
  );
}
