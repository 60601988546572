export enum AppEnv {
  local = "local",
  development = "development",
  production = "production",
}

// React query cache keys
export enum cacheKey {
  // Domain User
  showUserMe = "showUserMe",
  showUser = "showUser", // Params: userId
  indexUserRoles = "indexUserRoles", // Params: userId, page, pageSize
  indexUserSports = "indexUserSports", // Params: userId, page, pageSize
  indexUsers = "indexUsers", // Params: page, pageSize, lastName, organizationName

  indexDomains = "indexDomains", // Params: page, pageSize

  indexSports = "indexSports", // Params: page, pageSize

  showRole = "showRole", // Params: roleId
  indexRoleUsers = "indexRoleUsers", // Params: roleId, page, pageSize
  indexRolePermissions = "indexRolePermissions", // Params: roleId, page, pageSize
  indexRoles = "indexRoles", // Params: page, pageSize, organizationId

  indexPermissions = "indexPermissions", // Params: page, pageSize

  showOrganization = "showOrganization", // Params: organizationId
  indexOrganizations = "indexOrganizations", // Params: name, isActive, page, pageSize, sortBy
  indexOrganizationUsers = "indexOrganizationUsers", // Params: organizationId, page, pageSize
  indexOrganizationPermissions = "indexOrganizationPermissions", // Params: organizationId, page, pageSize
  indexOrganizationRoles = "indexOrganizationRoles", // Params: organizationId, page, pageSize
  getAllOrganizationRoutesByOrganizationId = "getAllOrganizationRoutesByOrganizationId", // Params: organizationId
  getAllOrganizationCompetitionsRugbyByOrganizationId = "getAllOrganizationCompetitionsRugbyByOrganizationId", // Params: organizationId
  indexOrganizationTokenOperations = "indexOrganizationTokenOperations", // Params: organizationId, page, pageSize, sortBy

  showApiRoute = "showApiRoute", // Params: routeId
  indexApiRoutes = "indexApiRoutes", // Params: page, pageSize, domainId, method, path
  getAllApiRoutes = "getAllApiRoutes",
  getAllOrganizationGamesByOrganizationId = "getAllOrganizationGamesByOrganizationId", // Params: organizationId

  // Domain Rugby
  getAllRugbyCompetitions = "getAllRugbyCompetitions",
}
