import { useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import ReactPlayer from "react-player";
import { PiCheckFatDuotone } from "react-icons/pi";
import { Tooltip } from "react-tooltip";

import { useIsResponsive } from "@/hooks/ResponsiveHook";
import { TimelinePoint } from "@pelote/types/TimelineType";
import { Action } from "@pelote/types/Action";
import UseActions from "@pelote/pages/cesta-punta/user/codage/redux/hooks/usePaginatedActions";
import UseTeamsData from "@pelote/pages/cesta-punta/user/codage/redux/hooks/teamsCestaPuntaHook";
import {
  useAppDispatch,
  useAppSelector,
} from "@pelote/pages/cesta-punta/user/codage/redux/hooks";
import usePaginatedActionTypes from "@pelote/hooks/usePaginatedActionTypes";
import {
  resetInitalStateCurrentAction,
  setEditMode,
  setEditModeReceptionPositive,
} from "@pelote/pages/cesta-punta/user/codage/redux/features/currentActionReducer";
import UsePreviousAction from "@pelote/pages/cesta-punta/user/codage/redux/hooks/previousActionHook";
import {
  setPasaTeam1,
  setPasaTeam2,
  setScoreTeam1Set1,
  setScoreTeam1Set2,
  setScoreTeam1Set3,
  setScoreTeam2Set1,
  setScoreTeam2Set2,
  setScoreTeam2Set3,
} from "@pelote/pages/cesta-punta/user/codage/redux/features/scoreReducer";
import { updateScoresAndPasas } from "@pelote/utils/updateScoresAndPasas";
import { getWinningAction } from "@pelote/utils/getWinningAction";
import { focusOnAction } from "@pelote/utils/focusOnAction";
import { determineNbWinningActions } from "@pelote/utils/determineNbWinningActions";
import { setIsEditMode } from "@pelote/pages/cesta-punta/user/codage/redux/features/editModeReducer";
import UseValidationReport from "@pelote/pages/cesta-punta/user/codage/redux/hooks/validationHook";
import {
  ConsecutivePasasByTeam,
  getConsecutivePasasPerTeam,
} from "@pelote/utils/getConsecutivePasasPerTeam";

import "./Timeline.scss";

export function Timeline({
  videoPlayerRef,
}: {
  videoPlayerRef: React.RefObject<ReactPlayer>;
}) {
  const dispatch = useAppDispatch();
  const isResponsive = useIsResponsive(1366);
  const isEditMode = useAppSelector(
    (state) => state.editModeReducer.isEditMode,
  );

  // Check if the action is in edit mode and reset the state if it is not the same action
  const [idActionToEdit, setActionToEdit] = useState<string>("");
  const { allActions } = UseActions();
  const { players, colorTeam1, colorTeam2 } = UseTeamsData();
  const { previousAction } = UsePreviousAction();
  const {
    paginatedActionTypes,
    service,
    shot,
    reception,
    idActionTypeShot,
    automatizationOptions,
  } = usePaginatedActionTypes();

  const [timeline, setTimeline] = useState<TimelinePoint[]>([]);
  const [actionToEditId, setActionToEditId] = useState<string>("");
  const totalRowCount: number = timeline.length;
  const [lastActionTrIndex, setLastActionTrIndex] = useState<number | null>(
    null,
  );
  const [previousActionToFocusOn, setPreviousActionToFocusOn] =
    useState<Action | null>(null);

  const { actionIdsToCorrect } = UseValidationReport();

  // A sans doute positionner dans un fichier utils
  const getPlayerLastNameFromId = (id: string) => {
    const player = players.find((player) => player.id === id);
    return player?.lastname || null;
  };

  const getColorTeamFromPlayerId = (id: string) => {
    const player = players.find((player) => player.id === id);
    const numberTeam = player?.team_number;
    if (numberTeam === 1) {
      return colorTeam1;
    }
    if (numberTeam === 2) {
      return colorTeam2;
    }
  };

  const handleEditMode = (action: Action) => {
    dispatch(setIsEditMode({ isEditMode: true }));
    // Check if the action is in edit mode and reset the state if it is not the same action
    // When the user change of action to edit, we reset the state of the current action
    setActionToEdit(action.id);
    if (idActionToEdit !== action.id) {
      dispatch(resetInitalStateCurrentAction());
    }
    const actionWithCorrectClickTime = {
      ...action,
      click_time: action.click_time / 1000,
    };
    setActionToEditId(action.id);
    if (!paginatedActionTypes) return;
    dispatch(
      setEditMode({
        editMode: true,
        actionToEditOrDelete: actionWithCorrectClickTime,
        listActionTypes: paginatedActionTypes,
      }),
    );
    // If the action is a shot, we find index of the selected action and look for the corresponding reception positive Action
    if (action.action_type_id === idActionTypeShot) {
      const indexActionToDelete = allActions.findIndex((item) => {
        return item.id === action.id;
      });
      const receptionPositveAction = allActions[indexActionToDelete - 1];
      const actionReceptionPositiveWithCorrectClickTime = {
        ...receptionPositveAction,
        click_time: receptionPositveAction.click_time / 1000,
      };
      if (!paginatedActionTypes) return;
      dispatch(
        setEditModeReceptionPositive({
          actionReceptionPositiveToEditOrDelete:
            actionReceptionPositiveWithCorrectClickTime,
          listActionTypes: paginatedActionTypes,
        }),
      );
    }
    videoPlayerRef.current?.seekTo(action.click_time / 1000, "seconds");
  };

  // Found lastActionIndex where is the last action coded
  useEffect(() => {
    if (previousAction && timeline) {
      const trIndex = timeline.findIndex((td) =>
        td.actions.some((action) => action.id === previousAction.action.id),
      );
      setPreviousActionToFocusOn(previousAction.action);
      setLastActionTrIndex(trIndex !== -1 ? trIndex : null);
      focusOnAction(trIndex, previousAction.action, previousActionToFocusOn);
    }
  }, [previousAction, timeline]);

  useEffect(() => {
    if (isEditMode === false) {
      setActionToEditId("");
    }
  }, [isEditMode]);

  useEffect(() => {
    buildTimeline(allActions);
  }, [allActions, service, shot]);

  function buildTimeline(allActions: Action[]) {
    const timeline: TimelinePoint[] = [];
    let currentPoint: TimelinePoint | null = null;
    // Set 1
    let scoreTeam1Set1: number = 0;
    let scoreTeam2Set1: number = 0;
    // Set 2
    let scoreTeam1Set2: number = 0;
    let scoreTeam2Set2: number = 0;
    // Set 3
    let scoreTeam1Set3: number = 0;
    let scoreTeam2Set3: number = 0;

    // Count Pasa
    let pasaTeam1: number = 0;
    let pasaTeam2: number = 0;

    // Get the details of consecutives pasas per team
    const detailConsecutivesPasas: ConsecutivePasasByTeam =
      getConsecutivePasasPerTeam(allActions, players);

    allActions.forEach((action) => {
      // If action is TYPE SERVICE, so we create a new point
      if (action.action_type_id === "c719c0af-c618-4f68-84be-fe1988f06f25") {
        if (currentPoint) {
          timeline.push(currentPoint);
        }
        currentPoint = {
          manche: action.set_number,
          actions: [],
          winningAction: {
            isWinningAction: false,
            sentence: "",
            winningActionId: "",
          },
          score: "",
        };
      }

      if (currentPoint) {
        currentPoint.actions.push(action);
        const winningAction = getWinningAction(action, players);
        currentPoint.winningAction = winningAction;
        if (winningAction.isWinningAction) {
          const scoresPasa = updateScoresAndPasas(
            action,
            players,
            scoreTeam1Set1,
            scoreTeam1Set2,
            scoreTeam1Set3,
            scoreTeam2Set1,
            scoreTeam2Set2,
            scoreTeam2Set3,
            pasaTeam1,
            pasaTeam2,
            detailConsecutivesPasas,
          );
          // Count scores
          scoreTeam1Set1 = scoresPasa.scoreTeam1Set1;
          scoreTeam1Set2 = scoresPasa.scoreTeam1Set2;
          scoreTeam1Set3 = scoresPasa.scoreTeam1Set3;
          scoreTeam2Set1 = scoresPasa.scoreTeam2Set1;
          scoreTeam2Set2 = scoresPasa.scoreTeam2Set2;
          scoreTeam2Set3 = scoresPasa.scoreTeam2Set3;
          // Count Pasa
          pasaTeam1 = scoresPasa.pasaTeam1;
          pasaTeam2 = scoresPasa.pasaTeam2;

          if (action.set_number === 1) {
            currentPoint.score = `${scoreTeam1Set1} - ${scoreTeam2Set1}`;
          } else if (action.set_number === 2) {
            currentPoint.score = `${scoreTeam1Set2} - ${scoreTeam2Set2}`;
          } else if (action.set_number === 3) {
            currentPoint.score = `${scoreTeam1Set3} - ${scoreTeam2Set3}`;
          }
          // Update scorePanel by dispatching each scores and Pasas
          dispatch(setScoreTeam1Set1(scoreTeam1Set1));
          dispatch(setScoreTeam1Set2(scoreTeam1Set2));
          dispatch(setScoreTeam1Set3(scoreTeam1Set3));

          dispatch(setScoreTeam2Set1(scoreTeam2Set1));
          dispatch(setScoreTeam2Set2(scoreTeam2Set2));
          dispatch(setScoreTeam2Set3(scoreTeam2Set3));

          dispatch(setPasaTeam1(pasaTeam1));
          dispatch(setPasaTeam2(pasaTeam2));
        }
      }
    });

    if (currentPoint) {
      timeline.push(currentPoint);
    }

    return setTimeline(timeline.reverse());
  }

  // Must fix timeline when responsive
  const getMancheColumn = () =>
    isResponsive
      ? columnHelper.accessor("manche", {
          cell: () => null,
          header: () => "",
        })
      : columnHelper.accessor("manche", {
          cell: (info) => {
            const reverseIndex = totalRowCount - info.row.index;
            return (
              <div>
                <a>
                  {info.getValue()} - ({reverseIndex})
                </a>
              </div>
            );
          },
          header: () => "Manche",
        });

  const getEchangeNumberColumn = () =>
    isResponsive
      ? columnHelper.accessor("winningAction", {
          cell: () => null,
          header: () => "",
        })
      : columnHelper.accessor("winningAction", {
          cell: (info) => {
            return (
              <div
                data-tooltip-id="winningActionDescription"
                data-tooltip-content={info.getValue().sentence}
              >
                <Tooltip
                  id="winningActionDescription"
                  className="winning_action_description"
                  classNameArrow="winning_action_description_arrow"
                />
                {info.getValue().isWinningAction === true ? (
                  <PiCheckFatDuotone fill="green" />
                ) : (
                  ""
                )}
              </div>
            );
          },
          header: () => "Point",
        });

  const getActionsColumn = () =>
    isResponsive
      ? columnHelper.display({
          id: "actions",
          cell: (info) => (
            <div className="buttons-timeline-responsive">
              {info.row.original.actions.map((action, index) => {
                const colorTeam = getColorTeamFromPlayerId(action.player_id);
                // Must show the action only if action.action_type_id is equal to idActionTypeShot or reception with options negative or service.id
                if (
                  action.action_type_id === idActionTypeShot ||
                  action.action_type_id === service?.id ||
                  (action.action_type_id === reception?.id &&
                    action.label_option_ids.find(
                      (item) =>
                        item.option_id ===
                        automatizationOptions.optionNegativeTypeReceptionResult
                          ?.id,
                    ))
                ) {
                  return (
                    <button
                      onClick={() => {
                        handleEditMode(action);
                        focusOnAction(
                          info.row.index + 1,
                          action,
                          previousActionToFocusOn,
                        );
                      }}
                      style={
                        isEditMode && action.id === actionToEditId
                          ? { backgroundColor: "purple", color: "white" }
                          : actionIdsToCorrect.includes(action.id)
                            ? { backgroundColor: "pink", color: "white" }
                            : action.id === previousAction.action.id
                              ? { backgroundColor: "brown", color: "white" }
                              : colorTeam
                                ? colorTeam === "white" ||
                                  colorTeam === "yellow"
                                  ? {
                                      color: "black",
                                      backgroundColor: `${colorTeam}`,
                                    }
                                  : {
                                      color: "white",
                                      backgroundColor: `${colorTeam}`,
                                    }
                                : { backgroundColor: "grey" }
                      }
                      key={index}
                    >
                      {getPlayerLastNameFromId(action.player_id)}
                    </button>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          ),
          header: () => "Actions",
        })
      : columnHelper.display({
          id: "actions",
          cell: (info) => {
            // Check if there are multiple winning actions into currentPoint and if so, change the style of the buttons
            // Also check if the Winning action of the currentPoint is the lastOne, if not we change the css of the div
            const {
              nbOfWinningActions,
              isLastActionDifferentOfTheWinningAction,
            } = determineNbWinningActions(info.row.original.actions, players);

            const hasTooManyWinningActions = nbOfWinningActions >= 2;
            const isLastActionNotTheWinningAction =
              nbOfWinningActions === 1 &&
              isLastActionDifferentOfTheWinningAction;

            return (
              <div
                className={
                  hasTooManyWinningActions
                    ? "buttons-timeline-winningActionOver2"
                    : isLastActionNotTheWinningAction
                      ? "buttons-timeline-lastActionIsNotWinningAction"
                      : "buttons-timeline"
                }
              >
                {info.row.original.actions.map((action, index) => {
                  const colorTeam = getColorTeamFromPlayerId(action.player_id);

                  // Must show the action only if action.action_type_id is equal to idActionTypeShot or reception with options negative or service.id
                  if (
                    action.action_type_id === idActionTypeShot ||
                    action.action_type_id === service?.id ||
                    (action.action_type_id === reception?.id &&
                      action.label_option_ids.find(
                        (item) =>
                          item.option_id ===
                          automatizationOptions
                            .optionNegativeTypeReceptionResult?.id,
                      ))
                  ) {
                    return (
                      <button
                        onClick={() => {
                          handleEditMode(action);
                          focusOnAction(
                            info.row.index,
                            action,
                            previousActionToFocusOn,
                          );
                        }}
                        style={
                          isEditMode && action.id === actionToEditId
                            ? { backgroundColor: "purple", color: "white" }
                            : actionIdsToCorrect.includes(action.id)
                              ? { backgroundColor: "pink", color: "white" }
                              : action.id === previousAction.action.id
                                ? { backgroundColor: "brown", color: "white" }
                                : colorTeam
                                  ? colorTeam === "white" ||
                                    colorTeam === "yellow"
                                    ? {
                                        color: "black",
                                        backgroundColor: `${colorTeam}`,
                                      }
                                    : {
                                        color: "white",
                                        backgroundColor: `${colorTeam}`,
                                      }
                                  : { backgroundColor: "grey" }
                        }
                        key={index}
                        id={action.id + "-button"}
                      >
                        {getPlayerLastNameFromId(action.player_id)}
                      </button>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            );
          },
          header: () => "Actions",
        });

  const columnHelper = createColumnHelper<TimelinePoint>();
  const columns = [
    getMancheColumn(),
    getActionsColumn(),
    getEchangeNumberColumn(),
    columnHelper.accessor("score", {
      cell: (info) => info.getValue(),
      header: () => "Score",
    }),
  ];

  const table = useReactTable({
    data: timeline,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="tim-div">
      <table className="full-width-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup: any) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header: any, index: number) => (
                <th
                  key={header.id}
                  style={{ width: index === 1 ? "70%" : "10%" }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row: any, rowIndex: number) => (
            <tr
              key={row.id}
              style={
                lastActionTrIndex === rowIndex
                  ? { backgroundColor: "lightskyblue" }
                  : {}
              }
            >
              {row.getVisibleCells().map((cell: any) => (
                <td
                  key={cell.id}
                  style={{ overflowX: "scroll", maxWidth: "485px" }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
