import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { PaginatedModel } from "@user/types/PaginatedModel";
import UsersApi from "@user/api/UsersApi";
import { APIError, APIErrorType } from "@user/types/ApiError";
import { Sport } from "@user/types/Sport";
import useUser from "@user/hooks/useUser";
import usePaginatedSports from "@user/hooks/usePaginatedSports";
import { cacheKey } from "@/utils/constants";

function usePageAdminUserSports({
  userId,
  userSportsPageSize = 10,
  sportsPageSize = 10,
}: {
  userId?: string;
  userSportsPageSize: number;
  sportsPageSize: number;
}): {
  paginatedUserSports: PaginatedModel<Sport> | undefined;
  userSportsPage: number;
  userSportsPageSize: number;
  setUserSportsPage: (page: number) => void;
  addSportToUser: (sportId: string) => void;
  removeSportFromUser: (sportId: string) => void;
  paginatedSports: PaginatedModel<Sport> | undefined;
  sportsPage: number;
  sportsPageSize: number;
  setSportPage: (page: number) => void;
  isLoading: boolean;
  // deleteUser: (id: string) => void;
} {
  const { t } = useTranslation();

  // Handle pagination for user sports & sports
  const [userSportsPage, setUserSportsPage] = useState(1);
  const [sportsPage, setSportsPage] = useState(1);

  // Get the paginated user sports
  const { sports: paginatedUserSports, isLoading: isUserSportsLoading } =
    useUser(userId, {
      withSports: true,
      sportsPage: userSportsPage,
      sportsPageSize: userSportsPageSize,
    });

  // Get the paginated sports
  const { paginatedSports, isLoading: isSportsLoading } = usePaginatedSports({
    page: sportsPage,
    pageSize: sportsPageSize,
  });

  // Add a sport to the user
  const queryClientAdd = useQueryClient();
  const { mutate: addSportToUserInApi } = useMutation({
    mutationFn: (sportId: string) => {
      if (!userId) return new Promise(() => {});
      return UsersApi.addSport(userId, sportId);
    },
    onSuccess: () => {
      queryClientAdd.invalidateQueries({
        queryKey: [cacheKey.indexUserSports, userId],
      });

      toast.success(t("admin.user.sport-assignation-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data as APIError;
      if (errorDetails.error_type === APIErrorType.ALREADY_EXISTS) {
        toast.error(t("admin.user.sport-already-assigned"), {
          autoClose: 3000,
        });
        return;
      }

      console.error(error);
      toast.error(t("admin.user.sport-assignation-failed"), {
        autoClose: 3000,
      });
    },
  });
  function addSportToUser(sportId: string) {
    addSportToUserInApi(sportId);
  }

  // Remove a sport from the user
  const queryClientRemove = useQueryClient();
  const { mutate: removeSportFromUserInApi } = useMutation({
    mutationFn: (sportId: string) => {
      if (!userId) return new Promise(() => {});
      return UsersApi.removeSport(userId, sportId);
    },
    onSuccess: () => {
      queryClientRemove.invalidateQueries({
        queryKey: [cacheKey.indexUserSports, userId],
      });

      toast.success(t("admin.user.sport-removal-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.sport-removal-failed"), {
        autoClose: 3000,
      });
    },
  });
  function removeSportFromUser(sportId: string) {
    removeSportFromUserInApi(sportId);
  }

  // Build the global isLoading flag
  const isLoading = isUserSportsLoading || isSportsLoading;

  return {
    paginatedUserSports,
    userSportsPage,
    userSportsPageSize,
    setUserSportsPage,
    addSportToUser,
    removeSportFromUser,
    paginatedSports,
    sportsPage,
    sportsPageSize,
    setSportPage: setSportsPage,
    isLoading,
  };
}

export default usePageAdminUserSports;
