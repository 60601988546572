import axios from "axios";

import msalManager from "@/auth/msalManager";
import { ApiRoute, HttpMethod } from "@user/types/ApiRoute";
import { PaginatedModel } from "@user/types/PaginatedModel";

export interface CreateApiRouteInputs {
  domainId: string; // UUID v4
  method: HttpMethod;
  path: string;
  description?: string;
}

export interface UpdateApiRouteInputs {
  domainId?: string; // UUID v4
  method?: HttpMethod;
  path?: string;
  description?: string;
}

export interface IndexApiRouteOptions {
  domainId?: string; // UUID v4
  method?: HttpMethod;
  path?: string;
  page?: number;
  pageSize?: number;
}

export default class ApiRoutesApi {
  static domain: string = "user";
  static ressource: string = "routes";

  static async create(
    createApiRouteInputs: CreateApiRouteInputs,
  ): Promise<ApiRoute> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.ressource}`;

    const data = {
      domain_id: createApiRouteInputs.domainId,
      method: createApiRouteInputs.method,
      path: createApiRouteInputs.path,
      description: createApiRouteInputs.description,
    };

    const response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    });

    return response.data;
  }

  static async show(id: string): Promise<ApiRoute> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.ressource}/${id}`;

    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async index(
    options: IndexApiRouteOptions = {},
  ): Promise<PaginatedModel<ApiRoute>> {
    const { page = 1, pageSize = 10, domainId, method, path } = options;

    const accessToken = await msalManager.getAccessToken();

    // Build base URL
    const url = new URL(
      `${import.meta.env.VITE_API_URL}/${this.domain}/${this.ressource}`,
    );

    // Add query params
    const queryParams = new URLSearchParams();
    if (page) queryParams.append("page", page.toString());
    if (pageSize) queryParams.append("page_size", pageSize.toString());
    if (domainId) queryParams.append("domain_id", domainId);
    if (method) queryParams.append("method", method);
    if (path) queryParams.append("path", path);
    if (queryParams.toString()) {
      url.search = queryParams.toString();
    }

    const response = await axios(url.toString(), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async getAll(): Promise<ApiRoute[]> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.ressource}/all`;

    const response = await axios(url.toString(), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }

  static async update(
    id: string,
    updateApiRouteInputs: UpdateApiRouteInputs,
  ): Promise<ApiRoute> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.ressource}/${id}`;

    const data = {
      domain_id: updateApiRouteInputs.domainId,
      method: updateApiRouteInputs.method,
      path: updateApiRouteInputs.path,
      description: updateApiRouteInputs.description,
    };

    const response = await axios(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    });

    return response.data;
  }

  static async delete(id: string): Promise<ApiRoute> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.ressource}/${id}`;

    const response = await axios(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }
}
