import { Game } from "@/domains/rugby/types/Game";
import { Organization } from "./Organization";
import { User } from "./User";

export enum TokenOperationType {
  REFILL = "REFILL",
  BUY_RUGBY_GAME = "BUY_RUGBY_GAME",
  BUY_RUGBY_PLAYER = "BUY_RUGBY_PLAYER",
  BUY_RUGBY_TEAM = "BUY_RUGBY_TEAM",
}

export interface TokenOperations {
  id: string; // UUID v4
  organization: Organization;
  user: User;
  operation_date: string;
  operation_type: TokenOperationType;
  entity_id: string;
  token_variation: number;
  game_rugby: Game;
  created_at: string;
  created_by?: string; // UUID v4
  updated_at: string;
  updated_by?: string; // UUID v4
}
