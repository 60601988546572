import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminRolePermissions from "./usePageAdminRolePermissions";
import Pagination from "@/ui-lib/pagination/Pagination";
import FormButton from "@/ui-lib/form-button/FormButton";
import { Permission } from "@user/types/Permission";

import "./pageAdminRolePermissions.scss";

const PageAdminRolePermissions = () => {
  const { t } = useTranslation();

  const {
    paginatedRolePermissions,
    rolePermissionsPage,
    rolePermissionsPageSize,
    setRolePermissionsPage,
    addPermissionToRole,
    removePermissionFromRole,
    paginatedPermissions,
    permissionsPage,
    permissionsPageSize,
    setPermissionsPage,
    isLoading,
  } = usePageAdminRolePermissions();

  // Role permissions table initialization
  const columlHelperRolePermissions = createColumnHelper<Permission>();
  const columnsRolePermissions = [
    columlHelperRolePermissions.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.domain"),
    }),
    columlHelperRolePermissions.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.name"),
    }),
    columlHelperRolePermissions.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.description"),
    }),
    columlHelperRolePermissions.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => removePermissionFromRole(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  // All permissions table initialization
  const columlHelperAllPermissions = createColumnHelper<Permission>();
  const columnsAllPermissions = [
    columlHelperAllPermissions.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.domain"),
    }),
    columlHelperAllPermissions.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.name"),
    }),
    columlHelperAllPermissions.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.description"),
    }),
    columlHelperAllPermissions.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.add")}
            onClick={() => addPermissionToRole(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-role-permissions">
      <div className="title-role-permissions">
        {t("admin.role.role-permissions")}
      </div>
      <div className="table-role-permissions">
        <Table
          columns={columnsRolePermissions}
          data={paginatedRolePermissions?.items ?? []}
          hasGlobalFilter={true}
        />
        <div className="pagination">
          <Pagination
            totalItems={paginatedRolePermissions?.pagination.total_items ?? 0}
            itemsPerPage={rolePermissionsPageSize}
            currentPage={rolePermissionsPage}
            onClick={setRolePermissionsPage}
          />
        </div>
      </div>
      <div className="title-all-permissions">
        {t("admin.role.available-permissions")}
      </div>
      <div className="table-all-permissions">
        <Table
          columns={columnsAllPermissions}
          data={paginatedPermissions?.items ?? []}
          hasGlobalFilter={true}
        />
        <div className="pagination">
          <Pagination
            totalItems={paginatedPermissions?.pagination.total_items ?? 0}
            itemsPerPage={permissionsPageSize}
            currentPage={permissionsPage}
            onClick={setPermissionsPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PageAdminRolePermissions;
