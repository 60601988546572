import { Outlet, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import { AdminTabs, AdminTabsProps } from "@/ui-lib/admin-tabs/AdminTabs";
import useOrganization from "@user/hooks/useOrganization";
import useNavigateInApp from "@/hooks/useNavigateInApp";

const LayoutAdminOrganizationDetails = () => {
  const { id: organizationId } = useParams();
  const { navigateTo } = useNavigateInApp();
  const { t } = useTranslation();

  const { organization } = useOrganization(organizationId, {
    skip: !organizationId,
  });

  const handleButtonClick = (path: string) => {
    navigateTo(path);
  };

  const adminOrganizationtabs: AdminTabsProps = {
    buttons: [
      {
        label: t("admin.organization.general"),
        onClick: () =>
          handleButtonClick(`/admin/user/organizations/${organization?.id}`),
        isActive:
          !location.pathname.includes("permissions") &&
          !location.pathname.includes("roles") &&
          !location.pathname.includes("users") &&
          !location.pathname.includes("api-accesses") &&
          !location.pathname.includes("competitions") &&
          !location.pathname.includes("tokens"),
      },
      {
        label: t("admin.organization.permissions"),
        onClick: () =>
          handleButtonClick(
            `/admin/user/organizations/${organization?.id}/permissions`,
          ),
        isActive: location.pathname.includes("permissions"),
      },
      {
        label: t("admin.organization.roles"),
        onClick: () =>
          handleButtonClick(
            `/admin/user/organizations/${organization?.id}/roles`,
          ),
        isActive: location.pathname.includes("roles"),
      },
      {
        label: t("admin.organization.users"),
        onClick: () =>
          handleButtonClick(
            `/admin/user/organizations/${organization?.id}/users`,
          ),
        isActive: location.pathname.includes("users"),
      },
      {
        label: t("admin.organization.api-accesses"),
        onClick: () =>
          handleButtonClick(
            `/admin/user/organizations/${organization?.id}/api-accesses`,
          ),
        isActive: location.pathname.includes("api-accesses"),
      },
      {
        label: t("admin.organization.competitions"),
        onClick: () =>
          handleButtonClick(
            `/admin/user/organizations/${organization?.id}/competitions`,
          ),
        isActive: location.pathname.includes("competitions"),
      },
      {
        label: t("admin.organization-tokens.tokens"),
        onClick: () =>
          handleButtonClick(
            `/admin/user/organizations/${organization?.id}/tokens`,
          ),
        isActive: location.pathname.includes("tokens"),
      },
    ],
  };

  return (
    <div>
      <AdminHeader
        text={`${organization?.id ? t("admin.organization.organization") : t("organizations")} "${organization?.name}"`}
      />
      <AdminTabs buttons={adminOrganizationtabs.buttons} />
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutAdminOrganizationDetails;
