import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type EditModeType = {
  isEditMode: boolean;
};

const initialState: EditModeType = {
  isEditMode: false,
};

export const editModeReducer = createSlice({
  name: "editModeReducer",
  initialState,
  reducers: {
    setIsEditMode: (
      state: EditModeType,
      action: PayloadAction<{ isEditMode: boolean }>,
    ) => {
      state.isEditMode = action.payload.isEditMode;
    },
  },
});

export const { setIsEditMode } = editModeReducer.actions;
export default editModeReducer.reducer;
