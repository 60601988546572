import axios from "axios";

import msalManager from "@/auth/msalManager";
import { OrganizationCompetitionRugby } from "@user/types/OrganizationCompetitionRugby";

export interface CreateOrganizationCompetitions {
  organization_id: string;
  competition_id: number;
  has_full_access?: boolean;
  has_dynamic_report_access?: boolean;
  has_no_access?: boolean;
}
export interface UpdateOrganizationCompetitions
  extends CreateOrganizationCompetitions {
  id: string;
}

export default class OrganizationCompetitions {
  static domain: string = "user";
  static resource: string = "organization-competitions-rugby";

  static async create(
    createOrganizationCompetition: CreateOrganizationCompetitions,
  ): Promise<OrganizationCompetitionRugby> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}`;
    const data = {
      competition_id: createOrganizationCompetition.competition_id,
      organization_id: createOrganizationCompetition.organization_id,
      has_full_access: createOrganizationCompetition.has_full_access,
      has_dynamic_report_access:
        createOrganizationCompetition.has_dynamic_report_access,
      has_no_access: createOrganizationCompetition.has_no_access,
    };
    const response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    });

    return response.data;
  }

  static async update(
    id: string,
    updateOrganizationCompetition: UpdateOrganizationCompetitions,
  ): Promise<OrganizationCompetitionRugby> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.resource}/${id}`;

    const response = await axios(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: updateOrganizationCompetition,
    });

    return response.data;
  }
}
