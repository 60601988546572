import { useContext } from "react";

import userContext from "@/contexts/userContext";
import { Playground } from "@pelote/types/Playground";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import usePaginatedPlaygrounds from "@pelote/hooks/usePaginatedPlaygrounds";

function usePageAdminPlaygroundList({
  page,
  pageSize,
}: {
  page: number;
  pageSize: number;
}): {
  sportPath: string | undefined;
  paginatedPlaygrounds: PaginatedModel<Playground> | undefined;
  isLoading: boolean;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Get the paginated playgrounds
  const { paginatedPlaygrounds, isLoading: isPlaygroundsLoading } =
    usePaginatedPlaygrounds({
      page,
      pageSize,
    });

  // Build the global isLoading flag
  const isLoading = isUserLoading || isPlaygroundsLoading;

  return {
    sportPath,
    paginatedPlaygrounds: paginatedPlaygrounds,
    isLoading,
  };
}

export default usePageAdminPlaygroundList;
