import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ProgressBar from "@ramonak/react-progress-bar";

import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import usePageAdminGameCreateVideo from "./usePageAdminGameCreateVideo";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./pageAdminGameCreateVideo.scss";

export interface CreateVideoFormInputs {
  name: string;
  videoFiles?: FileList;
}

const PageAdminGameCreateVideo = () => {
  const {
    sportPath,
    handleCreateVideo,
    isCreationSuccess,
    isLoading,
    uploadStatus,
    isUploadSuccess,
  } = usePageAdminGameCreateVideo();

  const { id } = useParams();
  const { t } = useTranslation();

  // Navigate to the list of videos when the creation is successful
  const navigate = useNavigate();
  useEffect(() => {
    if (isUploadSuccess && isCreationSuccess && !isLoading)
      navigate(`/admin/pelote/${sportPath}/games/${id}/videos`);
  }, [isCreationSuccess, isLoading, isUploadSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateVideoFormInputs>({
    defaultValues: {
      name: "",
      videoFiles: undefined,
    },
  });

  return (
    <div id="page-admin-game-video-create">
      <form id="video-create" onSubmit={handleSubmit(handleCreateVideo)}>
        <div className="form-inputs">
          <div className="input-name">
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />

            <div className="upload-message">
              <span className="message">
                {t("admin.game.videoUploadMessage")}
              </span>
              <span className="required-star"> *</span>
            </div>
            <input
              type="file"
              id="video"
              accept="video/*"
              {...register("videoFiles")}
            />
          </div>
        </div>
        <div className="form-buttons">
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() =>
              navigate(`/admin/pelote/cesta-punta/games/${id}/videos`)
            }
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
      {/* Modal which takes the whole screen and shows the progress of the upload */}
      {uploadStatus?.status === "in_progress" && (
        <div className="modal">
          <div className="modal-content">
            <ProgressBar
              completed={uploadStatus.progressInt}
              bgColor="#3b82f6"
              height="20px"
              isLabelVisible={true}
              labelAlignment="center"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdminGameCreateVideo;
