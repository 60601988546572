import ReactSelect from "react-select";

import "./select.scss";

export type SelectOption = {
  value: string | number;
  label: string;
};

/** Generic select component: a wrapper around react-select, with a few customizations
 */
const Select = ({
  options,
  selectedOptionIndex,
  onChange,
  className,
}: {
  options: SelectOption[];
  selectedOptionIndex: number;
  onChange: (option: SelectOption) => void;
  className?: string;
}) => {
  if (options.length === 0) {
    console.error("No options provided to Select component");
    return null;
  }

  if (selectedOptionIndex < 0 || selectedOptionIndex >= options.length) {
    console.error(`selectedOptionIndex out of bounds: ${selectedOptionIndex}`);
    return null;
  }

  return (
    <ReactSelect
      className={`${className} select-component`}
      options={options}
      value={options[selectedOptionIndex]}
      onChange={(option) => onChange(option as SelectOption)}
    />
  );
};

export default Select;
