import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import RoundsApi, { CreateRoundInputs } from "@pelote/api/RoundsApi";
import { APIError } from "@pelote/types/ApiError";
import usePaginatedSeasons from "@pelote/hooks/usePaginatedSeasons";
import { SelectOption } from "@/ui-lib/select/Select";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";
import usePaginatedPools from "@pelote/hooks/usePaginatedPools";
import usePaginatedStages from "@pelote/hooks/usePaginatedStages";

function usePageAdminRoundCreate(): {
  sportPath: string | undefined;
  handleCreateRound: (createRoundInputs: CreateRoundInputs) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
  seasonSelectOptions: SelectOption[];
  competitionSelectOptions: SelectOption[];
  stageSelectOptions: SelectOption[];
  poolSelectOptions: SelectOption[];
  handleChangeSeasonOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
  handleChangeCompetitionOption: (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => void;
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  const [selectedSeasonOption, setSelectedSeasonOption] =
    useState<SelectOption | null>(null);
  const [selectedCompetitionOption, setSelectedCompetitionOption] =
    useState<SelectOption | null>(null);

  // Fetch a list of stages
  const { paginatedStages } = usePaginatedStages({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || selectedCompetitionOption === null,
  });

  // Fetch a list of pools
  const { paginatedPools } = usePaginatedPools({
    seasonId: selectedSeasonOption?.value as string,
    competitionId: selectedCompetitionOption?.value as string,
    page: 1,
    pageSize: 10,
    skip: selectedSeasonOption === null || selectedCompetitionOption === null,
  });

  // Get the paginated seasons
  const { paginatedSeasons, isLoading: isSeasonsLoading } = usePaginatedSeasons(
    {
      page: 1,
      pageSize: 10,
      sortBy: "name:desc",
      sportId: currentSport?.id,
      skip: currentSport === undefined,
    },
  );

  // Convert the seasons to select options
  const seasonSelectOptions = convertItemsToSelectOptions(
    paginatedSeasons?.items || [],
    "id",
    "name",
  );

  // Get the paginated competitions
  const { paginatedCompetitions, isLoading: isCompetitionsLoading } =
    usePaginatedCompetitions({
      page: 1,
      pageSize: 10,
      sportId: currentSport?.id,
      skip: currentSport === undefined,
    });

  // Convert the competitions to select options
  const competitionSelectOptions = convertItemsToSelectOptions(
    paginatedCompetitions?.items || [],
    "id",
    "name",
  );
  competitionSelectOptions.unshift({ label: "------", value: "" });

  // Convert the stages to select options
  const stageSelectOptions = convertItemsToSelectOptions(
    paginatedStages?.items || [],
    "id",
    "name",
  );
  stageSelectOptions.unshift({ label: "------", value: "" });

  // Convert the pools to select options
  const poolSelectOptions = convertItemsToSelectOptions(
    paginatedPools?.items || [],
    "id",
    "name",
  );
  poolSelectOptions.unshift({ label: "------", value: "" });

  // Handle the creation of a round
  const queryClient = useQueryClient();
  const {
    mutate: createRound,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newRound: CreateRoundInputs) => RoundsApi.create(newRound),
    onSuccess: (variables) => {
      queryClient.invalidateQueries({
        queryKey: [
          "indexRounds",
          variables.season_id,
          variables.competition_id,
        ],
      });
      toast.success(t("admin.round.created"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });
  function handleCreateRound(createRoundInputs: CreateRoundInputs) {
    if (currentSport === undefined) return;
    if (createRoundInputs.pool_id === "") delete createRoundInputs.pool_id;
    if (createRoundInputs.stage_id === "") delete createRoundInputs.stage_id;
    createRound(createRoundInputs);
  }

  const handleChangeSeasonOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedSeasonOption(
      seasonSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };
  const handleChangeCompetitionOption = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setSelectedCompetitionOption(
      competitionSelectOptions.find(
        (option) => option.value === event.target.value,
      ) || null,
    );
  };

  // Build the global isLoading flag
  const isLoading = isUserLoading || isCompetitionsLoading || isSeasonsLoading;

  useEffect(() => {
    const initialSeasonOption = seasonSelectOptions[0] || null;
    setSelectedSeasonOption(initialSeasonOption);
    const initialCompetitionOption = competitionSelectOptions[0] || null;
    setSelectedCompetitionOption(initialCompetitionOption);
  }, [isLoading]);

  return {
    sportPath,
    handleCreateRound,
    isLoading,
    isCreationPending,
    isCreationSuccess,
    seasonSelectOptions,
    competitionSelectOptions,
    stageSelectOptions,
    poolSelectOptions,
    handleChangeSeasonOption,
    handleChangeCompetitionOption,
  };
}

export default usePageAdminRoundCreate;
