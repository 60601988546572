import { useQuery } from "@tanstack/react-query";

import PlayersApi from "@pelote/api/PlayersApi";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { Game } from "../types/Game";

interface usePlayerGamesOptions {
  seasonId?: string;
  competitionId?: string;
  playerId: string;
  page?: number;
  pageSize?: number;
  skip?: boolean;
}

function usePaginatedPlayerGames(
  options: Partial<usePlayerGamesOptions> = {},
): {
  paginatedPlayerGames: PaginatedModel<Game> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const {
    seasonId,
    competitionId,
    playerId,
    page = 1,
    pageSize = 10,
    skip,
  } = options;
  // Fetch a list of players based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: ["indexGames", seasonId, competitionId, playerId, page, pageSize],
    queryFn: () =>
      PlayersApi.indexGames(
        playerId as string,
        competitionId,
        seasonId,
        pageSize,
      ),
    enabled: !skip,
  });

  return { paginatedPlayerGames: data, isLoading, isError };
}

export default usePaginatedPlayerGames;
