import { useTranslation } from "react-i18next";

// Graphs
import ScatterChartAreas from "@/ui-lib/core/scatterChart/ScatterChart";
import PieChartGraph from "@/ui-lib/core/pieChart/PieChart";

// CSS
import "@pelote/pages/cesta-punta/gamePdf/PageGamePdf.scss";

// Models
import { MetricsGamePlayer } from "@pelote/types/MetricsGamePlayer";
import {
  MetricsGameActionsList,
  ServiceForActionsList,
  ReceptionForActionsList,
  ShotForActionsList,
} from "@pelote/types/MetricsGameActionsList";
import { Game } from "@pelote/types/Game";
import { Playground } from "@pelote/types/Playground";

// Header & Footer
import GamePdfFooter from "@pelote/components/GamePdf/GamePdfFooter";
import { roundDecimal } from "@/utils/roundDecimal";

const GamePdfPlayer = ({
  type,
  game,
  playground,
  metricsGamePlayer,
  metricsGameActionsListData,
  pageNumber,
}: {
  type: "front" | "back";
  game: Game | undefined;
  playground: Playground | undefined;
  metricsGamePlayer: MetricsGamePlayer | undefined;
  metricsGameActionsListData: MetricsGameActionsList | undefined;
  pageNumber: number;
}) => {
  const { t } = useTranslation();

  if (!game || !metricsGamePlayer || !metricsGameActionsListData) {
    return null;
  }

  // Get players infos
  const playerFrontTeam1 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  const playerFrontTeam2 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  const playerBackTeam1 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  const playerBackTeam2 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  game.players.forEach((player) => {
    if (player.favorite_position === "front" && player.team_number === 1) {
      playerFrontTeam1.firstname = player.firstname;
      playerFrontTeam1.lastname = player.lastname;
      playerFrontTeam1.photo_url = player.photo_url;
    }
    if (player.favorite_position === "front" && player.team_number === 2) {
      playerFrontTeam2.firstname = player.firstname;
      playerFrontTeam2.lastname = player.lastname;
      playerFrontTeam2.photo_url = player.photo_url;
    }
    if (player.favorite_position === "back" && player.team_number === 1) {
      playerBackTeam1.firstname = player.firstname;
      playerBackTeam1.lastname = player.lastname;
      playerBackTeam1.photo_url = player.photo_url;
    }
    if (player.favorite_position === "back" && player.team_number === 2) {
      playerBackTeam2.firstname = player.firstname;
      playerBackTeam2.lastname = player.lastname;
      playerBackTeam2.photo_url = player.photo_url;
    }
  });

  // Get Teams names
  const team1Name =
    playerFrontTeam1.firstname.charAt(0) +
    "." +
    playerFrontTeam1.lastname.toUpperCase() +
    " / " +
    playerBackTeam1.firstname.charAt(0) +
    "." +
    playerBackTeam1.lastname.toUpperCase();
  const team2Name =
    playerFrontTeam2.firstname.charAt(0) +
    "." +
    playerFrontTeam2.lastname.toUpperCase() +
    " / " +
    playerBackTeam2.firstname.charAt(0) +
    "." +
    playerBackTeam2.lastname.toUpperCase();

  // Get report title
  const reportTitle: string = `Rapport Partie ${team1Name} vs ${team2Name}`;

  const serviceList: ServiceForActionsList[] =
    metricsGameActionsListData.actions.filter(
      (action): action is ServiceForActionsList =>
        action.player_id === metricsGamePlayer.player_id &&
        action.type === "service",
    );
  const receptionsList: ReceptionForActionsList[] =
    metricsGameActionsListData.actions.filter(
      (action): action is ReceptionForActionsList =>
        action.player_id === metricsGamePlayer.player_id &&
        action.type === "reception",
    );
  const shotsList: ShotForActionsList[] =
    metricsGameActionsListData.actions.filter(
      (action): action is ShotForActionsList =>
        action.player_id === metricsGamePlayer.player_id &&
        action.type === "shot",
    );
  const serviceListTeam: ServiceForActionsList[] =
    metricsGameActionsListData.actions.filter(
      (action): action is ServiceForActionsList =>
        action.team_number === metricsGamePlayer.team_number &&
        action.type === "service",
    );
  const receptionsListTeam: ReceptionForActionsList[] =
    metricsGameActionsListData.actions.filter(
      (action): action is ReceptionForActionsList =>
        action.team_number === metricsGamePlayer.team_number &&
        action.type === "reception",
    );
  const shotsListTeam: ShotForActionsList[] =
    metricsGameActionsListData.actions.filter(
      (action): action is ShotForActionsList =>
        action.team_number === metricsGamePlayer.team_number &&
        action.type === "shot",
    );

  const serviceTypeMetrics = [
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.back"),
      total: metricsGamePlayer.services.type_service.back.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.services.type_service.back.total) /
          metricsGamePlayer.services.total,
      ),
      color: "green",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.front"),
      total: metricsGamePlayer.services.type_service.front.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.services.type_service.front.total) /
          metricsGamePlayer.services.total,
      ),
      color: "blue",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.ace"),
      total: metricsGamePlayer.services.type_service.ace.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.services.type_service.ace.total) /
          metricsGamePlayer.services.total,
      ),
      colr: "purple",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.pasa"),
      total: metricsGamePlayer.services.type_service.pasa.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.services.type_service.pasa.total) /
          metricsGamePlayer.services.total,
      ),
      color: "orange",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.falta"),
      total: metricsGamePlayer.services.type_service.falta.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.services.type_service.falta.total) /
          metricsGamePlayer.services.total,
      ),
      color: "red",
    },
  ];

  const receptionTypeMetrics = [
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.aire"),
      total: metricsGamePlayer.receptions.type_reception.aire.total,
      positives:
        metricsGamePlayer.receptions.result_reception.positive.type_reception
          .aire.total,
      negatives:
        metricsGamePlayer.receptions.result_reception.negative.type_reception
          .aire.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.receptions.type_reception.aire.total) /
          metricsGamePlayer.receptions.total,
      ),
      color: "green",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.bote"),
      total: metricsGamePlayer.receptions.type_reception.bote.total,
      positives:
        metricsGamePlayer.receptions.result_reception.positive.type_reception
          .bote.total,
      negatives:
        metricsGamePlayer.receptions.result_reception.negative.type_reception
          .bote.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.receptions.type_reception.bote.total) /
          metricsGamePlayer.receptions.total,
      ),
      color: "blue",
    },
    {
      name: t(
        "codagePelote.actionsPanel.actionTypeDetails.option.bote_corrido",
      ),
      total: metricsGamePlayer.receptions.type_reception.bote_corrido.total,
      positives:
        metricsGamePlayer.receptions.result_reception.positive.type_reception
          .bote_corrido.total,
      negatives:
        metricsGamePlayer.receptions.result_reception.negative.type_reception
          .bote_corrido.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.receptions.type_reception.bote_corrido.total) /
          metricsGamePlayer.receptions.total,
      ),
      color: "purple",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.bote_pronto"),
      total: metricsGamePlayer.receptions.type_reception.bote_pronto.total,
      positives:
        metricsGamePlayer.receptions.result_reception.positive.type_reception
          .bote_pronto.total,
      negatives:
        metricsGamePlayer.receptions.result_reception.negative.type_reception
          .bote_pronto.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.receptions.type_reception.bote_pronto.total) /
          metricsGamePlayer.receptions.total,
      ),
      color: "orange",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.rebote"),
      total: metricsGamePlayer.receptions.type_reception.rebote.total,
      positives:
        metricsGamePlayer.receptions.result_reception.positive.type_reception
          .rebote.total,
      negatives:
        metricsGamePlayer.receptions.result_reception.negative.type_reception
          .rebote.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.receptions.type_reception.rebote.total) /
          metricsGamePlayer.receptions.total,
      ),
      color: "red",
    },
  ];

  const shotTypeMetrics = [
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.classic"),
      total: metricsGamePlayer.shots.type_shot.classic.total,
      positives:
        metricsGamePlayer.shots.result_shot.positive.type_shot.classic.total,
      negatives:
        metricsGamePlayer.shots.result_shot.negative.type_shot.classic.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.shots.type_shot.classic.total) /
          metricsGamePlayer.shots.total,
      ),
      color: "green",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.attack"),
      total: metricsGamePlayer.shots.type_shot.attack.total,
      positives:
        metricsGamePlayer.shots.result_shot.positive.type_shot.attack.total,
      negatives:
        metricsGamePlayer.shots.result_shot.negative.type_shot.attack.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.shots.type_shot.attack.total) /
          metricsGamePlayer.shots.total,
      ),
      color: "blue",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.picada"),
      total: metricsGamePlayer.shots.type_shot.picada.total,
      positives:
        metricsGamePlayer.shots.result_shot.positive.type_shot.picada.total,
      negatives:
        metricsGamePlayer.shots.result_shot.negative.type_shot.picada.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.shots.type_shot.picada.total) /
          metricsGamePlayer.shots.total,
      ),
      color: "purple",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.dejada"),
      total: metricsGamePlayer.shots.type_shot.dejada.total,
      positives:
        metricsGamePlayer.shots.result_shot.positive.type_shot.dejada.total,
      negatives:
        metricsGamePlayer.shots.result_shot.negative.type_shot.dejada.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.shots.type_shot.dejada.total) /
          metricsGamePlayer.shots.total,
      ),
      color: "orange",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.txik_txak"),
      total: metricsGamePlayer.shots.type_shot.txik_txak.total,
      positives:
        metricsGamePlayer.shots.result_shot.positive.type_shot.txik_txak.total,
      negatives:
        metricsGamePlayer.shots.result_shot.negative.type_shot.txik_txak.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.shots.type_shot.txik_txak.total) /
          metricsGamePlayer.shots.total,
      ),
      color: "red",
    },
    {
      name: t("codagePelote.actionsPanel.actionTypeDetails.option.txula"),
      total: metricsGamePlayer.shots.type_shot.txula.total,
      positives:
        metricsGamePlayer.shots.result_shot.positive.type_shot.txula.total,
      negatives:
        metricsGamePlayer.shots.result_shot.negative.type_shot.txula.total,
      percentage: roundDecimal(
        (100 * metricsGamePlayer.shots.type_shot.txula.total) /
          metricsGamePlayer.shots.total,
      ),
      color: "grey",
    },
  ];

  // Balls played
  const countBallsPlayed = serviceList.length + receptionsList.length;
  const countBallsPlayedTeam =
    serviceListTeam.length + receptionsListTeam.length;

  // Actions leading to a Point
  const actionsLeadingToPointsWon =
    shotsList.filter(
      (shot) =>
        shot.result_shot === "positive" ||
        shot.opponent_result_reception === "negative",
    ).length +
    serviceList.filter(
      (service) =>
        service.type_service === "ace" ||
        service.opponent_result_reception === "negative",
    ).length;

  const actionsLeadingToPointsWonTeam =
    shotsListTeam.filter(
      (shot) =>
        shot.result_shot === "positive" ||
        shot.opponent_result_reception === "negative",
    ).length +
    serviceListTeam.filter(
      (service) =>
        service.type_service === "ace" ||
        service.opponent_result_reception === "negative",
    ).length;

  // Points lost due to pasa
  // Value is 0 if 5 or less pasa, 1 for each pasa above 5
  const PointsLostPasa =
    serviceList.filter((service) => service.type_service === "pasa").length > 5
      ? serviceList.filter((service) => service.type_service === "pasa")
          .length - 5
      : 0;

  const PointsLostPasaTeam =
    serviceListTeam.filter((service) => service.type_service === "pasa")
      .length > 5
      ? serviceListTeam.filter((service) => service.type_service === "pasa")
          .length - 5
      : 0;

  // Action leading to a point lost
  const actionsLeadingToPointsLost =
    serviceList.filter((service) => service.type_service === "falta").length +
    PointsLostPasa +
    receptionsList.filter(
      (reception) => reception.result_reception === "negative",
    ).length +
    shotsList.filter((shot) => shot.result_shot === "negative").length;

  const actionsLeadingToPointsLostTeam =
    serviceListTeam.filter((service) => service.type_service === "falta")
      .length +
    PointsLostPasaTeam +
    receptionsListTeam.filter(
      (reception) => reception.result_reception === "negative",
    ).length +
    shotsListTeam.filter((shot) => shot.result_shot === "negative").length;

  return (
    <div className="page">
      {/* If the player is Front */}
      {type === "front" && (
        <div
          className="body"
          style={{
            display: "grid",
            height: "287mm",
            gridTemplateRows: "2% 18% 2% 18% 2% 28% 2% 28%",
          }}
        >
          <div
            style={{
              display: "grid",
              gridRow: "2 / 2",
              gridTemplateColumns: "2% 96% 2%",
            }}
          >
            <div
              className="box"
              style={{
                gridColumn: "2 / 2",
                display: "grid",
                gridTemplateColumns: "1% 35% 1% 26% 1% 35% 1%",
              }}
            >
              <div
                style={{
                  gridColumn: "2 / 2",
                  display: "grid",
                  gridTemplateRows: "10% 50% 40%",
                  height: "195px",
                }}
              >
                <div style={{ gridRow: "1 / 1" }}></div>
                <div style={{ gridRow: "2 / 2" }}>
                  <p
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {metricsGamePlayer.lastname}
                  </p>
                  <p style={{ fontSize: "30px" }}>
                    {metricsGamePlayer.firstname}
                  </p>
                </div>
                <div style={{ gridRow: "3 / 3" }}>
                  <p style={{ fontSize: "30px" }}>
                    {t(metricsGamePlayer.position)}
                  </p>
                </div>
              </div>
              <div style={{ gridColumn: "4 / 4", height: "195px" }}>
                <img
                  src={metricsGamePlayer.photo_url}
                  alt={metricsGamePlayer.firstname}
                  style={{ width: "auto", height: "100%" }}
                />
              </div>
              <div
                style={{
                  gridColumn: "6 / 6",
                  height: "195px",
                  display: "grid",
                  gridTemplateRows: "33% 33% 33%",
                }}
              >
                <div style={{ gridRow: "1 / 1" }}>
                  <p style={{ fontSize: "20px" }}>Pelotes jouées :</p>
                  <p style={{ fontSize: "25px" }}>
                    {" "}
                    {countBallsPlayed} / {countBallsPlayedTeam}{" "}
                  </p>
                </div>
                <div style={{ gridRow: "2 / 2" }}>
                  <p style={{ fontSize: "20px" }}>Points gagnés : </p>
                  <p style={{ fontSize: "25px" }}>
                    {actionsLeadingToPointsWon} /{" "}
                    {actionsLeadingToPointsWonTeam}
                  </p>
                </div>
                <div style={{ gridRow: "3 / 3" }}>
                  <p style={{ fontSize: "20px" }}>Points perdus : </p>
                  <p style={{ fontSize: "25px" }}>
                    {actionsLeadingToPointsLost} /{" "}
                    {actionsLeadingToPointsLostTeam}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridRow: "4 / 4",
              gridTemplateColumns: "2% 96% 2%",
            }}
          >
            <div
              className="box"
              style={{
                gridColumn: "2 / 2",
                display: "grid",
                gridTemplateColumns: "24% 1% 24% 1% 50%",
              }}
            >
              <h5 className="subtitle" style={{ gridColumn: "1 / 6" }}>
                {t("codagePelote.actionsPanel.actionType.service")}{" "}
              </h5>
              <div style={{ gridColumn: "1 / 1", height: "185px" }}>
                {PieChartGraph({
                  categories: [
                    t(
                      "codagePelote.actionsPanel.actionTypeDetails.option.back",
                    ),
                    t(
                      "codagePelote.actionsPanel.actionTypeDetails.option.front",
                    ),
                    t("codagePelote.actionsPanel.actionTypeDetails.option.ace"),
                    t(
                      "codagePelote.actionsPanel.actionTypeDetails.option.pasa",
                    ),
                    t(
                      "codagePelote.actionsPanel.actionTypeDetails.option.falta",
                    ),
                  ],
                  values: serviceTypeMetrics.map((metric) => metric.total),
                  colors: ["green", "blue", "purple", "orange", "red"],
                  height: 180,
                })}
              </div>

              <div style={{ gridColumn: "3 / 3", height: "185px" }}>
                <table style={{ height: "90%", width: "100%" }}>
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "40%" }}></th>
                      <th scope="col" style={{ width: "60%" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {serviceTypeMetrics.map((metric) => (
                      <tr key={metric.name}>
                        <td>
                          {" "}
                          <p className="text">{metric.total}</p>{" "}
                        </td>
                        <td>
                          <p
                            className="text"
                            style={{
                              borderRadius: "10px",
                              backgroundColor: metric.color,
                            }}
                          >
                            {metric.name}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div style={{ gridColumn: "5 / 5", height: "185px" }}>
                <h5 className="subtitle" style={{ fontSize: "12px" }}>
                  {t("metricsPelote.graphServiceEnding")}{" "}
                </h5>
                {ScatterChartAreas({
                  data: serviceList,
                  type: "service",
                  numberOfAreas: playground?.number_areas || 0,
                  height: 150,
                })}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridRow: "6 / 6",
              gridTemplateColumns: "2% 96% 2%",
            }}
          >
            <div
              className="box"
              style={{
                gridColumn: "2 / 2",
                display: "grid",
                gridTemplateRows: "10% 40% 50%",
                height: "100%",
              }}
            >
              <div style={{ gridRow: "1 / 1" }}>
                <h5 className="subtitle">
                  {t("codagePelote.actionsPanel.actionType.reception")}{" "}
                </h5>
              </div>
              <div
                style={{
                  gridRow: "2 / 2",
                  display: "grid",
                  gridTemplateColumns: "1% 30% 1% 36% 1% 30% 1%",
                  width: "200mm",
                }}
              >
                <div style={{ gridColumn: "2 / 2" }}>
                  {PieChartGraph({
                    categories: [
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.aire",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.bote",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.bote_corrido",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.bote_pronto",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.rebote",
                      ),
                    ],
                    values: receptionTypeMetrics.map((metric) => metric.total),
                    colors: ["green", "blue", "purple", "orange", "red"],
                    height: 150,
                  })}
                </div>
                <div style={{ gridColumn: "4 / 4" }}>
                  <table
                    style={{ height: "80%", width: "100%", marginTop: "10px" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "10%" }}></th>
                        <th scope="col" style={{ width: "40%" }}></th>
                        <th scope="col" style={{ width: "40%" }}></th>
                        <th scope="col" style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {receptionTypeMetrics.map((metric) => (
                        <tr key={metric.name}>
                          <td></td>
                          <td>
                            {" "}
                            <p className="text" style={{ fontSize: "15px" }}>
                              {metric.positives}/{metric.total} -{" "}
                              {metric.positives === 0
                                ? 0
                                : roundDecimal(
                                    (100 * metric.positives) / metric.total,
                                  )}
                              %{" "}
                            </p>{" "}
                          </td>
                          <td>
                            <p
                              className="text"
                              style={{
                                borderRadius: "10px",
                                backgroundColor: metric.color,
                                fontSize: "15px",
                              }}
                            >
                              {metric.name}
                            </p>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ gridColumn: "6 / 6" }}>
                  {PieChartGraph({
                    categories: [
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.forehand",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.backhand",
                      ),
                    ],
                    values: [
                      metricsGamePlayer.receptions.side.forehand,
                      metricsGamePlayer.receptions.side.backhand,
                    ],
                    colors: ["blue", "orange"],
                    height: 150,
                  })}
                </div>
              </div>
              <div
                style={{
                  gridRow: "3 / 3",
                  display: "grid",
                  gridTemplateColumns: "2% 47% 2% 47% 2%",
                }}
              >
                <div style={{ gridColumn: "2 / 2" }}>
                  <h5 className="subtitle" style={{ fontSize: "15px" }}>
                    {t("metricsPelote.graphPositiveReceptionZone")}
                  </h5>
                  {ScatterChartAreas({
                    data: receptionsList.filter(
                      (reception) => reception.result_reception === "positive",
                    ),
                    type: "reception",
                    numberOfAreas: playground?.number_areas || 0,
                    height: 140,
                  })}
                </div>
                <div style={{ gridColumn: "4 / 4" }}>
                  <h5 className="subtitle" style={{ fontSize: "15px" }}>
                    {t("metricsPelote.graphNegativeReceptionZone")}
                  </h5>
                  {ScatterChartAreas({
                    data: receptionsList.filter(
                      (reception) => reception.result_reception === "negative",
                    ),
                    type: "reception",
                    numberOfAreas: playground?.number_areas || 0,
                    height: 140,
                  })}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridRow: "8 / 8",
              gridTemplateColumns: "2% 96% 2%",
            }}
          >
            <div
              className="box"
              style={{
                gridColumn: "2 / 2",
                display: "grid",
                gridTemplateRows: "10% 40% 50%",
                height: "100%",
              }}
            >
              <div style={{ gridRow: "1 / 1" }}>
                <h5 className="subtitle">
                  {t("codagePelote.actionsPanel.actionType.shot")}{" "}
                </h5>
              </div>
              <div
                style={{
                  gridRow: "2 / 2",
                  display: "grid",
                  gridTemplateColumns: "1% 30% 1% 36% 1% 30% 1%",
                  width: "200mm",
                }}
              >
                <div style={{ gridColumn: "2 / 2" }}>
                  {PieChartGraph({
                    categories: [
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.classic",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.attack",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.picada",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.dejada",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.txik_txak",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.txula",
                      ),
                    ],
                    values: shotTypeMetrics.map((metric) => metric.total),
                    colors: [
                      "green",
                      "blue",
                      "purple",
                      "orange",
                      "red",
                      "grey",
                    ],
                    height: 150,
                  })}
                </div>
                <div style={{ gridColumn: "4 / 4" }}>
                  <table
                    style={{ height: "70%", width: "100%", marginTop: "10px" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "10%" }}></th>
                        <th scope="col" style={{ width: "40%" }}></th>
                        <th scope="col" style={{ width: "40%" }}></th>
                        <th scope="col" style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {shotTypeMetrics.map((metric) => (
                        <tr key={metric.name}>
                          <td></td>
                          <td>
                            {" "}
                            <p className="text" style={{ fontSize: "13px" }}>
                              {metric.total - metric.negatives}/{metric.total} -{" "}
                              {metric.total - metric.negatives === 0
                                ? 0
                                : roundDecimal(
                                    (100 * (metric.total - metric.negatives)) /
                                      metric.total,
                                  )}
                              %{" "}
                            </p>{" "}
                          </td>
                          <td>
                            <p
                              className="text"
                              style={{
                                borderRadius: "10px",
                                backgroundColor: metric.color,
                                fontSize: "13px",
                              }}
                            >
                              {metric.name}
                            </p>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ gridColumn: "6 / 6" }}>
                  {PieChartGraph({
                    categories: [
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.forehand",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.backhand",
                      ),
                    ],
                    values: [
                      metricsGamePlayer.shots.side.forehand,
                      metricsGamePlayer.shots.side.backhand,
                    ],
                    colors: ["blue", "orange"],
                    height: 150,
                  })}
                </div>
              </div>
              <div
                style={{
                  gridRow: "3 / 3",
                  display: "grid",
                  gridTemplateColumns: "2% 47% 2% 47% 2%",
                }}
              >
                <div style={{ gridColumn: "2 / 2" }}>
                  <h5 className="subtitle" style={{ fontSize: "15px" }}>
                    {t("metricsPelote.graphSuccessShotZone")}
                  </h5>
                  {ScatterChartAreas({
                    data: shotsList.filter(
                      (shot) => shot.result_shot === "positive",
                    ),
                    type: "shot",
                    numberOfAreas: playground?.number_areas || 0,
                    height: 140,
                  })}
                </div>
                <div style={{ gridColumn: "4 / 4" }}>
                  <h5 className="subtitle" style={{ fontSize: "15px" }}>
                    {t("metricsPelote.graphNegativeShotZone")}
                  </h5>
                  {ScatterChartAreas({
                    data: shotsList.filter(
                      (shot) => shot.result_shot === "negative",
                    ),
                    type: "shot",
                    numberOfAreas: playground?.number_areas || 0,
                    height: 140,
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* If the player is Back */}
      {type === "back" && (
        <div
          className="body"
          style={{
            display: "grid",
            height: "287mm",
            gridTemplateRows: "2% 18% 2% 38% 2% 38%",
          }}
        >
          <div
            style={{
              display: "grid",
              gridRow: "2 / 2",
              gridTemplateColumns: "2% 96% 2%",
            }}
          >
            <div
              className="box"
              style={{
                gridColumn: "2 / 2",
                display: "grid",
                gridTemplateColumns: "1% 35% 1% 26% 1% 35% 1%",
              }}
            >
              <div
                style={{
                  gridColumn: "2 / 2",
                  display: "grid",
                  gridTemplateRows: "10% 50% 40%",
                  height: "195px",
                }}
              >
                <div style={{ gridRow: "1 / 1" }}></div>
                <div style={{ gridRow: "2 / 2" }}>
                  <p
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {metricsGamePlayer.lastname}
                  </p>
                  <p style={{ fontSize: "30px" }}>
                    {metricsGamePlayer.firstname}
                  </p>
                </div>
                <div style={{ gridRow: "3 / 3" }}>
                  <p style={{ fontSize: "30px" }}>
                    {t(metricsGamePlayer.position)}
                  </p>
                </div>
              </div>
              <div style={{ gridColumn: "4 / 4", height: "195px" }}>
                <img
                  src={metricsGamePlayer.photo_url}
                  alt={metricsGamePlayer.firstname}
                  style={{ width: "auto", height: "100%" }}
                />
              </div>
              <div
                style={{
                  gridColumn: "6 / 6",
                  height: "195px",
                  display: "grid",
                  gridTemplateRows: "33% 33% 33%",
                }}
              >
                <div style={{ gridRow: "1 / 1" }}>
                  <p style={{ fontSize: "20px" }}>Pelotes jouées :</p>
                  <p style={{ fontSize: "25px" }}>
                    {" "}
                    {countBallsPlayed} / {countBallsPlayedTeam}{" "}
                  </p>
                </div>
                <div style={{ gridRow: "2 / 2" }}>
                  <p style={{ fontSize: "20px" }}>Points gagnés : </p>
                  <p style={{ fontSize: "25px" }}>
                    {actionsLeadingToPointsWon} /{" "}
                    {actionsLeadingToPointsWonTeam}
                  </p>
                </div>
                <div style={{ gridRow: "3 / 3" }}>
                  <p style={{ fontSize: "20px" }}>Points perdus : </p>
                  <p style={{ fontSize: "25px" }}>
                    {actionsLeadingToPointsLost} /{" "}
                    {actionsLeadingToPointsLostTeam}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridRow: "4 / 4",
              gridTemplateColumns: "2% 96% 2%",
            }}
          >
            <div
              className="box"
              style={{
                gridColumn: "2 / 2",
                display: "grid",
                gridTemplateRows: "10% 40% 50%",
                height: "100%",
              }}
            >
              <div style={{ gridRow: "1 / 1" }}>
                <h5 className="subtitle">
                  {t("codagePelote.actionsPanel.actionType.reception")}{" "}
                </h5>
              </div>
              <div
                style={{
                  gridRow: "2 / 2",
                  display: "grid",
                  gridTemplateColumns: "1% 30% 1% 36% 1% 30% 1%",
                  width: "200mm",
                }}
              >
                <div style={{ gridColumn: "2 / 2" }}>
                  {PieChartGraph({
                    categories: [
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.aire",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.bote",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.bote_corrido",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.bote_pronto",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.rebote",
                      ),
                    ],
                    values: receptionTypeMetrics.map((metric) => metric.total),
                    colors: ["green", "blue", "purple", "orange", "red"],
                    height: 150,
                  })}
                </div>
                <div style={{ gridColumn: "4 / 4" }}>
                  <table
                    style={{ height: "80%", width: "100%", marginTop: "10px" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "10%" }}></th>
                        <th scope="col" style={{ width: "40%" }}></th>
                        <th scope="col" style={{ width: "40%" }}></th>
                        <th scope="col" style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {receptionTypeMetrics.map((metric) => (
                        <tr key={metric.name}>
                          <td></td>
                          <td>
                            {" "}
                            <p className="text" style={{ fontSize: "15px" }}>
                              {metric.positives}/{metric.total} -{" "}
                              {metric.positives === 0
                                ? 0
                                : roundDecimal(
                                    (100 * metric.positives) / metric.total,
                                  )}
                              %{" "}
                            </p>{" "}
                          </td>
                          <td>
                            <p
                              className="text"
                              style={{
                                borderRadius: "10px",
                                backgroundColor: metric.color,
                                fontSize: "15px",
                              }}
                            >
                              {metric.name}
                            </p>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ gridColumn: "6 / 6" }}>
                  {PieChartGraph({
                    categories: [
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.forehand",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.backhand",
                      ),
                    ],
                    values: [
                      metricsGamePlayer.receptions.side.forehand,
                      metricsGamePlayer.receptions.side.backhand,
                    ],
                    colors: ["blue", "orange"],
                    height: 150,
                  })}
                </div>
              </div>
              <div
                style={{
                  gridRow: "3 / 3",
                  display: "grid",
                  gridTemplateColumns: "2% 47% 2% 47% 2%",
                }}
              >
                <div style={{ gridColumn: "2 / 2" }}>
                  <h5 className="subtitle" style={{ fontSize: "15px" }}>
                    {t("metricsPelote.graphPositiveReceptionZone")}
                  </h5>
                  {ScatterChartAreas({
                    data: receptionsList.filter(
                      (reception) => reception.result_reception === "positive",
                    ),
                    type: "reception",
                    numberOfAreas: playground?.number_areas || 0,
                    height: 140,
                  })}
                </div>
                <div style={{ gridColumn: "4 / 4" }}>
                  <h5 className="subtitle" style={{ fontSize: "15px" }}>
                    {t("metricsPelote.graphNegativeReceptionZone")}
                  </h5>
                  {ScatterChartAreas({
                    data: receptionsList.filter(
                      (reception) => reception.result_reception === "negative",
                    ),
                    type: "reception",
                    numberOfAreas: playground?.number_areas || 0,
                    height: 140,
                  })}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "grid",
              gridRow: "6 / 6",
              gridTemplateColumns: "2% 96% 2%",
            }}
          >
            <div
              className="box"
              style={{
                gridColumn: "2 / 2",
                display: "grid",
                gridTemplateRows: "10% 40% 50%",
                height: "100%",
              }}
            >
              <div style={{ gridRow: "1 / 1" }}>
                <h5 className="subtitle">
                  {t("codagePelote.actionsPanel.actionType.shot")}{" "}
                </h5>
              </div>
              <div
                style={{
                  gridRow: "2 / 2",
                  display: "grid",
                  gridTemplateColumns: "1% 30% 1% 36% 1% 30% 1%",
                  width: "200mm",
                }}
              >
                <div style={{ gridColumn: "2 / 2" }}>
                  {PieChartGraph({
                    categories: [
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.classic",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.attack",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.picada",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.dejada",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.txik_txak",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.txula",
                      ),
                    ],
                    values: shotTypeMetrics.map((metric) => metric.total),
                    colors: [
                      "green",
                      "blue",
                      "purple",
                      "orange",
                      "red",
                      "grey",
                    ],
                    height: 150,
                  })}
                </div>
                <div style={{ gridColumn: "4 / 4" }}>
                  <table
                    style={{ height: "70%", width: "100%", marginTop: "10px" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "10%" }}></th>
                        <th scope="col" style={{ width: "40%" }}></th>
                        <th scope="col" style={{ width: "40%" }}></th>
                        <th scope="col" style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {shotTypeMetrics.map((metric) => (
                        <tr key={metric.name}>
                          <td></td>
                          <td>
                            {" "}
                            <p className="text" style={{ fontSize: "13px" }}>
                              {metric.total - metric.negatives}/{metric.total} -{" "}
                              {metric.total - metric.negatives === 0
                                ? 0
                                : roundDecimal(
                                    (100 * (metric.total - metric.negatives)) /
                                      metric.total,
                                  )}
                              %{" "}
                            </p>{" "}
                          </td>
                          <td>
                            <p
                              className="text"
                              style={{
                                borderRadius: "10px",
                                backgroundColor: metric.color,
                                fontSize: "13px",
                              }}
                            >
                              {metric.name}
                            </p>
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ gridColumn: "6 / 6" }}>
                  {PieChartGraph({
                    categories: [
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.forehand",
                      ),
                      t(
                        "codagePelote.actionsPanel.actionTypeDetails.option.backhand",
                      ),
                    ],
                    values: [
                      metricsGamePlayer.shots.side.forehand,
                      metricsGamePlayer.shots.side.backhand,
                    ],
                    colors: ["blue", "orange"],
                    height: 150,
                  })}
                </div>
              </div>
              <div
                style={{
                  gridRow: "3 / 3",
                  display: "grid",
                  gridTemplateColumns: "2% 47% 2% 47% 2%",
                }}
              >
                <div style={{ gridColumn: "2 / 2" }}>
                  <h5 className="subtitle" style={{ fontSize: "15px" }}>
                    {t("metricsPelote.graphSuccessShotZone")}
                  </h5>
                  {ScatterChartAreas({
                    data: shotsList.filter(
                      (shot) => shot.result_shot === "positive",
                    ),
                    type: "shot",
                    numberOfAreas: playground?.number_areas || 0,
                    height: 140,
                  })}
                </div>
                <div style={{ gridColumn: "4 / 4" }}>
                  <h5 className="subtitle" style={{ fontSize: "15px" }}>
                    {t("metricsPelote.graphNegativeShotZone")}
                  </h5>
                  {ScatterChartAreas({
                    data: shotsList.filter(
                      (shot) => shot.result_shot === "negative",
                    ),
                    type: "shot",
                    numberOfAreas: playground?.number_areas || 0,
                    height: 140,
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <GamePdfFooter pageNumber={pageNumber} reportTitle={reportTitle} />
    </div>
  );
};

export default GamePdfPlayer;
