import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function PageUnauthorized() {
  const { t } = useTranslation();

  return (
    <div id="page-unhautorized">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "200px",
        }}
      >
        <div style={{ margin: "10px", fontSize: "3rem" }}>
          {t("unauthorized.access-denied")}
        </div>
        <div style={{ margin: "10px", fontSize: "2rem" }}>
          {t("unauthorized.not-allowed-to-browse-here")}
        </div>
        <Link to="/" style={{ margin: "10px", color: "white" }}>
          {t("unauthorized.go-back-to-home-page")}
        </Link>
        <Link to="/logout" style={{ margin: "10px", color: "white" }}>
          {t("unauthorized.log-out")}
        </Link>
      </div>
    </div>
  );
}

export default PageUnauthorized;
