import "./stringInput.scss";

type InputProps = {
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

function StringInput({ placeholder, value, onChange, className }: InputProps) {
  return (
    <div className={`${className} string-input`}>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}

export default StringInput;
