import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

import useCreateAction from "@pelote/hooks/useCreateAction";
import ButtonCodage from "@/ui-lib/button-codage/ButtonCodage";
import UseCurrentActionCestaPunta from "@pelote/pages/cesta-punta/user/codage/redux/hooks/currentActionCestaPuntaHook";
import { resetPanelActionsState } from "@pelote/pages/cesta-punta/user/codage/redux/features/currentActionReducer";
import useActionId from "@pelote/hooks/useActionId";
import usePaginatedActionTypes from "@pelote/hooks/usePaginatedActionTypes";
import { Action } from "@pelote/types/Action";
import UsePreviousAction from "@pelote/pages/cesta-punta/user/codage/redux/hooks/previousActionHook";
import { setIsEditMode } from "@pelote/pages/cesta-punta/user/codage/redux/features/editModeReducer";

import "./CancelValidateActions.scss";

export function CancelValidateAction() {
  const { t } = useTranslation();
  const { gameId } = useParams();
  const dispatch = useDispatch();
  const { previousAction } = UsePreviousAction();
  const [hasSameTime, setHasSameTime] = useState(false);

  const {
    currentAction,
    currentActionReceptionPositiveShot,
    isEditMode,
    isSelectedObject,
  } = UseCurrentActionCestaPunta();

  // Must import actionTypes in order to know which one is Selected and access to VALIDATE button
  const { service, reception, shot, idActionTypeShot } =
    usePaginatedActionTypes();
  const isActionTypeService = currentAction.action_type_id === service?.id;
  const isActionTypeReception = currentAction.action_type_id === reception?.id;
  const isActionTypeShot = currentAction.action_type_id === shot?.id;

  const { handleCreateAction: handleCreateActionGame } = useCreateAction();
  const { handleDeleteAction, handleEditAction } = useActionId(gameId);

  const removeEmptyLabelOptionIds = (action: Action) => {
    const filteredLabelOptionIds = action.label_option_ids.filter(
      (item) => item.label_id !== "" && item.option_id !== "",
    );
    return { ...action, label_option_ids: filteredLabelOptionIds };
  };

  const handleCancel = () => {
    dispatch(resetPanelActionsState());
    dispatch(setIsEditMode({ isEditMode: false }));
  };

  // Check if the time of the action is the same as the previous action and if so block the codage and show a tooltip
  useEffect(() => {
    // Previous Click Time has already the righ clicktime (*1000)
    const previousClicktime = Math.trunc(previousAction?.action.click_time);
    const actionToPostOrEditClickTime = Math.trunc(
      currentAction.click_time * 1000,
    );
    // Check if time is different of 0 at the beginning of the game (if not the tooltip appears)
    // Also check if the previous action is empty, meaning no click time identical
    // Also check if the previous action is the same as the current action, if that the case we can edit it and validate
    if (
      actionToPostOrEditClickTime === 0 ||
      previousAction.action.id === "" ||
      previousAction.action.id === currentAction.id
    ) {
      setHasSameTime(false);
    } else {
      const hasSameTime = previousClicktime === actionToPostOrEditClickTime;
      setHasSameTime(hasSameTime);
    }
  }, [currentAction]);

  const handleValidate = () => {
    const actionToPostOrEdit = removeEmptyLabelOptionIds(currentAction);

    if (isEditMode) {
      if (gameId) {
        if (actionToPostOrEdit.action_type_id === idActionTypeShot) {
          handleEditAction(currentActionReceptionPositiveShot.id, {
            game_id: gameId,
            action_type_id: currentActionReceptionPositiveShot.action_type_id,
            player_id: currentActionReceptionPositiveShot.player_id,
            click_time: Math.trunc(
              currentActionReceptionPositiveShot.click_time * 1000,
            ),
            labels_options_ids:
              currentActionReceptionPositiveShot.label_option_ids,
            coordinates: currentActionReceptionPositiveShot.coordinates,
            set_number: currentAction.set_number,
          });
        }
        handleEditAction(actionToPostOrEdit.id, {
          game_id: gameId,
          action_type_id: actionToPostOrEdit.action_type_id,
          player_id: actionToPostOrEdit.player_id,
          click_time: Math.trunc(actionToPostOrEdit.click_time * 1000),
          labels_options_ids: actionToPostOrEdit.label_option_ids,
          coordinates: actionToPostOrEdit.coordinates,
          set_number: currentAction.set_number,
        });
      }
    } else {
      if (gameId) {
        //  If there is a shot, obviously there is a reception positive so we must post two actions
        if (actionToPostOrEdit.action_type_id === idActionTypeShot) {
          handleCreateActionGame({
            game_id: gameId,
            action_type_id: currentActionReceptionPositiveShot.action_type_id,
            player_id: currentActionReceptionPositiveShot.player_id,
            click_time: Math.trunc(
              currentActionReceptionPositiveShot.click_time * 1000,
            ),
            labels_options_ids:
              currentActionReceptionPositiveShot.label_option_ids,
            coordinates: currentActionReceptionPositiveShot.coordinates,
            // This set_number is the same as the shot
            set_number: currentAction.set_number,
          });
        }
        handleCreateActionGame({
          game_id: gameId,
          action_type_id: actionToPostOrEdit.action_type_id,
          player_id: actionToPostOrEdit.player_id,
          click_time: Math.trunc(actionToPostOrEdit.click_time * 1000),
          labels_options_ids: actionToPostOrEdit.label_option_ids,
          coordinates: actionToPostOrEdit.coordinates,
          set_number: currentAction.set_number,
        });
      }
    }
    dispatch(resetPanelActionsState());
    dispatch(setIsEditMode({ isEditMode: false }));
    return actionToPostOrEdit;
  };

  const handleDelete = () => {
    handleDeleteAction(
      currentAction,
      idActionTypeShot,
      currentActionReceptionPositiveShot.id,
    );
    dispatch(resetPanelActionsState());
    dispatch(setIsEditMode({ isEditMode: false }));
  };

  return (
    <div
      className="cancel-validate-container"
      data-tooltip-id="idHasSameTimeTooltip"
      data-tooltip-content={
        hasSameTime ? t("codagePelote.cancelValidation.error") : ""
      }
    >
      <ButtonCodage
        id="cancel-action-button"
        text={t("codagePelote.cancelValidation.cancel")}
        onClick={() => handleCancel()}
        className="cancel-validate-button"
      />
      <ButtonCodage
        id="validate-action-button"
        text={t("codagePelote.cancelValidation.validate")}
        onClick={() => handleValidate()}
        className="cancel-validate-button"
        isSelected={
          (isSelectedObject.service && isActionTypeService) ||
          (isSelectedObject.receptionNegative && isActionTypeReception) ||
          (isSelectedObject.receptionPositiveShot && isActionTypeShot)
        }
        customStyles={{ backgroundColor: "green" }}
        disabled={
          !(
            (isSelectedObject.service && isActionTypeService) ||
            (isSelectedObject.receptionNegative && isActionTypeReception) ||
            (isSelectedObject.receptionPositiveShot && isActionTypeShot)
          ) || hasSameTime
        }
      />
      <Tooltip
        id="idHasSameTimeTooltip"
        className="idHasSameTimeTooltipClass"
        classNameArrow="idHasSameTimeTooltipArrow"
      />
      {isEditMode ? (
        <ButtonCodage
          id="validate-delete-button"
          text={t("codagePelote.cancelValidation.delete")}
          onClick={() => handleDelete()}
          className="cancel-validate-button"
          isSelected={true}
          customStyles={{ backgroundColor: "purple" }}
        />
      ) : null}
    </div>
  );
}
