import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import ActionTypesApi, {
  CreateActionTypeInputs,
} from "@pelote/api/ActionTypesApi";
import { APIError } from "@pelote/types/ApiError";

function usePageAdminActionTypeCreate(): {
  sportPath: string | undefined;
  handleCreateActionType: (
    createActionTypeInputs: CreateActionTypeInputs,
  ) => void;
  isLoading: boolean;
  isCreationPending: boolean;
  isCreationSuccess: boolean;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Handle the creation of a actionType
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const {
    mutate: createActionType,
    isPending: isCreationPending,
    isSuccess: isCreationSuccess,
  } = useMutation({
    mutationFn: (newActionType: CreateActionTypeInputs) =>
      ActionTypesApi.create(newActionType),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["indexActionTypes"] });
      toast.success(t("admin.actiontype.created"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.creation"), {
        autoClose: 3000,
      });
    },
  });
  function handleCreateActionType(
    createActionTypeInputs: CreateActionTypeInputs,
  ) {
    if (currentSport === undefined) return;

    createActionTypeInputs.sport_id = currentSport.id;
    createActionType(createActionTypeInputs);
  }

  return {
    sportPath,
    handleCreateActionType,
    isLoading: isUserLoading,
    isCreationPending,
    isCreationSuccess,
  };
}

export default usePageAdminActionTypeCreate;
