import { useAppSelector } from "@pelote/pages/cesta-punta/user/codage/redux/hooks";
import { RootState } from "@pelote/pages/cesta-punta/user/codage/redux/store";

const UseVideoPlayerData = () => {
  const videoPlayerState = useAppSelector(
    (state: RootState) => state.videoPlayer,
  );
  const currentSpeed = videoPlayerState.currentSpeed;
  const isMuted = videoPlayerState.isMuted;
  const isPlaying = videoPlayerState.isPlaying;
  const isCurrentTimeMounted = videoPlayerState.isCurrentTimeMounted;

  return { currentSpeed, isMuted, isPlaying, isCurrentTimeMounted };
};

export default UseVideoPlayerData;
