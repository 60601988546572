import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import refereeIcon from "@assets/icons/referee_icon.svg";

function PageNotFound() {
  const { t } = useTranslation();

  return (
    <div className="body">
      <div className="row">
        <div className="col-12" style={{ textAlign: "center" }}>
          <img width="300px" height="300px" src={refereeIcon} alt="profile" />
        </div>
        <div className="col-12">
          <h1 style={{ marginTop: 40, textAlign: "center" }}>{t("404")}</h1>
        </div>
        <div className="col-12" style={{ marginTop: 40, textAlign: "center" }}>
          <Link to="/">Retour à l'acceuil</Link>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
