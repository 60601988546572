import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreatePoolInputs } from "@pelote/api/PoolsApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import Loader from "@pelote/components/loader/Loader";
import usePageAdminPoolCreate from "./usePageAdminPoolCreate";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminPool.scss";

const PageAdminPoolCreate = () => {
  const {
    sportPath,
    handleCreatePool,
    isCreationSuccess,
    isLoading,
    seasonSelectOptions,
    competitionSelectOptions,
  } = usePageAdminPoolCreate();

  // Navigate to the list of playgrounds when the creation is successful
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (!isLoading && isCreationSuccess)
      navigate(`/admin/pelote/${sportPath}/pools`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreatePoolInputs>({
    defaultValues: {
      name: "",
      display_order: 0,
      season_id: seasonSelectOptions?.[0]?.value as string,
      competition_id: competitionSelectOptions?.[0]?.value as string,
    },
  });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={t("admin.pool.creation")} />
      <form onSubmit={handleSubmit(handleCreatePool)}>
        <div className="page-pool-form">
          {seasonSelectOptions && (
            <div>
              <FormSelect
                id="season_id"
                labelName={t("season")}
                options={seasonSelectOptions}
                textToDisplayIfError={t("admin.necessary.season")}
                errorMessage={errors.season_id?.message}
                register={register}
              />
            </div>
          )}
          {competitionSelectOptions && (
            <div>
              <FormSelect
                id="competition_id"
                labelName={t("competition")}
                options={competitionSelectOptions}
                textToDisplayIfError={t("admin.necessary.competition")}
                errorMessage={errors.competition_id?.message}
                register={register}
              />
            </div>
          )}
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div>
            <FormInput
              id="display_order"
              labelName={t("admin.displayOrder")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.displayOrder")}
              errorMessage={errors.display_order?.message}
              register={register}
            />
          </div>
          <div className="page-pool-buttons">
            <FormGoBackButton
              labelName={t("admin.cancel")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/pools`)}
            />
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default PageAdminPoolCreate;
