/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import userContext from "@/contexts/userContext";
import Loader from "@pelote/components/loader/Loader";
import { PermissionKey } from "@user/types/Permission";
import { hasPermission } from "./utils";

function EnsureHasPermission({
  permissionKeys,
  children,
}: {
  permissionKeys: PermissionKey[];
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const { permissions: userPermissions, isLoading: isUserLoading } =
    useContext(userContext);

  useEffect(() => {
    if (isUserLoading) {
      return;
    }

    if (!hasPermission(userPermissions, permissionKeys)) {
      console.warn(
        `This user is not allowed to reach this page. Redirecting to unauthorized page.`,
      );
      navigate("/unauthorized");
    }
  }, [isUserLoading]);

  // If the user is not yet loaded, display a loader
  if (isUserLoading) {
    return <Loader />;
  }

  return children;
}

export default EnsureHasPermission;
