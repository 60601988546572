import { useQuery } from "@tanstack/react-query";

import GamesApi from "@pelote/api/GamesApi";
import { MetricsGameResponse } from "@pelote/types/MetricsGame";
import { MetricsGameTeamResponse } from "@pelote/types/MetricsGameTeam";
import { MetricsGamePlayerResponse } from "@pelote/types/MetricsGamePlayer";
import { MetricsGameActionsListResponse } from "@pelote/types/MetricsGameActionsList";

interface useMetricsGameOptions {
  gameId?: string;
  skip?: boolean;
}

function useMetricsGame(options: Partial<useMetricsGameOptions> = {}): {
  metricsGameDataResponse: MetricsGameResponse | undefined;
  metricsGameTeamDataResponse: MetricsGameTeamResponse | undefined;
  metricsGamePlayerDataResponse: MetricsGamePlayerResponse | undefined;
  metricsGameActionsListDataResponse:
    | MetricsGameActionsListResponse
    | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { gameId, skip = false } = options;
  // Fetch a list of labels based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const {
    data: metricsGameDataResponse,
    isLoading: isLoadingGame,
    isError: isErrorGame,
  } = useQuery({
    queryKey: ["showMetricsGame", gameId],
    queryFn: () => {
      if (gameId === undefined) return;
      return GamesApi.getMetricsGame(gameId);
    },
    enabled: !skip,
  });

  const {
    data: metricsGameTeamDataResponse,
    isLoading: isLoadingGameTeam,
    isError: isErrorGameTeam,
  } = useQuery({
    queryKey: ["showMetricsGameTeam", gameId],
    queryFn: () => {
      if (gameId === undefined) return;
      return GamesApi.getMetricsGameTeam(gameId);
    },
    enabled: !skip,
  });

  const {
    data: metricsGamePlayerDataResponse,
    isLoading: isLoadingGamePlayer,
    isError: isErrorGamePlayer,
  } = useQuery({
    queryKey: ["showMetricsGamePlayer", gameId],
    queryFn: () => {
      if (gameId === undefined) return;
      return GamesApi.getMetricsGamePlayer(gameId);
    },
    enabled: !skip,
  });

  const {
    data: metricsGameActionsListDataResponse,
    isLoading: isLoadingGameActionsList,
    isError: isErrorGameActionsList,
  } = useQuery({
    queryKey: ["showMetricsGameActionsList", gameId],
    queryFn: () => {
      if (gameId === undefined) return;
      return GamesApi.getMetricsGameActionsList(gameId);
    },
    enabled: !skip,
  });

  const isLoading =
    isLoadingGame ||
    isLoadingGameTeam ||
    isLoadingGamePlayer ||
    isLoadingGameActionsList;
  const isError =
    isErrorGame ||
    isErrorGameTeam ||
    isErrorGamePlayer ||
    isErrorGameActionsList;

  return {
    metricsGameDataResponse,
    metricsGameTeamDataResponse,
    metricsGamePlayerDataResponse,
    metricsGameActionsListDataResponse,
    isLoading,
    isError,
  };
}

export default useMetricsGame;
