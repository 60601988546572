import { UseFormRegister } from "react-hook-form";

import "./formInput.scss";

export enum InputType {
  text = "text",
  date = "date",
  dateTimeLocal = "datetime-local",
  checkbox = "checkbox",
  number = "number",
}

type FormInputProps = {
  id: string;
  labelName: string;
  type: InputType;
  disabled?: boolean;
  textToDisplayIfError?: string;
  errorMessage?: string;
  register: UseFormRegister<any>; // Provide the missing type argument
  registerValueAsDate?: boolean;
  labelColor?: string;
  className?: string;
};

function FormInput({
  id,
  labelName,
  type,
  disabled = false,
  textToDisplayIfError,
  errorMessage,
  register,
  registerValueAsDate,
  labelColor,
  className,
}: FormInputProps) {
  return (
    <div className={`form-input ${className}`}>
      <label htmlFor={id} style={{ color: labelColor }}>
        {labelName}{" "}
        <span className="required-star">
          {" "}
          {textToDisplayIfError && "*"}
          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
        </span>
      </label>
      <input
        type={type}
        id={id}
        disabled={disabled}
        {...register(id, {
          required: textToDisplayIfError,
          valueAsDate: registerValueAsDate,
        })}
      />
    </div>
  );
}

export default FormInput;
