import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Playground } from "@pelote/types/Playground";
import Table from "@/ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminPlaygroundList from "./usePageAdminPlaygroundList";
import FormButton from "@/ui-lib/form-button/FormButton";

const PageAdminPlaygroundList = () => {
  const { sportPath, paginatedPlaygrounds, isLoading } =
    usePageAdminPlaygroundList({
      page: 1,
      pageSize: 10,
    });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Playground>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),
    columlHelper.accessor("city", {
      cell: (info) => info.getValue(),
      header: () => t("admin.playground.city"),
    }),
    columlHelper.accessor("type", {
      cell: (info) => info.getValue(),
      header: () => t("admin.playground.type"),
    }),
    columlHelper.accessor("number_areas", {
      cell: (info) => info.getValue(),
      header: () => t("admin.playground.areasNumber"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigate(
                `/admin/pelote/${sportPath}/playgrounds/${info.row.original.id}`,
              )
            }
          />
        </div>
      ),
      header: () => {
        t("actions");
      },
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={t("admin.playground.playgrounds")} />
      <FormButton
        labelName={t("admin.playground.create")}
        onClick={() =>
          navigate(`/admin/pelote/${sportPath}/playgrounds/create`)
        }
      />
      <Table
        columns={columns}
        data={paginatedPlaygrounds?.items ?? []}
        hasGlobalFilter={true}
      />
    </>
  );
};

export default PageAdminPlaygroundList;
