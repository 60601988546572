import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";

import { useIsResponsive } from "@/hooks/ResponsiveHook";
import UseTeamsData from "@pelote/pages/cesta-punta/user/codage/redux/hooks/teamsCestaPuntaHook";
import { GamePlayer } from "@pelote/types/GamePlayer";
import { setPlayer } from "@pelote/pages/cesta-punta/user/codage/redux/features/currentActionReducer";
import UseCurrentActionCestaPunta from "@pelote/pages/cesta-punta/user/codage/redux/hooks/currentActionCestaPuntaHook";
import UsePreviousAction from "@pelote/pages/cesta-punta/user/codage/redux/hooks/previousActionHook";
import usePaginatedActionTypes from "@pelote/hooks/usePaginatedActionTypes";
import UseVideoPlayerData from "@pelote/pages/cesta-punta/user/codage/redux/hooks/videoPlayerHook";

import "./TeamPositions.scss";

export function TeamPositions({
  colorTeam,
  videoPlayerRef,
}: {
  colorTeam?: string;
  videoPlayerRef: React.RefObject<ReactPlayer>;
}) {
  const isResponsive = useIsResponsive(1366);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    players,
    avantTeam1,
    avantTeam2,
    arriereTeam1,
    arriereTeam2,
    colorTeam1,
  } = UseTeamsData();

  const { isCurrentTimeMounted } = UseVideoPlayerData();
  const { previousAction } = UsePreviousAction();
  const { paginatedActionTypes, service, typeLabel } =
    usePaginatedActionTypes();

  const { currentAction, isSelected } = UseCurrentActionCestaPunta();

  const handlePlayerSelected = (
    playerSelected: GamePlayer,
    isAutomatizationBut: boolean,
  ) => {
    if (isCurrentTimeMounted && videoPlayerRef.current) {
      dispatch(
        setPlayer({
          idPlayer: playerSelected.id,
          player: playerSelected,
          // Add 1 second to the current time to avoid the same click time for automatic actions after but
          currentTime: isAutomatizationBut
            ? videoPlayerRef.current.getCurrentTime() + 1
            : videoPlayerRef.current.getCurrentTime(),
        }),
      );
    } else {
      return toast.error("Merci de relancer la vidéo", {
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    const player = players.find(
      (player) => player.id === previousAction.action.player_id,
    );
    if (previousAction.action.action_type_id === service?.id) {
      const previousType = previousAction.action.label_option_ids.find(
        (item) => item.label_id === typeLabel?.id,
      );
      const previousOption = paginatedActionTypes?.items[0].labels
        .find((label) => label.name === "type_service")
        ?.options.find((option) => option.id === previousType?.option_id);

      if (previousOption?.name === "front" && player?.team_number === 1) {
        handlePlayerSelected(avantTeam2, true);
      } else if (previousOption?.name === "back" && player?.team_number === 1) {
        handlePlayerSelected(arriereTeam2, true);
      } else if (
        previousOption?.name === "front" &&
        player?.team_number === 2
      ) {
        handlePlayerSelected(avantTeam1, true);
      } else if (previousOption?.name === "back" && player?.team_number === 2) {
        handlePlayerSelected(arriereTeam1, true);
      } else if (previousOption?.name === "ace" && player?.team_number === 1) {
        handlePlayerSelected(avantTeam1, true);
      } else if (previousOption?.name === "ace" && player?.team_number === 2) {
        handlePlayerSelected(avantTeam2, true);
      } else if (
        previousOption?.name === "falta" &&
        player?.team_number === 1
      ) {
        handlePlayerSelected(avantTeam2, true);
      } else if (
        previousOption?.name === "falta" &&
        player?.team_number === 2
      ) {
        handlePlayerSelected(avantTeam1, true);
      }
    }
  }, [previousAction]);

  return (
    <Fragment>
      <div className="team-position">
        {/* ajouter le nom des joueurs en fonction de l'id team et de leur id_player + nom */}
        {isResponsive ? (
          ""
        ) : (
          <a
            style={
              colorTeam
                ? colorTeam === "white" || colorTeam === "yellow"
                  ? { background: colorTeam, color: "black" }
                  : { background: colorTeam, color: "white" }
                : { backgroundColor: "grey" }
            }
          >
            {colorTeam === colorTeam1 ? (
              <>
                {avantTeam1.lastname}
                <br />
                {arriereTeam1.lastname}
              </>
            ) : (
              <>
                {avantTeam2.lastname}
                <br />
                {arriereTeam2.lastname}
              </>
            )}
          </a>
        )}
        <button
          className={
            colorTeam === "white" || colorTeam === "yellow" ? "white" : "black"
          }
          style={
            isSelected.player &&
            currentAction.player_id ===
              (colorTeam === colorTeam1 ? avantTeam1.id : avantTeam2.id)
              ? { backgroundColor: "gold", outline: "none" }
              : colorTeam
                ? colorTeam === "white" || colorTeam === "yellow"
                  ? { background: colorTeam, color: "black" }
                  : { background: colorTeam, color: "white" }
                : { background: "grey" }
          }
          value={colorTeam === colorTeam1 ? avantTeam1.id : avantTeam2.id}
          onClick={() =>
            handlePlayerSelected(
              colorTeam === colorTeam1 ? avantTeam1 : avantTeam2,
              false,
            )
          }
        >
          {/* change it when I18NEXT WILL BE INTRODUCE */}
          {isResponsive
            ? t("codagePelote.teamPositionPanel.av").toUpperCase()
            : t("codagePelote.teamPositionPanel.avant").toUpperCase()}
        </button>
        <button
          className={
            colorTeam === "white" || colorTeam === "yellow" ? "white" : "black"
          }
          style={
            isSelected.player &&
            currentAction.player_id ===
              (colorTeam === colorTeam1 ? arriereTeam1.id : arriereTeam2.id)
              ? { backgroundColor: "gold", outline: "none" }
              : colorTeam
                ? colorTeam === "white" || colorTeam === "yellow"
                  ? { background: colorTeam, color: "black" }
                  : { background: colorTeam, color: "white" }
                : { background: "grey" }
          }
          value={colorTeam === colorTeam1 ? arriereTeam1.id : arriereTeam2.id}
          onClick={() =>
            handlePlayerSelected(
              colorTeam === colorTeam1 ? arriereTeam1 : arriereTeam2,
              false,
            )
          }
        >
          {/* change it when I18NEXT WILL BE INTRODUCE */}
          {isResponsive
            ? t("codagePelote.teamPositionPanel.ar").toUpperCase()
            : t("codagePelote.teamPositionPanel.arriere").toUpperCase()}
        </button>
      </div>
    </Fragment>
  );
}
