import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import PlayersApi, { UpdatePlayerInputs } from "@pelote/api/PlayersApi";
import usePlayer from "@pelote/hooks/usePlayer";
import { APIError } from "@pelote/types/ApiError";
import { Player } from "@pelote/types/Player";
import { SelectOption } from "@/ui-lib/select/Select";
import { genderList } from "@pelote/utils/genderList";
import { lateralityList } from "@pelote/utils/lateralityList";
import { nationalityList } from "@/domains/pelote/utils/nationalityList";

function usePageAdminPlayerEdit(): {
  sportPath: string | undefined;
  player: Player | undefined;
  handleEditPlayer: (updatedPlayer: UpdatePlayerInputs) => void;
  handleDeletePhoto: (id: string, event: React.MouseEvent) => void;
  isLoading: boolean;
  lateralitySelectOptions: SelectOption[];
  genderSelectOptions: SelectOption[];
  nationalitySelectOptions: SelectOption[];
} {
  // Get the player id from the route params
  const { id: playerId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // create the select options for the gender, laterality and nationality
  const genderSelectOptions = genderList();
  const lateralitySelectOptions = lateralityList();
  const nationalitySelectOptions = nationalityList();
  // Fetch the player
  const { player, isLoading: isPlayerLoading } = usePlayer(playerId || "");

  // Handle the edition of a player
  const queryClient = useQueryClient();
  const { mutate: updatePlayer } = useMutation({
    mutationFn: playerId
      ? (updatedPlayer: UpdatePlayerInputs) =>
          PlayersApi.update(playerId, updatedPlayer)
      : undefined,

    onSuccess: (data: Player) => {
      queryClient.invalidateQueries({
        queryKey: ["showPlayer", data.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["indexPlayers"],
      });
      toast.success(t("admin.player.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/players`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edition"), {
        autoClose: 3000,
      });
    },
  });
  function handleEditPlayer(updatedPlayer: UpdatePlayerInputs) {
    if (currentSport === undefined) return;
    if (
      !updatedPlayer.birthdate ||
      isNaN(new Date(updatedPlayer.birthdate).getTime())
    ) {
      // Use 1900-01-01 to reset the birthdate in db (cf. API behavior)
      updatedPlayer.birthdate = "1900-01-01";
    }
    updatePlayer(updatedPlayer);
  }

  // Handle the deletion of a photo
  const queryClientPhotoDeletion = useQueryClient();
  const { mutate: deletePhoto } = useMutation({
    mutationFn: (id: string) => PlayersApi.delete_photo(id),
    onSuccess: (_, id) => {
      queryClientPhotoDeletion.removeQueries({ queryKey: ["showPlayer", id] });
      queryClientPhotoDeletion.invalidateQueries({
        queryKey: ["indexPlayers"],
      });

      toast.success(t("admin.player.photo.deletionSuccess"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.player.photo.deletionError"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeletePhoto(id: string, event: React.MouseEvent) {
    event.preventDefault();
    deletePhoto(id);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isPlayerLoading;

  return {
    sportPath,
    player,
    handleEditPlayer,
    handleDeletePhoto,
    isLoading,
    lateralitySelectOptions,
    genderSelectOptions,
    nationalitySelectOptions,
  };
}

export default usePageAdminPlayerEdit;
