import axios from "axios";

import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { domain } from "@pelote/config";
import { Pool } from "@pelote/types/Pool";
import msalManager from "@/auth/msalManager";

export interface CreatePoolInputs {
  name: string;
  display_order: number;
  sport_id: string; // UUID v4
  season_id: string; // UUID v4
  competition_id: string; // UUID v4
}

export interface UpdatePoolInputs {
  name?: string;
  display_order?: number;
}

export default class PoolsApi {
  static ressource: string = "pools";

  static async create(createPoolInputs: CreatePoolInputs): Promise<Pool> {
    const accessToken = await msalManager.getAccessToken();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/seasons/${createPoolInputs.season_id}/competitions/${createPoolInputs.competition_id}/${this.ressource}`;
    const response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: createPoolInputs,
    });
    return response.data;
  }

  static async index(
    season_id: string,
    competition_id: string,
    page: number = 1,
    pageSize: number = 10,
  ): Promise<PaginatedModel<Pool>> {
    const accessToken = await msalManager.getAccessToken();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}?page=${page}&page_size=${pageSize}`;
    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

  static async show(
    season_id: string,
    competition_id: string,
    pool_id: string,
  ): Promise<Pool> {
    const accessToken = await msalManager.getAccessToken();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}/${pool_id}`;
    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

  static async update(
    season_id: string,
    competition_id: string,
    pool_id: string,
    updatePoolInputs: UpdatePoolInputs,
  ): Promise<Pool> {
    const accessToken = await msalManager.getAccessToken();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}/${pool_id}`;
    const response = await axios(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: updatePoolInputs,
    });
    return response.data;
  }

  static async delete(
    season_id: string,
    competition_id: string,
    pool_id: string,
  ): Promise<void> {
    const accessToken = await msalManager.getAccessToken();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/seasons/${season_id}/competitions/${competition_id}/${this.ressource}/${pool_id}`;
    await axios(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
