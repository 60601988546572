interface urlBuilderQueryParams {
  [key: string]: string | undefined;
}

function buildUrlWithParams(
  baseUrl: string,
  queryParams: urlBuilderQueryParams,
) {
  const url = new URL(baseUrl);
  for (const [key, value] of Object.entries(queryParams)) {
    if (value !== undefined && value.trim() !== "") {
      url.searchParams.append(key, value);
    }
  }
  return url.toString();
}

export { buildUrlWithParams };
