import { ValidationErrors } from "@/domains/pelote/hooks/useValidateActions";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: ValidationErrors = {
  missing_labels: [],
  validator: [
    {
      error_code: "",
      action_id: "",
      action_type: "",
      player_id: "",
      player_name: "",
      player_position: "",
      point_number: 1,
      set_number: 1,
    },
  ],
};

export const validationReducer = createSlice({
  name: "validationReducer",
  initialState,
  reducers: {
    setAllValidationErrors: (
      state: ValidationErrors,
      action: PayloadAction<{ validationErrors: ValidationErrors }>,
    ) => {
      state = action.payload.validationErrors;
      return state;
    },
    resetValidationErrors: () => {
      return initialState;
    },
  },
});

export const { setAllValidationErrors, resetValidationErrors } =
  validationReducer.actions;
export default validationReducer.reducer;
