import "./formButton.scss";

type FormButtonProps = {
  labelName: string;
  onClick?: () => void;
  color?: string;
  disabled?: boolean;
  className?: string;
};

function FormButton({
  labelName,
  onClick,
  color,
  disabled = false,
  className = "",
}: FormButtonProps) {
  return (
    <div className={`form-button ${className}`}>
      <button
        onClick={onClick}
        disabled={disabled}
        type="button"
        style={{ color }}
      >
        {labelName}
      </button>
    </div>
  );
}

export default FormButton;
