import { useParams } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminUserSports from "./usePageAdminUserSports";
import { Sport } from "@user/types/Sport";
import Pagination from "@/ui-lib/pagination/Pagination";
import FormButton from "@/ui-lib/form-button/FormButton";

import "./pageAdminUserSports.scss";

const PageAdminUserSports = () => {
  const { t } = useTranslation();
  const { id: userId } = useParams<{ id: string }>();

  const {
    paginatedUserSports,
    userSportsPage,
    userSportsPageSize,
    setUserSportsPage,
    addSportToUser,
    removeSportFromUser,
    paginatedSports,
    sportsPage,
    sportsPageSize,
    setSportPage,
    isLoading,
  } = usePageAdminUserSports({
    userId,
    userSportsPageSize: 5,
    sportsPageSize: 10,
  });

  // User sports table initialization
  const columlHelperUserSports = createColumnHelper<Sport>();
  const columnsUserSports = [
    columlHelperUserSports.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.sport.name"),
    }),
    columlHelperUserSports.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => removeSportFromUser(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  // Sports table initialization
  const columlHelperSports = createColumnHelper<Sport>();
  const columnsSports = [
    columlHelperSports.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.sport.name"),
    }),
    columlHelperSports.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.add")}
            onClick={() => addSportToUser(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-user-sports">
      <div className="title-user-sports">{t("admin.user.user-sports")}</div>
      <div className="table-users-sports">
        <Table
          columns={columnsUserSports}
          data={paginatedUserSports?.items ?? []}
          hasGlobalFilter={true}
        />
        <div className="pagination">
          <Pagination
            totalItems={paginatedUserSports?.pagination.total_items ?? 0}
            itemsPerPage={userSportsPageSize}
            currentPage={userSportsPage}
            onClick={setUserSportsPage}
          />
        </div>
      </div>
      <div className="title-sports">{t("admin.user.available-sports")}</div>
      <div className="table-available-sports">
        <Table
          columns={columnsSports}
          data={paginatedSports?.items ?? []}
          hasGlobalFilter={true}
        />
        <div className="pagination">
          <Pagination
            totalItems={paginatedSports?.pagination.total_items ?? 0}
            itemsPerPage={sportsPageSize}
            currentPage={sportsPage}
            onClick={setSportPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PageAdminUserSports;
