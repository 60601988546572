import { useContext, useEffect } from "react";

import useNavigateInApp from "@/hooks/useNavigateInApp";
import userContext from "@/contexts/userContext";
import { SportKey } from "@/domains/user/types/Sport";
import { hasPermission, hasSport } from "@/auth/utils";
import { PermissionKey } from "@/domains/user/types/Permission";

const PageHome = () => {
  const { navigateTo } = useNavigateInApp();
  const {
    permissions,
    sports,
    isLoading: isUserLoading,
  } = useContext(userContext);

  // Until we have created a proper home page, we redirect the user to another page depending on its sports and permissions
  useEffect(() => {
    if (isUserLoading) return;

    if (
      hasSport(sports, SportKey.CESTA_PUNTA) &&
      hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.PELOTE_BASE,
      ])
    ) {
      navigateTo(`/cesta-punta/competitions`);
    } else if (
      hasPermission(permissions, [
        PermissionKey.FULL_ACCESS,
        PermissionKey.USER_MANAGEMENT_FULL,
        PermissionKey.USER_MANAGEMENT_ORGANIZATION,
      ])
    ) {
      navigateTo(`/admin/user/organizations`);
    } else {
      navigateTo("/unauthorized");
    }
  }, [isUserLoading]);

  return null;
};

export default PageHome;
