import axios from "axios";

import { domain } from "@pelote/config";
import { Season } from "@pelote/types/Season";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import msalManager from "@/auth/msalManager";

export interface CreateSeasonInputs {
  name: string;
  sport_id: string;
}

export interface UpdateSeasonInputs {
  name: string;
  sport_id: string;
}

export default class SeasonsApi {
  static resource = "seasons";

  static async create(createSeasonInputs: CreateSeasonInputs): Promise<Season> {
    const accessToken = await msalManager.getAccessToken();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}`;
    const response = await axios(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: createSeasonInputs,
    });
    return response.data;
  }

  static async index(
    page: number = 1,
    pageSize: number = 10,
    sortBy?: string,
    sport_id?: string,
  ): Promise<PaginatedModel<Season>> {
    const accessToken = await msalManager.getAccessToken();
    let url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}?page=${page}&page_size=${pageSize}`;
    if (sortBy) {
      url += `&sort_by=${sortBy}`;
    }
    if (sport_id) {
      url += `&sport_id=${sport_id}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

  static async show(id: string): Promise<Season> {
    const accessToken = await msalManager.getAccessToken();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}`;
    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }

  static async update(
    id: string,
    updateSeasonInputs: UpdateSeasonInputs,
  ): Promise<Season> {
    const accessToken = await msalManager.getAccessToken();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}`;
    const response = await axios(url, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: updateSeasonInputs,
    });
    return response.data;
  }

  static async delete(id: string): Promise<void> {
    const accessToken = await msalManager.getAccessToken();
    const url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/${id}`;
    await axios(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
}
