import "./deleteButton.scss";

type DeleteButtonProps = {
  labelName: string;
  onClick?: () => void;
  disabled?: boolean;
  // disableMessage?: string; // TODO: Tooltip message to display when the button is disabled
};

function DeleteButton({
  labelName,
  onClick,
  disabled = false,
}: DeleteButtonProps) {
  return (
    <div className="delete-button">
      <button onClick={onClick} disabled={disabled} type="button">
        {labelName}
      </button>
    </div>
  );
}

export default DeleteButton;
