/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";

import userContext, { UserContext } from "@/contexts/userContext";
import usePaginatedSports from "@user/hooks/usePaginatedSports";
import msalManager from "@/auth/msalManager";
import { Sport } from "@user/types/Sport";
import useUserMe from "@user/hooks/useUserMe";
import { Role } from "@user/types/Role";
import { Permission } from "@user/types/Permission";
import smartlookManager from "@/utils/smartlook";

function UserProvider({ children }: { children: React.ReactNode }) {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isConnectionLoading, setIsConnectionLoading] = useState<boolean>(true);

  const [currentSport, setCurrentSport] = useState<Sport | undefined>(
    undefined,
  );

  useEffect(function initIsConnected() {
    (async () => {
      const instance = await msalManager.getInstance();
      setTimeout(() => {
        // For an unknown reason, the instance.getAllAccounts() is filled after a few moments
        setIsConnected(!!instance.getAllAccounts().length);
        setIsConnectionLoading(false);
      }, 2000);
    })(); // Init + trigger function
  }, []);

  useEffect(function redirectAfterLogin() {
    (async () => {
      const instance = await msalManager.getInstance();
      instance
        .handleRedirectPromise()
        .then((response) => {
          if (response && response.state) {
            const state = JSON.parse(response.state); // Get the state from the response (previously set in the login page)
            if (state.redirectUrl) {
              window.location.href = state.redirectUrl;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    })(); // Init + trigger function
  }, []);

  // Build all sports
  const { paginatedSports, isLoading: isSportsLoading } = usePaginatedSports({
    skip: !isConnected,
  });
  const allSports: Sport[] = paginatedSports?.items ?? [];

  // Fetch user on connection
  const { user, isLoading: isUserLoading } = useUserMe({ skip: !isConnected });
  const organization = user?.organization ?? undefined;
  const role: Role | undefined = user?.role ?? undefined;
  const permissions: Permission[] = user?.permissions ?? [];
  const sports: Sport[] = user?.sports ?? [];

  // add user info to smartlook
  useEffect(
    function addUserInfoToSmartlook() {
      if (isConnected && user) {
        smartlookManager.addIdentity(
          user.email,
          user.first_name,
          user.last_name,
        );
      }
    },
    [isConnected, user],
  );

  function changeSport(sportId: string) {
    const newSport = allSports.find((sport) => sport.id === sportId);
    setCurrentSport(newSport);
  }

  const isLoading = isUserLoading || isSportsLoading;

  // Initialize current sport with the first sport
  useEffect(
    function initCurrentSport() {
      if (allSports.length > 0 && currentSport === undefined) {
        setCurrentSport(allSports[0]);
      }
    },
    [allSports],
  );

  const userCtx: UserContext = useMemo(
    () => ({
      user: user,
      organization: organization,
      role: role,
      permissions: permissions,
      sports: sports,
      currentSport: currentSport,
      allSports: allSports,
      isConnected,
      isLoading,
      isConnectionLoading,
      isUserLoading,
      changeSport: changeSport,
    }),
    [
      user,
      organization,
      role,
      permissions,
      sports,
      currentSport,
      allSports,
      isConnected,
      isLoading,
      isConnectionLoading,
      isUserLoading,
      changeSport,
    ],
  );

  return (
    <userContext.Provider value={userCtx}>{children}</userContext.Provider>
  );
}

export default UserProvider;
