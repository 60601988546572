import { useContext, useState } from "react";

import userContext from "@/contexts/userContext";
import { Competition } from "@pelote/types/Competition";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import usePaginatedCompetitions from "@pelote/hooks/usePaginatedCompetitions";

import "./PageAdminCompetitionList.scss";

function usePageAdminCompetitionList({ pageSize }: { pageSize: number }): {
  sportPath: string | undefined;
  paginatedCompetitions: PaginatedModel<Competition> | undefined;
  isLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
} {
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);
  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Get the paginated competitions
  const { paginatedCompetitions, isLoading: isCompetitionsLoading } =
    usePaginatedCompetitions({
      page: currentPage,
      pageSize,
      sportId: currentSport?.id,
      skip: currentSport === undefined,
    });

  // Build the global isLoading flag
  const isLoading = isUserLoading || isCompetitionsLoading;

  return {
    sportPath,
    paginatedCompetitions: paginatedCompetitions,
    isLoading,
    setCurrentPage,
    currentPage,
    pageSize,
  };
}

export default usePageAdminCompetitionList;
