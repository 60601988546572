import { UseFormRegister } from "react-hook-form";

import "./formCheckbox.scss";

type FormCheckboxProps = {
  id: string;
  labelName: string;
  disabled?: boolean;
  textToDisplayIfError?: string;
  errorMessage?: string;
  register: UseFormRegister<any>; // Provide the missing type argument
  registerValueAsDate?: boolean;
  className?: string;
};

function FormCheckbox({
  id,
  labelName,
  disabled = false,
  textToDisplayIfError,
  errorMessage,
  register,
  registerValueAsDate,
  className,
}: FormCheckboxProps) {
  return (
    <div className={`form-checkbox ${className}`}>
      <label className="label" htmlFor={id}>
        {labelName}{" "}
        <span className="required-star">
          {" "}
          {textToDisplayIfError && "*"}
          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
        </span>
      </label>
      <input
        type="checkbox"
        id={id}
        className="checkbox"
        disabled={disabled}
        {...register(id, {
          required: textToDisplayIfError,
          valueAsDate: registerValueAsDate,
        })}
      />
    </div>
  );
}

export default FormCheckbox;
