import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import { Option } from "@pelote/types/Option";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import usePaginatedOptions from "@pelote/hooks/usePaginatedOptions";
import OptionsApi from "@pelote/api/OptionsApi";
import { APIError } from "@pelote/types/ApiError";

function usePageAdminOptionList({ pageSize }: { pageSize: number }): {
  sportPath: string | undefined;
  paginatedOptions: PaginatedModel<Option> | undefined;
  isLoading: boolean;
  handleDeleteOption: (id: string) => void;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
} {
  const { t } = useTranslation();
  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Get the paginated options
  const { paginatedOptions, isLoading: isOptionsLoading } = usePaginatedOptions(
    {
      page: currentPage,
      pageSize,
      sportId: currentSport?.id,
      skip: currentSport === undefined,
    },
  );

  // Handle the deletion of a option
  const queryClient = useQueryClient();
  const { mutate: deleteOption } = useMutation({
    mutationFn: (id: string) => OptionsApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: ["showOption", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexOptions"],
      });

      toast.success(t("admin.option.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeleteOption(id: string) {
    if (confirm(t("admin.option.confirmDeletion"))) {
      deleteOption(id);
    }
  }

  // Build the global isLoading flag
  const isLoading = isUserLoading || isOptionsLoading;

  return {
    sportPath,
    paginatedOptions: paginatedOptions,
    handleDeleteOption,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
  };
}

export default usePageAdminOptionList;
