import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Action } from "@pelote/types/Action";

export type AllActions = Action[];

const initialState: AllActions = [];

export const allActionsCodageSlice = createSlice({
  name: "allActionsCesta",
  initialState,
  reducers: {
    addAction: (
      state: AllActions,
      action: PayloadAction<{ action: Action }>,
    ) => {
      state.push(action.payload.action);
      state.sort((a, b) => {
        // If click_time egals, sort reception first and the shot
        if (a.click_time === b.click_time) {
          if (
            a.action_type_id === "0db45030-7798-4f77-ae6f-5bda2c53f264" &&
            b.action_type_id === "a54e281f-f483-4d64-a342-d620ffceac3e"
          ) {
            return -1; // show a before b in the sorted array
          } else if (
            a.action_type_id === "a54e281f-f483-4d64-a342-d620ffceac3e" &&
            b.action_type_id === "0db45030-7798-4f77-ae6f-5bda2c53f264"
          ) {
            return 1; // show b before a in the sorted array
          } else {
            // If action_type_id are the same or different from the specified ones, do not change the order
            return 0;
          }
        } else {
          return a.click_time - b.click_time;
        }
      });
      return state;
    },

    getActions: (
      state: AllActions,
      action: PayloadAction<{ actions: AllActions }>,
    ) => {
      state = action.payload.actions;
      if (state.length === 0) {
        return state;
      } else {
        const sortedArray = [...state].sort((a, b) => {
          // If click_time egals, sort reception first and the shot
          if (a.click_time === b.click_time) {
            if (
              a.action_type_id === "0db45030-7798-4f77-ae6f-5bda2c53f264" &&
              b.action_type_id === "a54e281f-f483-4d64-a342-d620ffceac3e"
            ) {
              return -1; // show a before b in the sorted array
            } else if (
              a.action_type_id === "a54e281f-f483-4d64-a342-d620ffceac3e" &&
              b.action_type_id === "0db45030-7798-4f77-ae6f-5bda2c53f264"
            ) {
              return 1; // show b before a in the sorted array
            } else {
              // If action_type_id are the same or different from the specified ones, do not change the order
              return 0;
            }
          } else {
            return a.click_time - b.click_time;
          }
        });
        return sortedArray;
      }
    },
  },
});

export const { addAction, getActions } = allActionsCodageSlice.actions;
export default allActionsCodageSlice.reducer;
