import { useState } from "react";
import { toast } from "react-toastify";
import { t } from "i18next";

import { PaginatedModel } from "@user/types/PaginatedModel";
import usePaginatedRoles from "@user/hooks/usePaginatedRoles";
import { Role } from "@user/types/Role";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import RolesApi from "@user/api/RolesApi";
import { APIError } from "@user/types/ApiError";
import { cacheKey } from "@/utils/constants";

function usePageAdminRoleList({ pageSize }: { pageSize: number }): {
  paginatedRoles: PaginatedModel<Role> | undefined;
  isLoading: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
  deleteRole: (id: string) => void;
} {
  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Get the paginated roles
  const { paginatedRoles, isLoading: isRolesLoading } = usePaginatedRoles({
    page: currentPage,
    pageSize,
  });

  // Handle the deletion of a role
  const queryClient = useQueryClient();
  const { mutate: deleteRoleInApi } = useMutation({
    mutationFn: (id: string) => RolesApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: [cacheKey.showRole, id] });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexRoles],
      });

      toast.success(t("admin.role.role-deletion-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.role.role-deletion-failed"), {
        autoClose: 3000,
      });
    },
  });
  function deleteRole(id: string) {
    if (confirm(t("admin.role.confirm-role-deletion"))) {
      deleteRoleInApi(id);
    }
  }

  // Build the global isLoading flag
  const isLoading = isRolesLoading;

  return {
    paginatedRoles,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
    deleteRole,
  };
}

export default usePageAdminRoleList;
