import Select from "@ui-lib/select/Select";

import useSportSelect from "./useSportSelect";

export default function SportSelect({ className }: { className?: string }) {
  const { isLoading, sportOptions, selectedOptionIndex, handleSportSelection } =
    useSportSelect();

  if (isLoading) {
    return null;
  }

  return (
    sportOptions &&
    selectedOptionIndex != null && (
      <Select
        className={className}
        options={sportOptions}
        selectedOptionIndex={selectedOptionIndex}
        onChange={(option) => handleSportSelection(option)}
      />
    )
  );
}
