import { useAppSelector } from "../hooks";
import { RootState } from "../store";

const UsePreviousAction = () => {
  const previousAction = useAppSelector(
    (state: RootState) => state.previousAction,
  );

  return {
    previousAction,
  };
};

export default UsePreviousAction;
