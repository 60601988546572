import "./CheckboxPeloteActionType.scss";

export type Checkbox = {
  label: string;
  defaultChecked?: boolean;
  onClick?: () => void;
  className?: string;
};

/** Checkbox used in pelote actiontype/lable/option pages
 * @param label: string
 * @param defaultChecked: boolean
 * @param onClick: () => void
 * @param className: string
 * @returns React.FC
 */
const Checkbox = ({ label, defaultChecked, onClick, className }: Checkbox) => {
  return (
    <div className={`${className}-checkbox-component`}>
      <input
        type="checkbox"
        onClick={onClick}
        id={label}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={label}>{label}</label>
    </div>
  );
};

export default Checkbox;
