import { useQuery } from "@tanstack/react-query";

import { cacheKey } from "@/utils/constants";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { TokenOperations } from "@user/types/TokenOperation";
import TokenOperationsApi from "@user/api/TokenOperationsApi";

interface usePaginatedTokenOperationsOptions {
  organizationId?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
  skip?: boolean;
}

function usePaginatedTokenOperations(
  options: Partial<usePaginatedTokenOperationsOptions> = {},
): {
  paginatedTokenOperations: PaginatedModel<TokenOperations> | undefined;
  isLoading: boolean;
  isError: boolean;
} {
  const { organizationId, page, pageSize, sortBy, skip } = options;

  // Fetch a list of token operation based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      cacheKey.indexOrganizationTokenOperations,
      organizationId,
      page,
      pageSize,
      sortBy,
    ],
    queryFn: () =>
      TokenOperationsApi.index({
        organizationId,
        page,
        pageSize,
        sortBy,
      }),
    enabled: !skip,
  });

  return {
    paginatedTokenOperations: data,
    isLoading,
    isError,
  };
}

export default usePaginatedTokenOperations;
