/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import userContext from "@/contexts/userContext";
import Loader from "@pelote/components/loader/Loader";
import { SportKey, SportUrlSegment } from "@user/types/Sport";
import { hasSport } from "./utils";

function EnsureCestaPuntaSport({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  // Get the current sport from the user context
  const {
    sports: userSports,
    currentSport,
    changeSport,
    isLoading: isUserLoading,
  } = useContext(userContext);

  useEffect(() => {
    if (isUserLoading || !userSports) {
      return;
    }

    // If the user does not have the sport, redirect to the unauthorized page
    if (!hasSport(userSports, SportKey.CESTA_PUNTA)) {
      console.warn(
        `EnsureCestaPuntaSport: user does not have access to the sport "Cesta Punta"`,
      );
      navigate("/unauthorized");
    }

    // If the current sport is not the one from the URL, update the current sport
    if (
      currentSport === undefined ||
      currentSport.url_segment !== SportUrlSegment.CESTA_PUNTA
    ) {
      const newCurrentSport = userSports.find(
        (userSport) => userSport.url_segment === SportUrlSegment.CESTA_PUNTA,
      );
      if (newCurrentSport) {
        console.info(
          `EnsureCestaPuntaSport: updating current sport to ${newCurrentSport.url_segment}`,
        );
        changeSport(newCurrentSport.id);
      }
    }
  }, [isUserLoading]);

  // If the user is not yet loaded, display a loader
  if (isUserLoading) {
    return <Loader />;
  }

  return children;
}

export default EnsureCestaPuntaSport;
