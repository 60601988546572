import React, { Fragment } from "react";

import "./ScatterChart.scss";
import {
  ScatterChart,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
  Scatter,
  LabelList,
  Customized,
  ReferenceArea,
} from "recharts";

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="dark-1 rounded"
        style={{
          color: "black",
          fontWeight: 900,
          fontSize: 20,
          fill: "black",
        }}
      >
        <div className="p-3">
          <div>X: {data.metaData.x1}</div>
          <div>Y: {data.metaData.y1}</div>
        </div>
      </div>
    );
  }
  return null;
};

// Fonction pour obtenir la couleur basée sur le nombre d'éléments par rapport au nombre maximum d'éléments
const getColorBasedOnMaxElements = (
  currentElements: number,
  maxElements: number,
) => {
  // Interpolation de couleur entre rouge et vert en fonction du nombre d'éléments
  const percentage = (currentElements / maxElements) * 100;
  const red = Math.round(255 - (percentage * 255) / 100);
  const green = Math.round((percentage * 255) / 100);

  // Convertir les composantes de couleur en une chaîne hexadécimale
  const color = `#${green.toString(16).padStart(2, "0")}${red.toString(16).padStart(2, "0")}00`;

  return color;
};

function ScatterChartAreas({
  data,
  type,
  numberOfAreas,
  height,
}: {
  data: Array<any>;
  type: string;
  numberOfAreas: number;
  height: number;
}) {
  //Creating areas of 10x10 On The Map
  let areas: Array<any> = [];
  const markupAreas: Array<any> = [];

  let minX = 0;
  let maxX = numberOfAreas;

  let minY = 0;
  const maxY = 5.2;

  if (numberOfAreas === 9) {
    areas = [
      {
        x1: 0,
        x2: 1,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
    ];
  } else if (numberOfAreas === 13) {
    areas = [
      {
        x1: 0,
        x2: 1,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
    ];
  } else if (numberOfAreas === 14) {
    areas = [
      {
        x1: 0,
        x2: 1,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
    ];
  } else if (numberOfAreas === 15) {
    areas = [
      {
        x1: 0,
        x2: 1,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 14,
        x2: 15,
        y1: 0,
        y2: 1,
        fillOpacity: 1,
        color: "dark",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 14,
        x2: 15,
        y1: 1,
        y2: 2,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 14,
        x2: 15,
        y1: 2,
        y2: 3,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 14,
        x2: 15,
        y1: 3,
        y2: 4,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 0,
        x2: 1,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 1,
        x2: 2,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 2,
        x2: 3,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 3,
        x2: 4,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 4,
        x2: 5,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 5,
        x2: 6,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 6,
        x2: 7,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 7,
        x2: 8,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 8,
        x2: 9,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 9,
        x2: 10,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 10,
        x2: 11,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 11,
        x2: 12,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 12,
        x2: 13,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 13,
        x2: 14,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
      {
        x1: 14,
        x2: 15,
        y1: 4,
        y2: 5,
        fillOpacity: 1,
        color: "silver",
        textValue: "",
        percentage: 0,
        value: 0,
        points: [],
      },
    ];
  }

  data.forEach((dataElement) => {
    let x: number = 0;
    let y: number = 0;

    if (type === "service") {
      x = parseFloat(dataElement["X_end"]);
      y = parseFloat(dataElement["Y_end"]);
      // Do not take into account services that are "ace", "pasa" or "falta"
      if (
        dataElement["type_service"] === "ace" ||
        dataElement["type_service"] === "pasa" ||
        dataElement["type_service"] === "falta"
      ) {
        return;
      }
    } else if (type === "reception") {
      x = parseFloat(dataElement["X"]);
      y = parseFloat(dataElement["Y"]);
    } else if (type === "shot") {
      x = parseFloat(dataElement["X_start"]);
      y = parseFloat(dataElement["Y_start"]);
    }

    const selectedArea = areas.find(
      (area: any) => area.x1 == x && area.y1 == y,
    );

    if (selectedArea) {
      selectedArea.points.push({
        x1: x,
        y1: y,
        x2: x + 1,
        y2: y + 1,
        result: dataElement["result"],
      });
    }
  });

  areas.map((area: any) => {
    let maxElements = 0;
    if (area.points.length > 0) {
      maxElements = Math.max(
        ...areas.map((element: any) => element.points.length),
      );

      let color = getColorBasedOnMaxElements(area.points.length, maxElements);
      area.color = color;

      markupAreas.push({
        x: area.x1 + 0.5,
        y: area.y1 + 0.5,
        name: area.points.length,
      });
    }
  });

  if (numberOfAreas === 9) {
    return (
      <div style={{ width: "100%", height: height }}>
        <ResponsiveContainer>
          <ScatterChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis type="number" dataKey="x" hide domain={[minX, maxX]} />
            <YAxis type="number" dataKey="y" hide domain={[minY, maxY]} />
            {
              <Tooltip
                cursor={{ strokeDasharray: "3 3" }}
                content={<CustomTooltip />}
              />
            }
            {areas.map((sector, index) => (
              <ReferenceArea
                key={index}
                x1={sector.x1}
                x2={sector.x2}
                y1={sector.y1}
                y2={sector.y2}
                fill={sector.color}
                fillOpacity={0.2}
                isAnimationActive={false}
                strokeOpacity={0}
                stroke={"white"}
              />
            ))}
            <Scatter
              data={markupAreas}
              fillOpacity={0}
              isAnimationActive={false}
            >
              <LabelList
                position="center"
                dataKey="name"
                color={"white"}
                fill={"colorWhite"}
                fontWeight="bold"
                fontSize={13}
              />
            </Scatter>
            // Full field lines
            <ReferenceLine
              segment={[
                { x: 0, y: 0 },
                { x: 9, y: 0 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 0, y: 0 },
                { x: 0, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 9, y: 0 },
                { x: 9, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 0, y: 5 },
                { x: 9, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            // In field lines
            <ReferenceLine
              segment={[
                { x: 0, y: 1 },
                { x: 9, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 1, y: 5 },
                { x: 1, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 1, y: 2 },
                { x: 1, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 2, y: 5 },
                { x: 2, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 3, y: 5 },
                { x: 3, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 3, y: 2 },
                { x: 3, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 4, y: 5 },
                { x: 4, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 4, y: 2 },
                { x: 4, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 5, y: 5 },
                { x: 5, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 6, y: 5 },
                { x: 6, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 6, y: 2 },
                { x: 6, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 7, y: 5 },
                { x: 7, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 7, y: 2 },
                { x: 7, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 8, y: 5 },
                { x: 8, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 8, y: 2 },
                { x: 8, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <Customized
              component={() => (
                <Fragment>
                  <image
                    x={20} // X coordinate of the image
                    y={10} // Y coordinate of the image
                    width={40} // Image width
                    height={40} // Image height
                  />
                </Fragment>
              )}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    );
  } else if (numberOfAreas === 13) {
    return (
      <div style={{ width: "100%", height: height }}>
        <ResponsiveContainer>
          <ScatterChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis type="number" dataKey="x" hide domain={[minX, maxX]} />
            <YAxis type="number" dataKey="y" hide domain={[minY, maxY]} />
            {
              <Tooltip
                cursor={{ strokeDasharray: "3 3" }}
                content={<CustomTooltip />}
              />
            }
            {areas.map((sector, index) => (
              <ReferenceArea
                key={index}
                x1={sector.x1}
                x2={sector.x2}
                y1={sector.y1}
                y2={sector.y2}
                fill={sector.color}
                fillOpacity={0.2}
                isAnimationActive={false}
                strokeOpacity={0}
                stroke={"white"}
              />
            ))}
            <Scatter
              data={markupAreas}
              fillOpacity={0}
              isAnimationActive={false}
            >
              <LabelList
                position="center"
                dataKey="name"
                color={"white"}
                fill={"colorWhite"}
                fontWeight="bold"
                fontSize={13}
              />
            </Scatter>
            // Full field lines
            <ReferenceLine
              segment={[
                { x: 0, y: 0 },
                { x: 13, y: 0 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 0, y: 0 },
                { x: 0, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 13, y: 0 },
                { x: 13, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 0, y: 5 },
                { x: 13, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            // In field lines
            <ReferenceLine
              segment={[
                { x: 0, y: 1 },
                { x: 13, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 1, y: 5 },
                { x: 1, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 1, y: 2 },
                { x: 1, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 2, y: 5 },
                { x: 2, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 2, y: 2 },
                { x: 2, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 3, y: 5 },
                { x: 3, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 4, y: 5 },
                { x: 4, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 4, y: 2 },
                { x: 4, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 5, y: 5 },
                { x: 5, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 5, y: 2 },
                { x: 5, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 6, y: 5 },
                { x: 6, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 7, y: 5 },
                { x: 7, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 7, y: 2 },
                { x: 7, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 8, y: 5 },
                { x: 8, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 8, y: 2 },
                { x: 8, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 9, y: 5 },
                { x: 9, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 10, y: 5 },
                { x: 10, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 10, y: 2 },
                { x: 10, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 11, y: 5 },
                { x: 11, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 11, y: 2 },
                { x: 11, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 12, y: 5 },
                { x: 12, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 12, y: 2 },
                { x: 12, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <Customized
              component={() => (
                <Fragment>
                  <image
                    x={20} // X coordinate of the image
                    y={10} // Y coordinate of the image
                    width={40} // Image width
                    height={40} // Image height
                  />
                </Fragment>
              )}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    );
  } else if (numberOfAreas === 14) {
    return (
      <div style={{ width: "100%", height: height }}>
        <ResponsiveContainer>
          <ScatterChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis type="number" dataKey="x" hide domain={[minX, maxX]} />
            <YAxis type="number" dataKey="y" hide domain={[minY, maxY]} />
            {
              <Tooltip
                cursor={{ strokeDasharray: "3 3" }}
                content={<CustomTooltip />}
              />
            }
            {areas.map((sector, index) => (
              <ReferenceArea
                key={index}
                x1={sector.x1}
                x2={sector.x2}
                y1={sector.y1}
                y2={sector.y2}
                fill={sector.color}
                fillOpacity={0.2}
                isAnimationActive={false}
                strokeOpacity={0}
                stroke={"white"}
              />
            ))}
            <Scatter
              data={markupAreas}
              fillOpacity={0}
              isAnimationActive={false}
            >
              <LabelList
                position="center"
                dataKey="name"
                color={"white"}
                fill={"colorWhite"}
                fontWeight="bold"
                fontSize={13}
              />
            </Scatter>
            // Full field lines
            <ReferenceLine
              segment={[
                { x: 0, y: 0 },
                { x: 14, y: 0 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 0, y: 0 },
                { x: 0, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 14, y: 0 },
                { x: 14, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 0, y: 5 },
                { x: 14, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            // In field lines
            <ReferenceLine
              segment={[
                { x: 0, y: 1 },
                { x: 14, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 1, y: 5 },
                { x: 1, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 1, y: 2 },
                { x: 1, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 2, y: 5 },
                { x: 2, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 2, y: 2 },
                { x: 2, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 3, y: 5 },
                { x: 3, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 3, y: 2 },
                { x: 3, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 4, y: 5 },
                { x: 4, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 5, y: 5 },
                { x: 5, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 5, y: 2 },
                { x: 5, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 6, y: 5 },
                { x: 6, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 6, y: 2 },
                { x: 6, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 7, y: 5 },
                { x: 7, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 8, y: 5 },
                { x: 8, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 8, y: 2 },
                { x: 8, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 9, y: 5 },
                { x: 9, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 9, y: 2 },
                { x: 9, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 10, y: 5 },
                { x: 10, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 11, y: 5 },
                { x: 11, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 11, y: 2 },
                { x: 11, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 12, y: 5 },
                { x: 12, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 12, y: 2 },
                { x: 12, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 13, y: 5 },
                { x: 13, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 13, y: 2 },
                { x: 13, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <Customized
              component={() => (
                <Fragment>
                  <image
                    x={20} // X coordinate of the image
                    y={10} // Y coordinate of the image
                    width={40} // Image width
                    height={40} // Image height
                  />
                </Fragment>
              )}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    );
  } else if (numberOfAreas === 15) {
    return (
      <div style={{ width: "100%", height: height }}>
        <ResponsiveContainer>
          <ScatterChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis type="number" dataKey="x" hide domain={[minX, maxX]} />
            <YAxis type="number" dataKey="y" hide domain={[minY, maxY]} />
            {
              <Tooltip
                cursor={{ strokeDasharray: "3 3" }}
                content={<CustomTooltip />}
              />
            }
            {areas.map((sector, index) => (
              <ReferenceArea
                key={index}
                x1={sector.x1}
                x2={sector.x2}
                y1={sector.y1}
                y2={sector.y2}
                fill={sector.color}
                fillOpacity={0.2}
                isAnimationActive={false}
                strokeOpacity={0}
                stroke={"white"}
              />
            ))}
            <Scatter
              data={markupAreas}
              fillOpacity={0}
              isAnimationActive={false}
            >
              <LabelList
                position="center"
                dataKey="name"
                color={"white"}
                fill={"colorWhite"}
                fontWeight="bold"
                fontSize={13}
              />
            </Scatter>
            // Full field lines
            <ReferenceLine
              segment={[
                { x: 0, y: 0 },
                { x: 15, y: 0 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 0, y: 0 },
                { x: 0, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 15, y: 0 },
                { x: 15, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 0, y: 5 },
                { x: 15, y: 5 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            // In field lines
            <ReferenceLine
              segment={[
                { x: 0, y: 1 },
                { x: 15, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 1, y: 5 },
                { x: 1, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 1, y: 2 },
                { x: 1, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 2, y: 5 },
                { x: 2, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 2, y: 2 },
                { x: 2, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 3, y: 5 },
                { x: 3, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 3, y: 2 },
                { x: 3, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 4, y: 5 },
                { x: 4, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 4, y: 2 },
                { x: 4, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 5, y: 5 },
                { x: 5, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 6, y: 5 },
                { x: 6, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 6, y: 2 },
                { x: 6, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 7, y: 5 },
                { x: 7, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 7, y: 2 },
                { x: 7, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 8, y: 5 },
                { x: 8, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 9, y: 5 },
                { x: 9, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 9, y: 2 },
                { x: 9, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 10, y: 5 },
                { x: 10, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 10, y: 2 },
                { x: 10, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 11, y: 5 },
                { x: 11, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 12, y: 5 },
                { x: 12, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 12, y: 2 },
                { x: 12, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 13, y: 5 },
                { x: 13, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 13, y: 2 },
                { x: 13, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 14, y: 5 },
                { x: 14, y: 4 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <ReferenceLine
              segment={[
                { x: 14, y: 2 },
                { x: 14, y: 1 },
              ]}
              stroke={"black"}
              strokeWidth={3}
            />
            <Customized
              component={() => (
                <Fragment>
                  <image
                    x={20} // X coordinate of the image
                    y={10} // Y coordinate of the image
                    width={40} // Image width
                    height={40} // Image height
                  />
                </Fragment>
              )}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default ScatterChartAreas;
