import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { UpdatePlaygroundInputs } from "@pelote/api/PlaygroundsApi";
import Loader from "@pelote/components/loader/Loader";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import {
  PlaygroundType,
  PlaygroundNumberAreas,
} from "@pelote/types/Playground";
import usePageAdminPlaygroundEdit from "./usePageAdminPlaygroundEdit";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";
import DeleteButton from "@/ui-lib/delete-button/DeleteButton";

const PageAdminPlaygroundEdit = () => {
  const {
    playground,
    handleEditPlayground,
    handleDeletePlayground,
    disableDelete,
    isLoading,
  } = usePageAdminPlaygroundEdit();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<UpdatePlaygroundInputs>({
    defaultValues: {
      name: "",
      city: "",
      type: PlaygroundType.jai_alai_54m,
      number_areas: PlaygroundNumberAreas.thirteen,
    },
  });

  useEffect(() => {
    setValue("name", playground?.name || "");
    setValue("city", playground?.city as string);
    setValue("type", playground?.type || PlaygroundType.jai_alai_54m);
    setValue(
      "number_areas",
      playground?.number_areas || PlaygroundNumberAreas.nine,
    );
  }, [playground]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={`${t("playground")} ${playground?.name}`} />
      <form onSubmit={handleSubmit(handleEditPlayground)}>
        <div className="page-playground-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
          <div>
            <FormInput
              id="city"
              labelName={t("admin.playground.city")}
              type={InputType.text}
              register={register}
            />
          </div>
          <div>
            <FormSelect
              id="type"
              labelName={t("admin.playground.type")}
              options={Object.values(PlaygroundType).map((type) => ({
                value: type,
                label: type,
              }))}
              register={register}
            />
          </div>
          <div>
            <FormSelect
              id="number_areas"
              labelName={t("admin.playground.areasNumber")}
              options={Object.keys(PlaygroundNumberAreas)
                .filter((key) => isNaN(Number(key)))
                .map((key) => ({
                  value:
                    PlaygroundNumberAreas[
                      key as keyof typeof PlaygroundNumberAreas
                    ],
                  label:
                    PlaygroundNumberAreas[
                      key as keyof typeof PlaygroundNumberAreas
                    ].toString(),
                }))}
              register={register}
            />
          </div>
          <div className="page-playground-buttons">
            <FormGoBackButton
              labelName={t("admin.return")}
              onClick={() => navigate(`/admin/pelote/cesta-punta/playgrounds`)}
            />
            {playground != undefined && (
              <DeleteButton
                labelName={t("admin.delete")}
                onClick={() => handleDeletePlayground(playground.id)}
                disabled={disableDelete}
              />
            )}
            <FormSubmit value={t("admin.save")} />
          </div>
        </div>
      </form>
    </>
  );
};

export default PageAdminPlaygroundEdit;
