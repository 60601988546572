import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { PaginatedModel } from "@user/types/PaginatedModel";
import usePaginatedUsers from "@user/hooks/usePaginatedUsers";
import { User } from "@user/types/User";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import UsersApi from "@user/api/UsersApi";
import { APIError } from "@user/types/ApiError";
import { useDebounce } from "@/hooks/useDebounce";
import { cacheKey } from "@/utils/constants";

function usePageAdminUserList(): {
  paginatedUsers: PaginatedModel<User> | undefined;
  filterByName: string;
  changeFilterByName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resetFilterByName: () => void;
  filterByOrganization: string;
  changeFilterByOrganization: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  resetFilterByOrganization: () => void;
  isLoading: boolean;
  deleteUser: (id: string) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
} {
  const { t } = useTranslation();

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(13);

  // Init the filters
  const [filterByName, setFilterByName] = useState("");
  const debouncedFilterByName = useDebounce(filterByName, 300);
  const [filterByOrganization, setFilterByOrganization] = useState("");
  const debouncedFilterByOrganization = useDebounce(filterByOrganization, 300);

  const changeFilterByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentPage !== 1 && setCurrentPage(1);
    setFilterByName(event.target.value);
  };
  const changeFilterByOrganization = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    currentPage !== 1 && setCurrentPage(1);
    setFilterByOrganization(event.target.value);
  };

  // Handle filters reset
  const resetFilterByName = () => {
    setFilterByName("");
    setCurrentPage(1);
  };
  const resetFilterByOrganization = () => {
    setFilterByOrganization("");
    setCurrentPage(1);
  };

  // Get the paginated users
  const { paginatedUsers, isLoadingUsers } = usePaginatedUsers({
    page: currentPage,
    pageSize,
    lastName: debouncedFilterByName,
    organizationName: debouncedFilterByOrganization,
  });

  // Handle the deletion of a user
  const queryClient = useQueryClient();
  const { mutate: deleteUserInApi } = useMutation({
    mutationFn: (id: string) => UsersApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: [cacheKey.showUser, id] });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexUsers],
      });
      queryClient.invalidateQueries({
        queryKey: [cacheKey.indexOrganizationUsers],
      });

      toast.success(t("admin.user.user-deletion-success"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.user.user-deletion-failed"), {
        autoClose: 3000,
      });
    },
  });
  function deleteUser(id: string) {
    if (confirm(t("admin.user.confirm-user-deletion"))) {
      deleteUserInApi(id);
    }
  }

  // Build the global isLoading flag
  const isLoading = isLoadingUsers;

  return {
    paginatedUsers,
    filterByName,
    changeFilterByName,
    resetFilterByName,
    filterByOrganization,
    changeFilterByOrganization,
    resetFilterByOrganization,
    deleteUser,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    isLoading,
  };
}

export default usePageAdminUserList;
