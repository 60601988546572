import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { toast } from "react-toastify";

import { cacheKey } from "@/utils/constants";
import useRugbyCompetitions from "@/domains/user/hooks/useRugbyCompetitions";
import useOrganization from "@/domains/user/hooks/useOrganization";
import { Competition } from "@/domains/rugby/types/Competitions";
import { APIError } from "@/domains/user/types/ApiError";
import OrganizationCompetitions, {
  UpdateOrganizationCompetitions,
  CreateOrganizationCompetitions,
} from "@/domains/user/api/OrganizationCompetitions";
import { OrganizationCompetitionRugby } from "@/domains/user/types/OrganizationCompetitionRugby";
import useOrganizationGamesBought from "./useOrganizationGamesBought";
import { OrganizationGameBought } from "@/domains/user/types/OrganizationGameBought";

export interface ExtendedCompetition extends Competition {
  sport_name: string;
  organization_competition_id?: string;
  has_full_access: boolean;
  has_dynamic_report_access: boolean;
  has_no_access: boolean;
  organizationGamesBought?: OrganizationGameBought[];
}

function usePageAdminOrganizationCompetitions(): {
  extendedCompetitions: ExtendedCompetition[] | undefined;
  isLoading: boolean;
  isError: boolean;
  isFetching: boolean;
  createOrganizationCompetition: (
    inputs: CreateOrganizationCompetitions,
  ) => void;
  updateOrganizationCompetition: (
    inputs: UpdateOrganizationCompetitions,
  ) => void;
} {
  const { id: organizationId } = useParams();
  const queryClient = useQueryClient();

  const {
    competitions,
    isLoading: isAllCompetitionsLoading,
    isError: isAllCompetitionsError,
  } = useRugbyCompetitions({
    skip: !organizationId,
  });

  const {
    organizationCompetitions,
    isLoading: isOrganizationCompetitionsLoading,
    isError: isOrganizationCompetitionsError,
  } = useOrganization(organizationId, {
    withOrganizationCompetitions: true,
  });

  // Get all game rugby for this organization and this competition in order to list them
  const { organizationGamesBought, isLoading: isAllGamesBoughtLoading } =
    useOrganizationGamesBought(organizationId);

  const extendedCompetitions: ExtendedCompetition[] =
    competitions?.map((competition) => {
      const organizationCompetition = organizationCompetitions?.find(
        (organizationCompetition) => {
          return organizationCompetition.competition_id === competition.id;
        },
      );

      const competitionGamesBought: OrganizationGameBought[] = [];
      organizationGamesBought?.forEach((game) => {
        if (game.competition_id === competition.id)
          return competitionGamesBought.push(game);
      });

      const sortedCompetitionGamesBought = competitionGamesBought.sort(
        (a, b) => {
          return (
            new Date(b.game.date).getTime() - new Date(a.game.date).getTime()
          );
        },
      );

      return {
        ...competition,
        sport_name: "Rugby",
        organization_competition_id: organizationCompetition?.id,
        has_full_access: organizationCompetition?.has_full_access ?? false,
        has_dynamic_report_access:
          organizationCompetition?.has_dynamic_report_access ?? false,
        has_no_access: organizationCompetition?.has_no_access ?? false,
        organizationGamesBought: sortedCompetitionGamesBought ?? [],
      };
    }) ?? [];

  // Create a new organization competition
  const { mutate: createCompetitionsRugby, isPending: isCreationPending } =
    useMutation({
      mutationFn: (
        newOrganizationCompetition: CreateOrganizationCompetitions,
      ) => {
        return OrganizationCompetitions.create(newOrganizationCompetition);
      },
      onSuccess: (data: OrganizationCompetitionRugby) => {
        queryClient.invalidateQueries({
          queryKey: [
            cacheKey.getAllOrganizationCompetitionsRugbyByOrganizationId,
            data.organization_id,
          ],
        });
        toast.success(
          t(
            "admin.organization-competitions.organization-competition-update-success",
          ),
          {
            autoClose: 3000,
          },
        );
      },
      onError: (error: APIError) => {
        console.error(error);
        toast.error(
          t(
            "admin.organization-competitions.organization-competition-update-failed",
          ),
          {
            autoClose: 3000,
          },
        );
      },
    });
  function createOrganizationCompetition(
    inputs: CreateOrganizationCompetitions,
  ) {
    createCompetitionsRugby(inputs);
  }

  // Update an organization competition
  const { mutate: updateCompetitionsRugby } = useMutation({
    mutationFn: (variables: {
      updatedOrganizationCompetition: UpdateOrganizationCompetitions;
    }) =>
      OrganizationCompetitions.update(
        variables.updatedOrganizationCompetition.id
          ? variables.updatedOrganizationCompetition.id
          : "",
        variables.updatedOrganizationCompetition,
      ),
    onSuccess: (data: OrganizationCompetitionRugby) => {
      queryClient.invalidateQueries({
        queryKey: [
          cacheKey.getAllOrganizationCompetitionsRugbyByOrganizationId,
          data.organization_id,
        ],
      });
      toast.success(
        t(
          "admin.organization-competitions.organization-competition-update-success",
        ),
        {
          autoClose: 3000,
        },
      );
    },

    onError: (error: APIError) => {
      console.error(error);
      toast.error(
        t(
          "admin.organization-competitions.organization-competition-update-failed",
        ),
        {
          autoClose: 3000,
        },
      );
    },
  });
  function updateOrganizationCompetition(
    inputs: UpdateOrganizationCompetitions,
  ) {
    updateCompetitionsRugby({ updatedOrganizationCompetition: inputs });
  }

  const isLoading =
    isAllCompetitionsLoading ||
    isOrganizationCompetitionsLoading ||
    isAllGamesBoughtLoading;
  const isError = isAllCompetitionsError || isOrganizationCompetitionsError;
  const isFetching = isCreationPending;

  return {
    extendedCompetitions,
    isLoading,
    isError,
    isFetching,
    createOrganizationCompetition,
    updateOrganizationCompetition,
  };
}

export default usePageAdminOrganizationCompetitions;
