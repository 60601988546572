import { useAppSelector } from "@pelote/pages/cesta-punta/user/codage/redux/hooks";
import { RootState } from "@pelote/pages/cesta-punta/user/codage/redux/store";

const UseActions = () => {
  const allActions = useAppSelector((state: RootState) => state.actions);
  // Which action has been coded first, it must be a "But" first (if not, we disabled Shot and R-)
  let isFirstActionIsNotBut = false;
  let lastActionClicktime: number = 0;

  if (allActions.length === 0) {
    isFirstActionIsNotBut = true;
  }

  if (allActions.length > 0) {
    isFirstActionIsNotBut =
      allActions[0].action_type_id !== "c719c0af-c618-4f68-84be-fe1988f06f25";
    lastActionClicktime = allActions[allActions.length - 1].click_time;
  }
  return {
    allActions,
    isFirstActionIsNotBut,
    lastActionClicktime,
  };
};

export default UseActions;
