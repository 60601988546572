export enum APIErrorType {
  NOT_FOUND = "NOT_FOUND",
  ALREADY_EXISTS = "ALREADY_EXISTS",
  ATTACHED_ITEMS = "ATTACHED_ITEMS",
  INVALID_PARAMETER = "INVALID_PARAMETER",
}

export interface APIError {
  status_code: number;
  error_type: APIErrorType | string;
  details: string;
}
