import { useContext } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import LabelsApi, { UpdateLabelInputs } from "@pelote/api/LabelsApi";
import useLabel from "@pelote/hooks/useLabel";
import { APIError } from "@pelote/types/ApiError";
import { Label } from "@pelote/types/Label";
import usePaginatedOptions from "@pelote/hooks/usePaginatedOptions";
import { convertItemsToSelectOptions } from "@/utils/convertItemsToSelectOptions";
import { SelectOption } from "@/ui-lib/select/Select";

function usePageAdminLabelEdit(): {
  sportPath: string | undefined;
  label: Label | undefined;
  isLoading: boolean;
  nonAttachedOptions: SelectOption[];
  attachedOptions: SelectOption[];
  handleEditLabel: (updatedLabel: UpdateLabelInputs) => void;
  handleAddOptionToLabel: (option: SelectOption) => void;
  handleRemoveOptionFromLabel: (option: SelectOption) => void;
} {
  // Get the label id from the route params
  const { id: labelId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Fetch the label
  const { label, isLoading: isLabelLoading } = useLabel(labelId as string);

  // Handle the edition of a label
  const queryClientUpdateLabel = useQueryClient();
  const { mutate: updateLabel } = useMutation({
    mutationFn: labelId
      ? (updatedLabel: UpdateLabelInputs) =>
          LabelsApi.update(labelId, updatedLabel)
      : undefined,

    onSuccess: (data: Label) => {
      queryClientUpdateLabel.invalidateQueries({
        queryKey: ["showLabel", data.id],
      });
      queryClientUpdateLabel.invalidateQueries({
        queryKey: ["indexLabels"],
      });
      toast.success(t("admin.label.edited"), {
        autoClose: 3000,
      });
      navigate(`/admin/pelote/${sportPath}/labels`);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.edit"), {
        autoClose: 3000,
      });
    },
  });

  // Handle the add of an option to a label
  const queryClientAddOptionToLabel = useQueryClient();
  const { mutate: addOptionToLabel } = useMutation({
    mutationFn: (optionId: string) =>
      LabelsApi.addOption(labelId as string, optionId),
    onSuccess: () => {
      queryClientAddOptionToLabel.invalidateQueries({
        queryKey: ["showLabel", labelId],
      });
      queryClientAddOptionToLabel.invalidateQueries({
        queryKey: ["indexOptions", currentSport?.id],
      });
      toast.success(t("admin.label.optionAdded"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.adding"), {
        autoClose: 3000,
      });
    },
  });

  // Handle the remove of an option from a label
  const queryClientRemoveOptionFromLabel = useQueryClient();
  const { mutate: removeOptionFromLabel } = useMutation({
    mutationFn: (optionId: string) =>
      LabelsApi.removeOption(labelId as string, optionId),
    onSuccess: () => {
      queryClientRemoveOptionFromLabel.invalidateQueries({
        queryKey: ["showLabel", labelId],
      });
      queryClientRemoveOptionFromLabel.invalidateQueries({
        queryKey: ["indexOptions", currentSport?.id],
      });
      queryClientRemoveOptionFromLabel.invalidateQueries({
        queryKey: ["indexLabels"],
      });
      toast.success(t("admin.label.optionRemoved"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });

  // Fetch all options for the current sport
  const { paginatedOptions, isLoading: isOptionsLoading } = usePaginatedOptions(
    {
      page: 1,
      pageSize: 100,
      sportId: currentSport?.id,
    },
  );

  const allOptions = convertItemsToSelectOptions(
    paginatedOptions?.items || [],
    "id",
    "name",
  );

  // Get the options attached to the label
  const attachedOptions = convertItemsToSelectOptions(
    label?.options || [],
    "id",
    "name",
  );

  // Filter the options to keep only the non attached ones
  const nonAttachedOptions = allOptions.filter(
    (option) =>
      !attachedOptions.find(
        (attachedOption) => attachedOption.value === option.value,
      ),
  );

  function handleEditLabel(updatedLabel: UpdateLabelInputs) {
    if (currentSport === undefined) return;
    updateLabel(updatedLabel);
  }

  function handleAddOptionToLabel(option: SelectOption) {
    addOptionToLabel(option.value as string);
  }

  function handleRemoveOptionFromLabel(option: SelectOption) {
    removeOptionFromLabel(option.value as string);
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isLabelLoading || isOptionsLoading;

  return {
    sportPath,
    label,
    isLoading,
    nonAttachedOptions,
    attachedOptions,
    handleEditLabel,
    handleAddOptionToLabel,
    handleRemoveOptionFromLabel,
  };
}

export default usePageAdminLabelEdit;
