import { createColumnHelper } from "@tanstack/react-table";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Label } from "@pelote/types/Label";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminLabelList from "./usePageAdminLabelList";
import FormButton from "@/ui-lib/form-button/FormButton";

const PageAdminLabelList = () => {
  const { sportPath, paginatedLabels, handleDeleteLabel, isLoading } =
    usePageAdminLabelList({
      page: 1,
      pageSize: 10,
    });

  const navigate = useNavigate();
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Label>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("name"),
    }),
    columlHelper.accessor("is_optional", {
      cell: (info) => (info.getValue() ? t("yes") : t("no")),
      header: () => t("admin.optional"),
    }),
    columlHelper.accessor("options", {
      // To link with options edition ?
      cell: (info) =>
        info.getValue().map((option) => (
          <li key={option.id}>
            <Link to={`/admin/pelote/${sportPath}/options/${option.id}`}>
              {option.name}
            </Link>
          </li>
        )),
      header: () => t("admin.option.options"),
    }),
    columlHelper.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.edit")}
            onClick={() =>
              navigate(
                `/admin/pelote/${sportPath}/labels/${info.row.original.id}`,
              )
            }
          />
          <FormButton
            labelName={t("admin.delete")}
            onClick={() => handleDeleteLabel(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <AdminHeader text={t("admin.label.labels")} />
      <FormButton
        labelName={t("admin.label.create")}
        onClick={() => navigate(`/admin/pelote/${sportPath}/labels/create`)}
      />
      <Table
        columns={columns}
        data={paginatedLabels?.items ?? []}
        hasGlobalFilter={true}
      />
    </>
  );
};

export default PageAdminLabelList;
