import { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import useOrganization from "@user/hooks/useOrganization";
import { Organization } from "@/domains/user/types/Organization";
import { ApiRoute } from "@/domains/user/types/ApiRoute";
import useApiRoutes from "@/domains/user/hooks/useApiRoutes";
import OrganizationApiRoutesApi, {
  CreateOrganizationApiRouteInputs,
  UpdateOrganizationApiRouteInputs,
} from "@/domains/user/api/OrganizationApiRoutesApi";
import { APIError } from "@/domains/user/types/ApiError";
import { OrganizationApiRoute } from "@/domains/user/types/OrganizationApiRoute";
import { cacheKey } from "@/utils/constants";

// This interface is used to extend the ApiRoute
// with the organizationRoute properties if any
export interface ExtendedRoute extends ApiRoute {
  organizationRouteId?: string;
  organization?: Organization;
  filter?: string;
  is_active: boolean;
}

function usePageAdminOrganizationApiAccesses(): {
  organization: Organization | undefined;
  extendedRoutes: ExtendedRoute[] | undefined;
  createOrganizationApiRoute: (
    createOrganizationApiRouteInputs: CreateOrganizationApiRouteInputs,
  ) => void;
  deleteOrganizationApiRoute: (organizationApiRouteId: string) => void;
  isLoading: boolean;
  isFetching: boolean;
  isEditModalOpen: boolean;
  openEditModal: (routeId: string) => void;
  closeEditModal: () => void;
  selectedExtendedRoute: ExtendedRoute | undefined;
  updateOrganizationRoute: (
    organizationApiRouteId: string,
    inputs: UpdateOrganizationApiRouteInputs,
  ) => void;
} {
  const { id: organizationId } = useParams();
  const queryClient = useQueryClient();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedExtendedRoute, setSelectedExtendedRoute] =
    useState<ExtendedRoute>();

  // Get all the Api routes
  const { apiRoutes, isLoading: isApiRoutesLoading } = useApiRoutes();

  // Get all the organization api routes
  const {
    organization,
    organizationApiRoutes,
    isLoading: isOrganizationApiRoutesLoading,
  } = useOrganization(organizationId, {
    withOrganizationApiRoutes: true,
  });

  // Build the extended routes
  const extendedRoutes: ExtendedRoute[] =
    apiRoutes?.map((route) => {
      const organizationRoute = organizationApiRoutes?.find(
        (organizationRoute) => {
          return organizationRoute.route.id === route.id;
        },
      );

      return {
        ...route,
        organizationRouteId: organizationRoute?.id,
        organization: organization,
        filter: organizationRoute?.filter,
        is_active: !!organizationRoute?.id,
      };
    }) ?? [];

  // Create a organization Api route
  const {
    mutate: createOrganizationApiRouteInApi,
    isPending: isCreationPending,
  } = useMutation({
    mutationFn: (newOrganizationApiRoute: CreateOrganizationApiRouteInputs) => {
      return OrganizationApiRoutesApi.create(newOrganizationApiRoute);
    },
    onSuccess: (_, inputs) => {
      queryClient.invalidateQueries({
        queryKey: [
          cacheKey.getAllOrganizationRoutesByOrganizationId,
          inputs.organizationId,
        ],
      });
      toast.success(
        t(
          "admin.organization-api-route.organization-api-route-creation-success",
        ),
        {
          autoClose: 3000,
        },
      );
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(
        t(
          "admin.organization-api-route.organization-api-route-creation-failed",
        ),
        {
          autoClose: 3000,
        },
      );
    },
  });
  async function createOrganizationApiRoute(
    createOrganizationApiRouteInputs: CreateOrganizationApiRouteInputs,
  ) {
    createOrganizationApiRouteInApi(createOrganizationApiRouteInputs);
  }

  // Handle the edition of a organization
  const { mutate: updateOrganizationRouteInApi } = useMutation({
    mutationFn: (variables: {
      organizationApiRouteId: string;
      updatedOrganizationApiRoute: UpdateOrganizationApiRouteInputs;
    }) =>
      OrganizationApiRoutesApi.update(
        variables.organizationApiRouteId,
        variables.updatedOrganizationApiRoute,
      ),
    onSuccess: (data: OrganizationApiRoute) => {
      queryClient.invalidateQueries({
        queryKey: [
          cacheKey.getAllOrganizationRoutesByOrganizationId,
          data.organization.id,
        ],
      });
      toast.success(
        t(
          "admin.organization-api-route.organization-api-route-edition-success",
        ),
        {
          autoClose: 3000,
        },
      );
      setIsEditModalOpen(false);
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(
        t("admin.organization-api-route.organization-api-route-edition-failed"),
        {
          autoClose: 3000,
        },
      );
    },
  });
  function updateOrganizationRoute(
    organizationApiRouteId: string,
    inputs: UpdateOrganizationApiRouteInputs,
  ) {
    updateOrganizationRouteInApi({
      organizationApiRouteId,
      updatedOrganizationApiRoute: inputs,
    });
  }

  // Delete a organization API route
  const {
    mutate: deleteOrganizationApiRouteInApi,
    isPending: isDeletePending,
  } = useMutation({
    mutationFn: (organizationApiRouteId: string) => {
      return OrganizationApiRoutesApi.delete(organizationApiRouteId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          cacheKey.getAllOrganizationRoutesByOrganizationId,
          organizationId,
        ],
      });

      toast.success(
        t(
          "admin.organization-api-route.organization-api-route-deletion-success",
        ),
        {
          autoClose: 3000,
        },
      );
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(
        t(
          "admin.organization-api-route.organization-api-route-deletion-failed",
        ),
        {
          autoClose: 3000,
        },
      );
    },
  });
  function deleteOrganizationApiRoute(organizationApiRouteId: string) {
    deleteOrganizationApiRouteInApi(organizationApiRouteId);
  }

  // Build the global isLoading flag
  const isLoading = isApiRoutesLoading || isOrganizationApiRoutesLoading;
  const isFetching = isCreationPending || isDeletePending;

  // Open the edit modal
  function openEditModal(routeId: string) {
    const route = extendedRoutes.find((route) => route.id === routeId);
    if (!route) {
      return;
    }

    setSelectedExtendedRoute(route);
    setIsEditModalOpen(true);
  }

  function closeEditModal() {
    setIsEditModalOpen(false);
  }

  return {
    organization,
    extendedRoutes,
    createOrganizationApiRoute,
    deleteOrganizationApiRoute,
    isLoading,
    isFetching,
    isEditModalOpen,
    openEditModal,
    closeEditModal,
    selectedExtendedRoute,
    updateOrganizationRoute,
  };
}

export default usePageAdminOrganizationApiAccesses;
