import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import Modal from "@/ui-lib/modal/Modal";
import { UpdateOrganizationApiRouteInputs } from "../../api/OrganizationApiRoutesApi";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import { ExtendedRoute } from "../../pages/admin/organization/usePageAdminOrganizationApiAccesses";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";

import "./modalEditOrganizationApiRoute.scss";

type Props = {
  onClose?: () => void;
  updateOganizationRoute: (
    organizationRouteId: string,
    inputs: UpdateOrganizationApiRouteInputs,
  ) => void;
  extendedRoute: ExtendedRoute;
};

const ModalEditOrganizationApiRoute = ({
  onClose,
  updateOganizationRoute,
  extendedRoute,
}: Props) => {
  const { t } = useTranslation();

  const { register, handleSubmit, getValues } = useForm<ExtendedRoute>({
    defaultValues: {
      organization: extendedRoute?.organization,
      domain: extendedRoute?.domain,
      method: extendedRoute?.method,
      path: extendedRoute?.path,
      description: extendedRoute?.description,
      filter: extendedRoute?.filter,
    },
  });

  return (
    <Modal title="Edit organization route" onClose={onClose}>
      <div className="modal-edit-organization-api-route">
        <form
          onSubmit={handleSubmit(() => {
            if (!extendedRoute.organizationRouteId) return;
            updateOganizationRoute(
              extendedRoute.organizationRouteId,
              getValues(),
            );
          })}
        >
          <div className="form-fields">
            <FormInput
              id="domain.name"
              className="form-input"
              labelName={t("admin.domain.domain")}
              type={InputType.text}
              disabled={true}
              labelColor="black"
              register={register}
            />
            <FormInput
              id="method"
              className="form-input"
              labelName={t("admin.api-route.method")}
              type={InputType.text}
              disabled={true}
              labelColor="black"
              register={register}
            />
            <FormInput
              id="path"
              className="form-input"
              labelName={t("admin.api-route.path")}
              type={InputType.text}
              disabled={true}
              labelColor="black"
              register={register}
            />
            <FormInput
              id="description"
              className="form-input"
              labelName={t("admin.api-route.description")}
              type={InputType.text}
              disabled={true}
              labelColor="black"
              register={register}
            />
            <FormInput
              id="filter"
              className="form-input"
              labelName={t("admin.organization-api-route.filter")}
              type={InputType.text}
              labelColor="black"
              register={register}
            />
          </div>
          <div className="form-buttons">
            <FormSubmit value={t("admin.save")} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ModalEditOrganizationApiRoute;
