import { GamePlayer } from "@pelote/types/GamePlayer";
// the 'p.position === "single" ' condition is here in case there would be a substitute in the game
export function findPlayersSingle(players: GamePlayer[]) {
  const player_1 = players.find(
    (p) => p.team_number === 1 && p.position === "single",
  );
  const player_2 = players.find(
    (p) => p.team_number === 2 && p.position === "single",
  );

  return {
    player_1,
    player_2,
  };
}
