import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import usePagePlayerDetails from "@pelote/pages/cesta-punta/user/players/usePagePlayerDetails";
import usePlayer from "@pelote/hooks/usePlayer";
import CardGame from "@pelote/components/CardGame/CardGame";
import { PlayerHeader } from "@pelote/components/player-header/PlayerHeader";
import { CodingStatus } from "@pelote/types/Game";
import { calculateWinrate } from "@pelote/utils/calculateWinrate";
import FormSelect from "@/ui-lib/form-select/FormSelect";

import "./PagePlayerDetails.scss";

export default function PagePlayerDetails() {
  const { playerId } = useParams();
  const {
    seasonSelectOptions,
    selectedSeasonOption,
    competitionSelectOptions,
    selectedCompetitionOption,
    paginatedPlayerGames,
    handleChangeCompetitionOption,
    handleChangeSeasonOption,
  } = usePagePlayerDetails({ pageSize: 100, playerId: playerId || "" });
  const { player } = usePlayer(playerId || "");

  const games = paginatedPlayerGames?.items;

  const navigate = useNavigate();

  const { register } = useForm({
    defaultValues: {
      season_id: seasonSelectOptions?.[0]?.value,
      competition_id: competitionSelectOptions?.[0]?.value,
    },
  });

  let validGames: number | string = "--";
  let numberOfWins: number | string = "--";
  let winrateGlobal: number | string = "--";
  let numberOf2setWins: number | string = "--";
  let winrate2sets: number | string = "--";
  let numberOf3setWins: number | string = "--";
  let winrate3sets: number | string = "--";
  if (games && games?.length != 0 && playerId) {
    ({
      validGames,
      numberOfWins,
      winrateGlobal,
      numberOf2setWins,
      winrate2sets,
      numberOf3setWins,
      winrate3sets,
    } = calculateWinrate(games, playerId));
  }
  function redirectToGamePdfPage(gameId: string) {
    navigate(`/cesta-punta/games/${gameId}/gamePdf`);
  }
  function redirectToCodingPage(gameId: string) {
    navigate(`/cesta-punta/games/${gameId}/codage`);
  }
  const { t } = useTranslation();
  return (
    <div className="player-component">
      <div className="player-top-section">
        {player ? <PlayerHeader player={player}></PlayerHeader> : ""}
        <div className="player-filter-section">
          <p>{t("filterData")}</p>
          <div className="player-filter-container">
            {seasonSelectOptions && (
              <FormSelect
                id="season_id"
                options={seasonSelectOptions?.map((option) => ({
                  value: option.value,
                  label: option.label,
                }))}
                register={register}
                onChange={handleChangeSeasonOption}
                selectedValue={selectedSeasonOption?.value.toString()}
              />
            )}
            {competitionSelectOptions && (
              <FormSelect
                id="competition_id"
                options={competitionSelectOptions?.map((option) => ({
                  value: option.value,
                  label: option.label,
                }))}
                register={register}
                onChange={handleChangeCompetitionOption}
                selectedValue={selectedCompetitionOption?.value.toString()}
              />
            )}
          </div>
        </div>
      </div>
      <div className="player-data-container">
        <div className="games-container">
          {games &&
            games.map((game) => (
              <CardGame
                game={game}
                isPlayerDetails={true}
                key={game.id}
                onCodingButtonClick={() => redirectToCodingPage(game.id)}
                onPDFButtonClick={() => redirectToGamePdfPage(game.id)}
                disableReportButton={game.coding_status !== CodingStatus.done}
                // Links should work with embedded videos from DB
              />
            ))}
          {(games?.length == 0 || games == undefined) && (
            <>
              {" "}
              <div className="no-game">{t("noGame")}</div>
            </>
          )}
        </div>
        <div className="stats-section">
          <div className="stat-title">{t("stats")}</div>
          <div className="stat-container">
            <div className="stat-value">{winrateGlobal}%</div>
            <div className="stat-name">{t("winrate")}</div>
            <div className="stat-name">
              {games && `(${numberOfWins}/${validGames})`}
            </div>
          </div>
          <div className="stat-container">
            <div className="stat-value">{winrate2sets}%</div>
            <div className="stat-name">{t("2WinRnrate")}</div>
            <div className="stat-name">
              {games && `(${numberOf2setWins}/${validGames})`}
            </div>
          </div>
          <div className="stat-container">
            <div className="stat-value">{winrate3sets}%</div>
            <div className="stat-name">{t("3WinRnrate")}</div>
            <div className="stat-name">
              {games && `(${numberOf3setWins}/${validGames})`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
