import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreateSeasonInputs } from "@pelote/api/SeasonsApi";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminSeasonCreate from "./usePageAdminSeasonCreate";
import FormInput, { InputType } from "@/ui-lib/form-input/FormInput";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormGoBackButton from "@/ui-lib/form-goBack-button/formGoBackButton";

import "./PageAdminSeason.scss";

const PageAdminSeasonCreate = () => {
  const { sportPath, handleCreateSeason, isCreationSuccess, isLoading } =
    usePageAdminSeasonCreate();

  // Navigate to the list of playgrounds when the creation is successful
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    if (!isLoading && isCreationSuccess)
      navigate(`/admin/pelote/${sportPath}/seasons`);
  }, [isCreationSuccess, isLoading]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateSeasonInputs>({
    defaultValues: {
      name: "",
    },
  });

  return (
    <>
      <AdminHeader text={t("admin.season.creation")} />
      <form onSubmit={handleSubmit(handleCreateSeason)}>
        <div className="page-season-form">
          <div>
            <FormInput
              id="name"
              labelName={t("name")}
              type={InputType.text}
              textToDisplayIfError={t("admin.necessary.name")}
              errorMessage={errors.name?.message}
              register={register}
            />
          </div>
        </div>
        <div className="page-season-buttons">
          <FormGoBackButton
            labelName={t("admin.cancel")}
            onClick={() => navigate(`/admin/pelote/cesta-punta/seasons`)}
          />
          <FormSubmit value={t("admin.save")} />
        </div>
      </form>
    </>
  );
};

export default PageAdminSeasonCreate;
