import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import { Sport } from "@user/types/Sport";
import Table from "@ui-lib/table/Table";
import AdminHeader from "@/ui-lib/admin-header/AdminHeader";
import usePageAdminSportList from "./usePageAdminSportList";
import Pagination from "@/ui-lib/pagination/Pagination";

import "./pageAdminSportList.scss";

const PageAdminSportList = () => {
  const { paginatedSports, setCurrentPage, currentPage, pageSize, isLoading } =
    usePageAdminSportList({
      pageSize: 25,
    });
  const { t } = useTranslation();

  const columlHelper = createColumnHelper<Sport>();
  const columns = [
    columlHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.sport.name"),
    }),
    columlHelper.accessor("url_segment", {
      cell: (info) => info.getValue(),
      header: () => t("admin.sport.url-segment"),
    }),
  ];

  return (
    <div className="page-admin-sport-list">
      <AdminHeader text={t("admin.sport.sports")} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="sports-list-content">
          <Table
            columns={columns}
            data={paginatedSports?.items ?? []}
            hasGlobalFilter={true}
          />
          <div className="pagination">
            <Pagination
              totalItems={paginatedSports?.pagination.total_items ?? 0}
              itemsPerPage={pageSize}
              currentPage={currentPage}
              onClick={setCurrentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PageAdminSportList;
