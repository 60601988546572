import { useTranslation } from "react-i18next";
import { formatSecondsToMMSS } from "../../../../utils/parseDurationStringToInt";

// CSS
import "@pelote/pages/cesta-punta/gamePdf/PageGamePdf.scss";

// Models
import { MetricsGame } from "@pelote/types/MetricsGame";
import { MetricsGameTeam } from "@pelote/types/MetricsGameTeam";
import {
  MetricsGameActionsList,
  ServiceForActionsList,
  ReceptionForActionsList,
  ShotForActionsList,
} from "@pelote/types/MetricsGameActionsList";
import { Game } from "@pelote/types/Game";

// Header & Footer
import GamePdfHeader from "@pelote/components/GamePdf/GamePdfHeader";
import GamePdfFooter from "@pelote/components/GamePdf/GamePdfFooter";

const GamePdfGeneral = ({
  generalData,
  game,
  metricsGameData,
  metricsGameTeam1Data,
  metricsGameTeam2Data,
  metricsGameActionsListData,
}: {
  generalData: {
    season: string | undefined;
    competition: string | undefined;
    competition_logo: string | undefined;
    round: string | undefined;
    playground: string | undefined;
  };
  game: Game | undefined;
  metricsGameData: MetricsGame | undefined;
  metricsGameTeam1Data: MetricsGameTeam | undefined;
  metricsGameTeam2Data: MetricsGameTeam | undefined;
  metricsGameActionsListData: MetricsGameActionsList | undefined;
}) => {
  const { t } = useTranslation();

  if (
    !game ||
    !metricsGameData ||
    !metricsGameTeam1Data ||
    !metricsGameTeam2Data ||
    !metricsGameActionsListData
  ) {
    return null;
  }

  const pageTitle: string = t("metricsPelote.generalStatistics");
  const pageNumber: number = 1;

  // Get players infos
  const playerFrontTeam1 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  const playerFrontTeam2 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  const playerBackTeam1 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  const playerBackTeam2 = {
    firstname: "",
    lastname: "",
    photo_url: "",
  };
  game.players.forEach((player) => {
    if (player.favorite_position === "front" && player.team_number === 1) {
      playerFrontTeam1.firstname = player.firstname;
      playerFrontTeam1.lastname = player.lastname;
      playerFrontTeam1.photo_url = player.photo_url;
    }
    if (player.favorite_position === "front" && player.team_number === 2) {
      playerFrontTeam2.firstname = player.firstname;
      playerFrontTeam2.lastname = player.lastname;
      playerFrontTeam2.photo_url = player.photo_url;
    }
    if (player.favorite_position === "back" && player.team_number === 1) {
      playerBackTeam1.firstname = player.firstname;
      playerBackTeam1.lastname = player.lastname;
      playerBackTeam1.photo_url = player.photo_url;
    }
    if (player.favorite_position === "back" && player.team_number === 2) {
      playerBackTeam2.firstname = player.firstname;
      playerBackTeam2.lastname = player.lastname;
      playerBackTeam2.photo_url = player.photo_url;
    }
  });

  // Get Teams names
  const team1Name =
    playerFrontTeam1.firstname.charAt(0) +
    "." +
    playerFrontTeam1.lastname.toUpperCase() +
    " / " +
    playerBackTeam1.firstname.charAt(0) +
    "." +
    playerBackTeam1.lastname.toUpperCase();
  const team2Name =
    playerFrontTeam2.firstname.charAt(0) +
    "." +
    playerFrontTeam2.lastname.toUpperCase() +
    " / " +
    playerBackTeam2.firstname.charAt(0) +
    "." +
    playerBackTeam2.lastname.toUpperCase();

  // Get report title
  const reportTitle: string = `Rapport Partie ${team1Name} vs ${team2Name}`;

  // Get date
  const date_day = game.start_time.split("T")[0].split("-")[2];
  const date_month = game.start_time.split("T")[0].split("-")[1];
  const date_year = game.start_time.split("T")[0].split("-")[0];
  const time = game.start_time.split("T")[1].split(".")[0];

  // Get Points Evolution
  const pointsEvolution: Array<{ set: number; team: number; score: number }> =
    [];
  let pasa_team_1 = 0;
  let pasa_team_2 = 0;

  let scoreTeam1 = 0;
  let scoreTeam2 = 0;
  let actualSet = 1;
  let previousAction = "";
  let previousTeam = 0;

  metricsGameActionsListData.actions.forEach((action) => {
    if (action.set_number !== actualSet) {
      actualSet = action.set_number;
      scoreTeam1 = 0;
      scoreTeam2 = 0;
    }

    if (action.type === "service") {
      const typedAction = action as ServiceForActionsList; // Force type to avoid typescript issues
      if (
        typedAction.team_number === 1 &&
        typedAction.type_service === "pasa"
      ) {
        pasa_team_1++;
        if (
          pasa_team_1 > 5 ||
          (previousAction === "pasa" && previousTeam === 1)
        ) {
          scoreTeam2++;
          pointsEvolution.push({
            set: typedAction.set_number,
            team: 2,
            score: scoreTeam2,
          });
        }
      } else if (
        typedAction.team_number === 2 &&
        typedAction.type_service === "pasa"
      ) {
        pasa_team_2++;
        if (
          pasa_team_2 > 5 ||
          (previousAction === "pasa" && previousTeam === 2)
        ) {
          scoreTeam1++;
          pointsEvolution.push({
            set: typedAction.set_number,
            team: 1,
            score: scoreTeam1,
          });
        }
      } else if (
        typedAction.team_number === 1 &&
        typedAction.type_service == "falta"
      ) {
        scoreTeam2++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: 2,
          score: scoreTeam2,
        });
      } else if (
        typedAction.team_number === 2 &&
        typedAction.type_service == "falta"
      ) {
        scoreTeam1++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: 1,
          score: scoreTeam1,
        });
      } else if (
        typedAction.team_number === 1 &&
        typedAction.type_service === "ace"
      ) {
        scoreTeam1++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: typedAction.team_number,
          score: scoreTeam1,
        });
      } else if (
        typedAction.team_number === 2 &&
        typedAction.type_service === "ace"
      ) {
        scoreTeam2++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: typedAction.team_number,
          score: scoreTeam2,
        });
      }
      previousAction = typedAction.type_service;
      previousTeam = typedAction.team_number;
    } else if (action.type === "reception") {
      const typedAction = action as ReceptionForActionsList; // Force type to avoid typescript issues
      if (
        typedAction.team_number === 1 &&
        typedAction.result_reception === "negative"
      ) {
        scoreTeam2++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: 2,
          score: scoreTeam2,
        });
      } else if (
        typedAction.team_number === 2 &&
        typedAction.result_reception === "negative"
      ) {
        scoreTeam1++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: 1,
          score: scoreTeam1,
        });
      }
      previousAction = typedAction.type_reception;
      previousTeam = typedAction.team_number;
    } else if (action.type === "shot") {
      const typedAction = action as ShotForActionsList; // Force type to avoid typescript issues
      if (
        typedAction.team_number === 1 &&
        typedAction.result_shot === "negative"
      ) {
        scoreTeam2++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: 2,
          score: scoreTeam2,
        });
      } else if (
        typedAction.team_number === 2 &&
        typedAction.result_shot === "negative"
      ) {
        scoreTeam1++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: 1,
          score: scoreTeam1,
        });
      } else if (
        typedAction.team_number === 1 &&
        typedAction.result_shot === "positive"
      ) {
        scoreTeam1++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: typedAction.team_number,
          score: scoreTeam1,
        });
      } else if (
        typedAction.team_number === 2 &&
        typedAction.result_shot === "positive"
      ) {
        scoreTeam2++;
        pointsEvolution.push({
          set: typedAction.set_number,
          team: typedAction.team_number,
          score: scoreTeam2,
        });
      }
      previousAction = typedAction.type_shot;
      previousTeam = typedAction.team_number;
    }
  });

  return (
    <div className="page">
      <GamePdfHeader
        pageTitle={pageTitle}
        team1_color={game.color_team_1}
        team1_name={team1Name}
        team2_color={game.color_team_2}
        team2_name={team2Name}
      />
      <div
        className="body"
        style={{
          display: "grid",
          gridTemplateRows: "18% 2% 40% 2% 18% 2% 18%",
        }}
      >
        <div
          style={{
            display: "grid",
            gridRow: "1 / 1",
            gridTemplateColumns: "2% 96% 2%",
          }}
        >
          <div
            className="box"
            style={{
              display: "grid",
              gridColumn: "2 / 2",
              gridTemplateColumns: "50% 50%",
            }}
          >
            <div style={{ height: "45mm", gridColumn: "1 / 1" }}>
              <img
                src={generalData.competition_logo}
                crossOrigin="anonymous"
                className="player-photo"
                height={"170mm"}
              />
            </div>
            <div style={{ height: "45mm", gridColumn: "2 / 2" }}>
              <h5
                className="bold"
                style={{ height: "11mm", lineHeight: "11mm" }}
              >
                {generalData.competition} {generalData.season}
              </h5>
              <h5
                className="bold"
                style={{ height: "11mm", lineHeight: "11mm" }}
              >
                {generalData.round}{" "}
              </h5>
              <h5
                className="bold"
                style={{ height: "11mm", lineHeight: "11mm" }}
              >
                {date_day}/{date_month}/{date_year} - {time}
              </h5>
              <h5
                className="bold"
                style={{ height: "11mm", lineHeight: "11mm" }}
              >
                {generalData.playground}{" "}
              </h5>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridRow: "3 / 3",
            gridTemplateColumns: "1% 21% 1% 21% 1% 10% 1% 21% 1% 21% 1%",
          }}
        >
          <div className="box" style={{ gridColumn: "2 / 2" }}>
            <h1 className="text" style={{ height: "10mm", lineHeight: "10mm" }}>
              {t("metricsPelote.front")}
            </h1>
            <div style={{ height: "70mm" }}>
              <img
                src={playerFrontTeam1.photo_url}
                crossOrigin="anonymous"
                className="player-photo"
                width={"100%"}
              />
            </div>
            <h1
              className="bold"
              style={{
                height: "5mm",
                overflowWrap: "break-word",
                fontSize: "100%",
              }}
            >
              {playerFrontTeam1.lastname.toUpperCase()}
            </h1>
            <h1
              className="text"
              style={{
                height: "5mm",
                overflowWrap: "break-word",
                fontSize: "100%",
              }}
            >
              {playerFrontTeam1.firstname}
            </h1>
          </div>
          <div className="box" style={{ gridColumn: "4 / 4" }}>
            <h1 className="text" style={{ height: "10mm", lineHeight: "10mm" }}>
              {t("metricsPelote.back")}
            </h1>
            <div style={{ height: "70mm" }}>
              <img
                src={playerBackTeam1.photo_url}
                crossOrigin="anonymous"
                className="player-photo"
                width={"100%"}
              />
            </div>
            <h1
              className="bold"
              style={{
                height: "5mm",
                overflowWrap: "break-word",
                fontSize: "100%",
              }}
            >
              {playerBackTeam1.lastname.toUpperCase()}
            </h1>
            <h1
              className="text"
              style={{
                height: "5mm",
                overflowWrap: "break-word",
                fontSize: "100%",
              }}
            >
              {playerBackTeam1.firstname}
            </h1>
          </div>
          <div className="box" style={{ gridColumn: "6 / 6" }}>
            <table style={{ width: "100%", height: "100%" }}>
              <thead>
                <tr>
                  <th scope="col" style={{ width: "50%" }}></th>
                  <th scope="col" style={{ width: "50%" }}></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <p className="subtitle">{t("metricsPelote.set1")}</p>
                  </td>
                </tr>
                {metricsGameTeam1Data.points.set_1 >
                metricsGameTeam2Data.points.set_1 ? (
                  <tr>
                    <td>
                      <p
                        className="subtitle"
                        style={{ color: game.color_team_1 }}
                      >
                        {metricsGameTeam1Data.points.set_1}
                      </p>
                    </td>

                    <td>
                      <p className="subtitle">
                        {metricsGameTeam2Data.points.set_1}
                      </p>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      <p className="subtitle">
                        {metricsGameTeam1Data.points.set_1}
                      </p>
                    </td>
                    <td>
                      <p
                        className="subtitle"
                        style={{ color: game.color_team_2 }}
                      >
                        {metricsGameTeam2Data.points.set_1}
                      </p>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={2}>
                    <p className="subtitle">{t("metricsPelote.set2")}</p>
                  </td>
                </tr>
                {metricsGameTeam1Data.points.set_2 >
                metricsGameTeam2Data.points.set_2 ? (
                  <tr>
                    <td>
                      <p
                        className="subtitle"
                        style={{ color: game.color_team_1 }}
                      >
                        {metricsGameTeam1Data.points.set_2}
                      </p>
                    </td>
                    <td>
                      <p className="subtitle">
                        {metricsGameTeam2Data.points.set_2}
                      </p>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      <p className="subtitle">
                        {metricsGameTeam1Data.points.set_2}
                      </p>
                    </td>
                    <td>
                      <p
                        className="subtitle"
                        style={{ color: game.color_team_2 }}
                      >
                        {metricsGameTeam2Data.points.set_2}
                      </p>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={2}>
                    <p className="subtitle">{t("metricsPelote.set3")}</p>
                  </td>
                </tr>
                {metricsGameTeam1Data.points.set_3 >
                metricsGameTeam2Data.points.set_3 ? (
                  <tr>
                    <td>
                      <p
                        className="subtitle"
                        style={{ color: game.color_team_1 }}
                      >
                        {metricsGameTeam1Data.points.set_3}
                      </p>
                    </td>
                    <td>
                      <p className="subtitle">
                        {metricsGameTeam2Data.points.set_3}
                      </p>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td>
                      <p className="subtitle">
                        {metricsGameTeam1Data.points.set_3}
                      </p>
                    </td>
                    <td>
                      <p
                        className="subtitle"
                        style={{ color: game.color_team_2 }}
                      >
                        {metricsGameTeam2Data.points.set_3}
                      </p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="box" style={{ gridColumn: "8 / 8" }}>
            <h1 className="text" style={{ height: "10mm", lineHeight: "10mm" }}>
              {t("metricsPelote.front")}
            </h1>
            <div style={{ height: "70mm" }}>
              <img
                src={playerFrontTeam2.photo_url}
                crossOrigin="anonymous"
                className="player-photo"
                width={"100%"}
              />
            </div>
            <h1
              className="bold"
              style={{
                height: "5mm",
                overflowWrap: "break-word",
                fontSize: "100%",
              }}
            >
              {playerFrontTeam2.lastname.toUpperCase()}
            </h1>
            <h1
              className="text"
              style={{
                height: "5mm",
                overflowWrap: "break-word",
                fontSize: "100%",
              }}
            >
              {playerFrontTeam2.firstname}
            </h1>
          </div>
          <div className="box" style={{ gridColumn: "10 / 10" }}>
            <h1 className="text" style={{ height: "10mm", lineHeight: "10mm" }}>
              {t("metricsPelote.back")}
            </h1>
            <div style={{ height: "70mm" }}>
              <img
                src={playerBackTeam2.photo_url}
                crossOrigin="anonymous"
                className="player-photo"
                width={"100%"}
              />
            </div>
            <h1
              className="bold"
              style={{
                height: "5mm",
                overflowWrap: "break-word",
                fontSize: "100%",
              }}
            >
              {playerBackTeam2.lastname.toUpperCase()}
            </h1>
            <h1
              className="text"
              style={{
                height: "5mm",
                overflowWrap: "break-word",
                fontSize: "100%",
              }}
            >
              {playerBackTeam2.firstname}
            </h1>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridRow: "5 / 5",
            gridTemplateColumns: "2% 31% 2% 30% 2% 31% 2%",
          }}
        >
          <div className="box" style={{ gridColumn: "2 / 7" }}>
            <h5 className="bold" style={{ height: "10mm", lineHeight: "10mm" }}>
              {t("metricsPelote.timePlayed")}{" "}
            </h5>
            <p className="text" style={{ height: "10mm", lineHeight: "10mm" }}>
              {formatSecondsToMMSS(metricsGameData.time.total)}
            </p>
            <h5 className="bold" style={{ height: "10mm", lineHeight: "10mm" }}>
              {t("metricsPelote.timeEffective")}{" "}
            </h5>
            <p className="text" style={{ height: "10mm", lineHeight: "10mm" }}>
              {formatSecondsToMMSS(metricsGameData.time.effective)}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "grid",
            gridRow: "7 / 7",
            gridTemplateColumns: "2% 96% 2%",
          }}
        >
          <div className="box" style={{ gridColumn: "2 / 2" }}>
            <h5 className="bold" style={{ height: "7mm", lineHeight: "7mm" }}>
              {t("metricsPelote.scoreEvolution")}{" "}
            </h5>
            <table style={{ width: "95%", height: "80%", marginLeft: "0.5cm" }}>
              <tbody>
                <tr style={{ height: "50%" }} key={10000}>
                  {pointsEvolution.map((point, index) => {
                    if (
                      point.team === 1 &&
                      pointsEvolution[index + 1] &&
                      pointsEvolution[index + 1].set !== point.set
                    ) {
                      return (
                        <>
                          <td
                            key={2 * index}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: game.color_team_1,
                            }}
                          >
                            {point.score}
                          </td>
                          <td
                            key={2 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                        </>
                      );
                    } else if (
                      pointsEvolution[index + 1] &&
                      pointsEvolution[index + 1].set !== point.set
                    ) {
                      return (
                        <>
                          <td key={2 * index}></td>
                          <td
                            key={2 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                        </>
                      );
                    } else if (
                      point.team === 1 &&
                      !pointsEvolution[index - 1]
                    ) {
                      return (
                        <>
                          <td
                            key={2 * index}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                          <td
                            key={2 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: game.color_team_1,
                              color: "white",
                            }}
                          >
                            {point.score}
                          </td>
                        </>
                      );
                    } else if (
                      point.team === 1 &&
                      !pointsEvolution[index + 1]
                    ) {
                      return (
                        <>
                          <td
                            key={2 * index}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: game.color_team_1,
                            }}
                          >
                            {point.score}
                          </td>
                          <td
                            key={2 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                        </>
                      );
                    } else if (point.team === 1) {
                      return (
                        <td
                          key={2 * index}
                          style={{
                            border: "solid black 1px",
                            backgroundColor: game.color_team_1,
                          }}
                        >
                          {point.score}
                        </td>
                      );
                    } else if (!pointsEvolution[index - 1]) {
                      return (
                        <>
                          <td
                            key={2 * index}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                          <td key={2 * index + 1}></td>
                        </>
                      );
                    } else if (!pointsEvolution[index + 1]) {
                      return (
                        <>
                          <td key={2 * index}></td>
                          <td
                            key={2 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                        </>
                      );
                    } else {
                      return <td key={2 * index}></td>;
                    }
                  })}
                </tr>
                <tr style={{ height: "50%" }} key={20000}>
                  {pointsEvolution.map((point, index) => {
                    if (
                      point.team === 2 &&
                      pointsEvolution[index + 1] &&
                      pointsEvolution[index + 1].set !== point.set
                    ) {
                      return (
                        <>
                          <td
                            key={4 * index}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: game.color_team_2,
                            }}
                          >
                            {point.score}
                          </td>
                          <td
                            key={4 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                        </>
                      );
                    } else if (
                      pointsEvolution[index + 1] &&
                      pointsEvolution[index + 1].set !== point.set
                    ) {
                      return (
                        <>
                          <td key={4 * index}></td>
                          <td
                            key={4 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                        </>
                      );
                    } else if (
                      point.team === 2 &&
                      !pointsEvolution[index - 1]
                    ) {
                      return (
                        <>
                          <td
                            key={4 * index}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                          <td
                            key={4 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: game.color_team_2,
                            }}
                          >
                            {point.score}
                          </td>
                        </>
                      );
                    } else if (
                      point.team === 2 &&
                      !pointsEvolution[index + 1]
                    ) {
                      return (
                        <>
                          <td
                            key={4 * index}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: game.color_team_2,
                            }}
                          >
                            {point.score}
                          </td>
                          <td
                            key={4 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                        </>
                      );
                    } else if (point.team === 2) {
                      return (
                        <td
                          key={4 * index}
                          style={{
                            border: "solid black 1px",
                            backgroundColor: game.color_team_2,
                          }}
                        >
                          {point.score}
                        </td>
                      );
                    } else if (!pointsEvolution[index - 1]) {
                      return (
                        <>
                          <td
                            key={4 * index}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                          <td key={4 * index + 1}></td>
                        </>
                      );
                    } else if (!pointsEvolution[index + 1]) {
                      return (
                        <>
                          <td key={4 * index}></td>
                          <td
                            key={4 * index + 1}
                            style={{
                              border: "solid black 1px",
                              backgroundColor: "black",
                            }}
                          ></td>
                        </>
                      );
                    } else {
                      return <td key={4 * index}></td>;
                    }
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <GamePdfFooter pageNumber={pageNumber} reportTitle={reportTitle} />
    </div>
  );
};

export default GamePdfGeneral;
