import { Domain } from "./Domain";

// List of permission keys created in database
// To be updated each time a new permission is added or removed
export enum PermissionKey {
  FULL_ACCESS = "FULL_ACCESS",
  USER_MANAGEMENT_FULL = "USER_MANAGEMENT_FULL",
  USER_MANAGEMENT_ORGANIZATION = "USER_MANAGEMENT_ORGANIZATION",
  PELOTE_BASE = "PELOTE_BASE",
  PELOTE_ADMINISTRATION = "PELOTE_ADMINISTRATION",
  PELOTE_ANALYSIS = "PELOTE_ANALYSIS",
  PELOTE_CODING = "PELOTE_CODING",
}

export interface Permission {
  id: string; // UUID v4
  key: PermissionKey;
  name: string;
  description?: string;
  domain: Domain;
  createdAt: string;
  updatedAt: string;
  createdBy?: string; // UUID v4
  updatedBy?: string; // UUID v4
}
