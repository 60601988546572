export interface Sport {
  id: string; // UUID v4
  key: SportKey;
  name: string;
  url_segment: SportUrlSegment;
  createdAt: string;
  updatedAt: string;
  updatedBy?: string; // UUID v4
}

export enum SportUrlSegment {
  CESTA_PUNTA = "cesta-punta",
  RUGBY = "rugby",
}

export enum SportKey {
  CESTA_PUNTA = "CESTA_PUNTA",
  RUGBY = "RUGBY",
}
