import "./CompetitionDescription.scss";

interface CompetitionHeaderProps {
  logoUrl?: string;
  name: string;
  description?: string;
}

export const CompetitionDescription = ({
  logoUrl,
  name,
  description,
}: CompetitionHeaderProps) => {
  return (
    <div className="competition-description">
      <div className="logo-competition">
        {logoUrl && <img src={logoUrl} alt="logo" />}
      </div>
      <div className="name-description-competition">
        {name && <h3>{name.toUpperCase()}</h3>}
        {description && <p>{description}</p>}
      </div>
    </div>
  );
};
