import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

import { apisScopes } from "@/auth/msalManager";
import { useLocation } from "react-router-dom";

const PageLogin = () => {
  const { instance } = useMsal();
  const location = useLocation();

  const handleLoginRedirect = () => {
    const redirectUrl = `${window.location.origin}${location.state?.from ?? "/"}`;
    instance.loginRedirect({
      scopes: apisScopes,
      state: JSON.stringify({ redirectUrl }), // Save the redirect URL in the state
    });
  };

  useEffect(function triggerLoginRedirect() {
    handleLoginRedirect();
  }, []);

  return null;
};

export default PageLogin;
