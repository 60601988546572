import { createColumnHelper } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";

import Loader from "@pelote/components/loader/Loader";
import Table from "@ui-lib/table/Table";
import usePageAdminOrganizationPermissions from "./usePageAdminOrganizationPermissions";
import Pagination from "@/ui-lib/pagination/Pagination";
import FormButton from "@/ui-lib/form-button/FormButton";
import { Permission } from "@user/types/Permission";

import "./pageAdminOrganizationPermissions.scss";

const PageAdminOrganizationPermissions = () => {
  const { t } = useTranslation();

  const {
    paginatedOrganizationPermissions,
    organizationPermissionsPage,
    organizationPermissionsPageSize,
    setOrganizationPermissionsPage,
    addPermissionToOrganization,
    removePermissionFromOrganization,
    paginatedPermissions,
    permissionsPage,
    permissionsPageSize,
    setPermissionsPage,
    isLoading,
  } = usePageAdminOrganizationPermissions();

  // Organization permissions table initialization
  const columlHelperOrganizationPermissions = createColumnHelper<Permission>();
  const columnsOrganizationPermissions = [
    columlHelperOrganizationPermissions.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.domain"),
    }),
    columlHelperOrganizationPermissions.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.name"),
    }),
    columlHelperOrganizationPermissions.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.description"),
    }),
    columlHelperOrganizationPermissions.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.delete")}
            onClick={() =>
              removePermissionFromOrganization(info.row.original.id)
            }
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  // All permissions table initialization
  const columlHelperAllPermissions = createColumnHelper<Permission>();
  const columnsAllPermissions = [
    columlHelperAllPermissions.accessor("domain.name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.domain"),
    }),
    columlHelperAllPermissions.accessor("name", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.name"),
    }),
    columlHelperAllPermissions.accessor("description", {
      cell: (info) => info.getValue(),
      header: () => t("admin.permission.description"),
    }),
    columlHelperAllPermissions.display({
      id: "actions",
      cell: (info) => (
        <div>
          <FormButton
            labelName={t("admin.add")}
            onClick={() => addPermissionToOrganization(info.row.original.id)}
          />
        </div>
      ),
      header: () => t("actions"),
    }),
  ];

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="page-admin-organization-permissions">
      <div className="title-organization-permissions">
        {t("admin.organization.organization-permissions")}
      </div>
      <div className="table-organization-permissions">
        <Table
          columns={columnsOrganizationPermissions}
          data={paginatedOrganizationPermissions?.items ?? []}
          hasGlobalFilter={true}
        />
        <div className="pagination">
          <Pagination
            totalItems={
              paginatedOrganizationPermissions?.pagination.total_items ?? 0
            }
            itemsPerPage={organizationPermissionsPageSize}
            currentPage={organizationPermissionsPage}
            onClick={setOrganizationPermissionsPage}
          />
        </div>
      </div>
      <div className="title-all-permissions">
        {t("admin.organization.available-permissions")}
      </div>
      <div className="table-all-permissions">
        <Table
          columns={columnsAllPermissions}
          data={paginatedPermissions?.items ?? []}
          hasGlobalFilter={true}
        />
        <div className="pagination">
          <Pagination
            totalItems={paginatedPermissions?.pagination.total_items ?? 0}
            itemsPerPage={permissionsPageSize}
            currentPage={permissionsPage}
            onClick={setPermissionsPage}
          />
        </div>
      </div>
    </div>
  );
};

export default PageAdminOrganizationPermissions;
