import { useQuery } from "@tanstack/react-query";

import { Video, VideoProcessingStatus } from "@pelote/types/Video";
import VideosApi from "@pelote/api/VideosApi";
import { PaginatedModel } from "@pelote/types/PaginatedModel";

interface useVideosOptions {
  page?: number;
  pageSize?: number;
  name?: string;
  processing_status?: VideoProcessingStatus;
  gameId?: string; // UUID v4
  sportId?: string; // UUID v4
  skip?: boolean;
  refetchInterval?: number;
}

function usePaginatedVideos(options: Partial<useVideosOptions> = {}): {
  paginatedVideos: PaginatedModel<Video> | undefined;
  isLoadingVideos: boolean;
  isErrorVideos: boolean;
} {
  const {
    page = 1,
    pageSize = 10,
    sportId,
    gameId,
    name,
    processing_status,
    skip,
    refetchInterval,
  } = options;

  // Fetch a list of Videos based on the page and pageSize parameters
  // This fetch is automatically triggered at startup and when the page or pageSize parameters change
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      "indexVideos",
      page,
      pageSize,
      sportId,
      gameId,
      name,
      processing_status,
    ],
    queryFn: () =>
      VideosApi.index(page, pageSize, sportId, gameId, name, processing_status),
    enabled: !skip,
    refetchInterval: refetchInterval,
  });

  return {
    paginatedVideos: data,
    isLoadingVideos: isLoading,
    isErrorVideos: isError,
  };
}

export default usePaginatedVideos;
