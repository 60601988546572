import { setButtonValidateState } from "@pelote/utils/interfaceCodagePeloteUtils";
import { useAppSelector } from "../hooks";
import { RootState } from "../store";

const UseCurrentActionCestaPunta = () => {
  const currentActionHook = useAppSelector(
    (state: RootState) => state.currentAction,
  );
  const isSelected = currentActionHook.isSelected;
  const currentAction = currentActionHook.action;
  const currentActionReceptionPositiveShot =
    currentActionHook.actionReceptionPositive;
  const actionId = currentActionHook.action.id;
  const actionTypeId = currentActionHook.action.action_type_id;
  const isEditMode = currentActionHook.editMode;
  const currentSet = currentActionHook.setNumberSelected;

  const isSelectedObject = setButtonValidateState(currentActionHook.isSelected);

  return {
    currentAction,
    currentActionReceptionPositiveShot,
    isSelected,
    actionId,
    actionTypeId,
    isEditMode,
    isSelectedObject,
    currentSet,
  };
};

export default UseCurrentActionCestaPunta;
