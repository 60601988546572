import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { SiMicrosoftexcel } from "react-icons/si";
import { downloadExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import useValidateActions from "@pelote/hooks/useValidateActions";
import Loader from "@pelote/components/loader/Loader";
import UseGameDetails from "@pelote/pages/cesta-punta/user/codage/redux/hooks/gameDetailsHook";
import useGameEdit from "@pelote/hooks/useGameEdit";
import { CodingStatus } from "@pelote/types/Game";
import useValidateCoding from "@pelote/hooks/useValidateCoding";
import {
  resetValidationErrors,
  setAllValidationErrors,
} from "@pelote/pages/cesta-punta/user/codage/redux/features/validationReducer";

import "./ModalValidation.scss";

export default function ModalValidation({
  gameId,
  closeModal,
}: {
  gameId: string;
  closeModal: () => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isCodageValid, setIsCodageValid] = useState(false);
  const { validationErrors, isLoading, refetch } = useValidateActions(
    gameId as string,
  );
  const { validateCoding } = useValidateCoding(gameId);
  const { competitionId } = UseGameDetails();
  const { handleEditStatusGame } = useGameEdit(gameId as string);

  function sendMetrics() {
    // codage is over, there is no errors, you can go back to competitions
    // send metrics
    validateCoding();
    const updatedGameDone = {
      coding_status: CodingStatus.done,
    };
    handleEditStatusGame(updatedGameDone);
    navigate(`../competitions/${competitionId}`);
  }

  useEffect(() => {
    // check if codage is valid
    if (
      !isLoading &&
      validationErrors &&
      validationErrors.validator.length < 1
    ) {
      setIsCodageValid(true);
    }

    if (validationErrors) {
      dispatch(resetValidationErrors());
      dispatch(setAllValidationErrors({ validationErrors: validationErrors }));
    }
  }, [validationErrors]);

  // Evertytime we open the modal, we reload the data
  const reloadData = () => {
    refetch();
  };

  useEffect(() => {
    reloadData();
  }, []);

  function handleDownloadExcel() {
    const header = [
      t(`modalValidation.tableErrors.titleColumn.mancheTitle`),
      t(`modalValidation.tableErrors.titleColumn.errorCodeTitle`),
      t(`modalValidation.tableErrors.titleColumn.actionTypeTitle`),
      t(`modalValidation.tableErrors.titleColumn.playerAndPositionTitle`),
    ];

    if (!validationErrors) return;
    const body = validationErrors.validator.map((item) => [
      item.point_number !== null
        ? `${item.set_number} - (${item.point_number})`
        : "",
      t(`modalValidation.tableErrors.codeError.${item.error_code}`),
      item.action_type !== null
        ? t(`modalValidation.tableErrors.actionType.${item.action_type}`)
        : "",
      item.player_name === null || item.player_position === null
        ? ""
        : `${item.player_name} - ${t(item.player_position)}`,
    ]);

    downloadExcel({
      fileName: t(`modalValidation.excel.fileName`),
      sheet: t(`modalValidation.excel.fileNameSheet`),
      tablePayload: {
        header,
        body,
      },
    });
  }

  return (
    <div className="modal-validation">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="modal-content">
          <div className="titleAndbutton">
            <h2 className="title">{t("modalValidation.titre")}</h2>
            {isCodageValid ? (
              ""
            ) : (
              <button className="downloadExcel" onClick={handleDownloadExcel}>
                <SiMicrosoftexcel size={30} />
              </button>
            )}
          </div>

          <>
            {isCodageValid ? (
              <p>{t("modalValidation.sentenceToValidate")}</p>
            ) : (
              <div>
                {validationErrors && (
                  <div className="modal-results-errors">
                    <table className="tableErrors">
                      <thead>
                        <tr>
                          <th>
                            {t(
                              "modalValidation.tableErrors.titleColumn.mancheTitle",
                            )}
                          </th>
                          <th>
                            {t(
                              "modalValidation.tableErrors.titleColumn.errorCodeTitle",
                            )}
                          </th>
                          <th>
                            {t(
                              "modalValidation.tableErrors.titleColumn.actionTypeTitle",
                            )}
                          </th>
                          <th>
                            {t(
                              "modalValidation.tableErrors.titleColumn.playerAndPositionTitle",
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {validationErrors.validator.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {item.point_number !== null
                                ? `${item.set_number} - (${item.point_number})`
                                : ""}
                            </td>
                            <td>
                              {t(
                                `modalValidation.tableErrors.codeError.${item.error_code}`,
                              )}
                            </td>
                            <td>
                              {item.action_type !== null
                                ? t(
                                    `modalValidation.tableErrors.actionType.${item.action_type}`,
                                  )
                                : " "}
                            </td>
                            <td>
                              {item.player_name === null ||
                              item.player_position === null
                                ? ""
                                : `${item.player_name}  -
                                  ${t(item.player_position)}`}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
          </>
          <>
            {isCodageValid ? (
              <div className="modal-validation-buttons">
                <button
                  className="modal-validation-button-close"
                  onClick={closeModal}
                >
                  {t("modalValidation.returnToCodage")}
                </button>
                {/* If no erros in codage, you can finish the codage and go back to competitons id this buttons appears only if there is no errors */}
                <button
                  className="modal-validation-button-end-codage"
                  onClick={() => sendMetrics()}
                >
                  {t("modalValidation.validateAndFinishCodage")}
                </button>
              </div>
            ) : (
              <div className="modal-validation-buttons">
                {/* If  erros in codage, you cancel and go back to your codage */}
                <button
                  className="modal-validation-button-close"
                  onClick={closeModal}
                >
                  {t("modalValidation.returnToCodage")}
                </button>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
}
