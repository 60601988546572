import { useEffect, useState } from "react";

export function useIsResponsive(initialWidth: number) {
  const [isResponsive, setIsResponsive] = useState(
    window.innerWidth <= initialWidth,
  );

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth <= initialWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [initialWidth]);

  return isResponsive;
}
