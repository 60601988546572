import { useContext, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import userContext from "@/contexts/userContext";
import { APIError } from "@pelote/types/ApiError";
import usePaginatedVideos from "@pelote/hooks/usePaginatedVideos";
import VideosApi from "@pelote/api/VideosApi";
import { PaginatedModel } from "@pelote/types/PaginatedModel";
import { Video } from "@pelote/types/Video";

function usePageAdminGameListVideos({ pageSize }: { pageSize: number }): {
  sportPath: string | undefined;
  paginatedVideos: PaginatedModel<Video> | undefined;
  handleDeleteVideo: (videoId: string) => void;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
  isLoading: boolean;
} {
  // Get the game id from the route params
  const { id: gameId } = useParams();
  const { t } = useTranslation();

  // Get the current sport from the user context
  const { currentSport, isLoading: isUserLoading } = useContext(userContext);

  // Get the sport path from the current sport
  const sportPath = currentSport?.url_segment;

  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);

  // Get the paginated videos
  const { paginatedVideos, isLoadingVideos } = usePaginatedVideos({
    page: currentPage,
    pageSize: 10,
    sportId: currentSport?.id,
    gameId: gameId,
    refetchInterval: 15000,
  });

  // Handle the deletion of a video
  const queryClient = useQueryClient();
  const { mutate: deleteVideo } = useMutation({
    mutationFn: (id: string) => VideosApi.delete(id),
    onSuccess: (_, id) => {
      queryClient.removeQueries({ queryKey: ["showVideo", id] });
      queryClient.invalidateQueries({
        queryKey: ["indexVideos"],
      });

      toast.success(t("admin.video.deleted"), {
        autoClose: 3000,
      });
    },
    onError: (error: APIError) => {
      console.error(error);
      toast.error(t("admin.error.deletion"), {
        autoClose: 3000,
      });
    },
  });
  function handleDeleteVideo(id: string) {
    if (confirm(t("admin.video.confirmDeletion"))) {
      deleteVideo(id);
    }
  }

  // Build global isLoading flag
  const isLoading = isUserLoading || isLoadingVideos;

  return {
    sportPath,
    paginatedVideos,
    handleDeleteVideo,
    setCurrentPage,
    currentPage,
    pageSize,
    isLoading,
  };
}

export default usePageAdminGameListVideos;
