import { getFlag } from "@pelote/utils/getFlag";
import "./CardPlayer.scss";
import { useTranslation } from "react-i18next";

interface CardPlayerProps {
  id: string;
  firstName: string;
  lastName: string;
  urlPhoto?: string;
  position: string;
  nationality: string;
  onClick?: (id: string) => void;
}

function CardPlayer({
  id,
  firstName,
  lastName,
  urlPhoto,
  position,
  nationality,
  onClick,
}: CardPlayerProps) {
  const { t } = useTranslation();
  return (
    <div
      className="cards-player-container"
      onClick={onClick ? () => onClick(id) : undefined}
    >
      <div className="card-nationality-holder">
        <img src={getFlag(nationality)} />
      </div>
      <div className="card-photo-holder">
        <img
          src={urlPhoto || "/src/assets/images/default_player_photo.png"}
          alt="player_photo"
        />
      </div>
      <div className="card-last-name">
        <p>{lastName}</p>
        <p style={{ textTransform: "capitalize" }}>{firstName}</p>
      </div>
      <div className="card-position">{t(position)}</div>
    </div>
  );
}

export default CardPlayer;
