import { useState } from "react";

import { PaginatedModel } from "@user/types/PaginatedModel";
import usePaginatedOrganizations from "@user/hooks/usePaginatedOrganizations";
import { Organization } from "@user/types/Organization";
import { useDebounce } from "@/hooks/useDebounce";

function usePageAdminOrganizationList(): {
  paginatedOrganizations: PaginatedModel<Organization> | undefined;
  filterByName: string;
  changeFilterByName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resetFilterByName: () => void;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  isLoading: boolean;
} {
  // Set the page to use before fetching the paginated options
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(13);

  // Init the filters
  const [filterByName, setFilterByName] = useState("");
  const debouncedFilterByName = useDebounce(filterByName, 300);

  const changeFilterByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    currentPage !== 1 && setCurrentPage(1);
    setFilterByName(event.target.value);
  };

  const resetFilterByName = () => {
    currentPage !== 1 && setCurrentPage(1);
    setFilterByName("");
  };

  // Get the paginated organizations
  const { paginatedOrganizations, isLoading: isLoadingOrganizations } =
    usePaginatedOrganizations({
      name: debouncedFilterByName,
      page: currentPage,
      pageSize,
    });

  // Build the global isLoading flag
  const isLoading = isLoadingOrganizations;

  return {
    paginatedOrganizations,
    filterByName,
    changeFilterByName,
    resetFilterByName,
    setCurrentPage,
    currentPage,
    pageSize,
    setPageSize,
    isLoading,
  };
}

export default usePageAdminOrganizationList;
