import { useState } from "react";
import { useParams } from "react-router-dom";

import { PaginatedModel } from "@user/types/PaginatedModel";
import useOrganization from "@user/hooks/useOrganization";
import { User } from "@user/types/User";
import { Organization } from "@/domains/user/types/Organization";

function usePageAdminOrganizationUsers(): {
  organization: Organization | undefined;
  paginatedUsers: PaginatedModel<User> | undefined;
  usersPage: number;
  usersPageSize: number;
  setUsersPage: (page: number) => void;
  setUsersPageSize: (pageSize: number) => void;
  isLoading: boolean;
} {
  const { id: organizationId } = useParams();

  // Handle pagination for organization users
  const [usersPage, setUsersPage] = useState(1);
  const [usersPageSize, setUsersPageSize] = useState(10);

  // Get the paginated organization users
  const {
    organization,
    users: paginatedUsers,
    isLoading: isUsersLoading,
  } = useOrganization(organizationId, {
    withUsers: true,
    usersPage: usersPage,
    usersPageSize: usersPageSize,
  });

  // Build the global isLoading flag
  const isLoading = isUsersLoading;

  return {
    organization,
    paginatedUsers,
    usersPage,
    usersPageSize,
    setUsersPage,
    setUsersPageSize,
    isLoading,
  };
}

export default usePageAdminOrganizationUsers;
