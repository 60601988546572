import axios from "axios";
import msalManager from "@/auth/msalManager";

import { domain } from "@rugby/utils/config";
import { Competition } from "@rugby/types/Competitions";

export default class CompetitionsApi {
  static resource = "competitions";

  static async getAll(sport_id?: number): Promise<Competition[]> {
    const accessToken = await msalManager.getAccessToken();
    let url = `${import.meta.env.VITE_API_URL}/${domain}/${this.resource}/all`;
    if (sport_id) {
      url += `&sport_id=${sport_id}`;
    }
    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response.data;
  }
}
