import axios from "axios";

import msalManager from "@/auth/msalManager";
import { PaginatedModel } from "@user/types/PaginatedModel";
import { Permission } from "@user/types/Permission";

export default class PermissionsApi {
  static domain: string = "user";
  static ressource: string = "permissions";

  static async index(
    page: number = 1,
    pageSize: number = 10,
  ): Promise<PaginatedModel<Permission>> {
    const accessToken = await msalManager.getAccessToken();

    const url = `${import.meta.env.VITE_API_URL}/${this.domain}/${this.ressource}?page=${page}&page_size=${pageSize}`;

    const response = await axios(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data;
  }
}
