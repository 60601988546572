import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { AddPlayerToGameInputs } from "@pelote/api/GamesApi";
import { Player, PlayerPosition } from "@pelote/types/Player";
import FormSubmit from "@/ui-lib/form-submit/FormSubmit";
import FormSelect from "@/ui-lib/form-select/FormSelect";
import { SelectOption } from "@/ui-lib/select/Select";
import { GamePlayer } from "@/domains/pelote/types/GamePlayer";
import { getTeamSizesAndSubstitutes } from "@/domains/pelote/utils/getTeamSizesAndSubstitutes";

import "./addPlayerToGame.scss";

interface AddPlayerToGame {
  player: Player;
  gamePlayers: GamePlayer[] | undefined;
  onSubmit: (playerDataToAdd: AddPlayerToGameInputs) => void; // Add this line
}
function AddPlayerToGame({ player, gamePlayers, onSubmit }: AddPlayerToGame) {
  let defaultTeam = 1;
  // If Team 1 has 2 players or more, and Team 2 has 1 or 0, default team switches to Team 2
  if (
    gamePlayers != undefined &&
    getTeamSizesAndSubstitutes(gamePlayers).team_1_size > 1 &&
    getTeamSizesAndSubstitutes(gamePlayers).team_2_size < 2
  ) {
    defaultTeam = 2;
  }
  let defaultPosition = player.favorite_position;
  // If both teams have at least 2 players, default position switches to Substitute
  if (
    gamePlayers != undefined &&
    getTeamSizesAndSubstitutes(gamePlayers).team_1_size > 1 &&
    getTeamSizesAndSubstitutes(gamePlayers).team_2_size > 1
  ) {
    defaultPosition = PlayerPosition.substitute;
  }
  const { register, handleSubmit } = useForm<AddPlayerToGameInputs>({
    defaultValues: {
      player_id: player.id,
      position: defaultPosition,
      team_number: defaultTeam,
    },
  });

  const { t } = useTranslation();

  const teamOptions: SelectOption[] = [
    { label: t("team") + " 1", value: "1" },
    { label: t("team") + " 2", value: "2" },
  ];
  {
    t("");
  }
  return (
    <div>
      <form className="add-player-to-game" onSubmit={handleSubmit(onSubmit)}>
        <input
          key={player.id}
          type="hidden"
          {...register("player_id")}
          defaultValue={player.id}
        />
        <FormSelect
          id="position"
          register={register}
          options={Object.values(PlayerPosition).map((position) => ({
            label: t(position),
            value: position,
          }))}
        />
        <FormSelect
          id="team_number"
          register={register}
          registerValueAsNumber={true}
          options={teamOptions}
        />
        <FormSubmit value={t("admin.add")} />
      </form>
    </div>
  );
}

export default AddPlayerToGame;
