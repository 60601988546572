import { useQuery } from "@tanstack/react-query";

import { cacheKey } from "@/utils/constants";
import OrganizationsApi from "@user/api/OrganizationsApi";
import { OrganizationGameBought } from "@user/types/OrganizationGameBought";

function useOrganizationGamesBought(organizationId?: string): {
  organizationGamesBought: OrganizationGameBought[] | undefined;
  isLoading: boolean;
} {
  // Get the paginated organization games
  const { data: organizationGamesBought, isLoading } = useQuery({
    queryKey: [
      cacheKey.getAllOrganizationGamesByOrganizationId,
      organizationId,
    ],
    queryFn: () => {
      if (!organizationId) return;
      return OrganizationsApi.getAllOrganizationGamesByOrganizationId(
        organizationId,
      );
    },
    enabled: !!organizationId,
  });

  return {
    organizationGamesBought,
    isLoading,
  };
}

export default useOrganizationGamesBought;
